import React, { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";

// HOOKS
import useEstimate from "../../hooks/useEstimate";

function SystemOverLay() {
  const people = [
    {
      name: "Y500Z Peristaltic Pump",
      title: "$1,201.43",
      email: "1",
    },
    {
      name: "18Ga Cannula Tip",
      title: "$1,201.43",
      email: "4",
    },
    {
      name: "Tray Set #1",
      title: "$1,501.43",
      email: "1",
    },
  ];
  return (
    <div className="h-full bg-white p-8 overflow-y-auto">
      <div>
        <h3 className="text-2xl font-semibold text-gray-900">
          Setup Information
        </h3>
        <dl className="mt-2 border-t border-b border-gray-200 divide-y divide-gray-200">
          <div className="py-3 flex justify-between text-sm font-medium">
            <dt className="text-lg text-gray-900">Parameters:</dt>
            <dd className="text-lg tracking-wide text-gray-700">
              Bottles | 12ml | no Heat
            </dd>
          </div>
          <div className="py-3 flex justify-between text-sm font-medium">
            <dt className="text-lg text-gray-900">Set up:</dt>
            <dd className="text-lg tracking-wide text-gray-700">
              Peristaltic System
            </dd>
          </div>
          <div className="py-3 flex justify-between text-sm font-medium">
            <dt className="text-lg text-gray-900">Total Price</dt>
            <dd className="text-lg tracking-wide text-green-700">$12,234.23</dd>
          </div>
        </dl>
      </div>
      <h2 className="mt-6 text-2xl font-semibold text-gray-900">Breakdown:</h2>
      <p className="my-2 mb-4 max-w-sm text-gray-500">
        Based on the previous setups in this production build, the following
        line items are added:
      </p>
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Item
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Unit Price
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      QTY
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {people.map((person, personIdx) => (
                    <tr
                      key={person.email}
                      className={
                        personIdx % 2 === 0 ? "bg-white" : "bg-gray-50"
                      }
                    >
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {person.name}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {person.title}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {person.email}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <p className="mt-8 font-medium text-base text-gray-700">
        Notice a misconfigured build?
      </p>
      <div>
        <label htmlFor="description" className="block text-sm text-gray-800">
          Description:
        </label>
        <div className="mt-1">
          <textarea
            id="description"
            name="description"
            rows={4}
            className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border border-gray-300 rounded-md"
          />
        </div>
      </div>
      <div className="flex flex-row justify-end">
        <button className="mt-4 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          Submit Concern
        </button>
      </div>
    </div>
  );
}

export default function OverLayRightFormWithImage() {
  const { handleSystemOverlay, estimateState } = useEstimate();
  const [open, setOpen] = useState(false);
  const { systemOverLay, systemOverlayDetails } = estimateState;

  useEffect(() => {
    setOpen(estimateState.systemOverLay);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [systemOverLay]);

  useEffect(() => {
    if (!open) {
      handleSystemOverlay(false, {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const toggleShow = (e) => {
    e.preventDefault();
    handleSystemOverlay(false, {});
    setOpen(false);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 overflow-hidden"
        open={open}
        onClose={setOpen}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="relative w-full">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-500"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-500"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 left-0 -ml-8 pt-4 pr-2 flex sm:-ml-10 sm:pr-4">
                    <button
                      className="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                      onClick={toggleShow}
                    >
                      <span className="sr-only">Close panel</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <SystemOverLay />
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
