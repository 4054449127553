import React from "react";
import { Outlet } from "react-router-dom";
import { Helmet } from "react-helmet";

// COMPONENTS
import GeneralHeader from "../../components/Header/pageNav";

const SupportView = () => {
  const subMenus = [
    {
      title: "Tickets",
      link: "tickets",
    },
    {
      title: "NPS",
      link: "nps",
    },
  ];
  return (
    <main
      className="flex-1 relative z-0 overflow-y-auto focus:outline-none"
      tabIndex="0"
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Support | ATG Staff</title>
      </Helmet>
      <GeneralHeader pageTitle={"Support"} subMenus={subMenus} />
      <Outlet />
    </main>
  );
};

export default SupportView;
