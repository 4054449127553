import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { XIcon } from "@heroicons/react/solid";
import { useContext } from "react";
import { InformationCircleIcon } from "@heroicons/react/outline";
import axios from "axios";

// CONTEXT_API
import { EstimateContext } from "../../context/Estimate";

// COMPONENTS
import DropDown from "../../components/FormComponents/dropdown/simple";

// HOOKS
import useEstimate from "./index";

// DATA
import FormMap from "./data/formMap";
import FromStructure from "./data/formStructure";

const useEstimateParameters = () => {
  const [state, dispatch] = useContext(EstimateContext);
  const {
    fetchPrice,
    handleFormSubmission,
    handleSystemOverlay,
    updateTotalPrice,
    updateFormPage,
  } = useEstimate();
  const {
    handleSubmit,
    register,
    getValues,
    watch,
    setValue,
    setError,
    clearErrors,
    unregister,
    formState,
  } = useForm();
  const { map } = FormMap;
  const [parameters, setParameters] = useState([]);
  const [watchFields, setWatchFields] = useState(null);
  const [toReview, setToReview] = useState(false);
  const [errorFields, setErrorFields] = useState({});
  const watchAllFields = watch();
  const { errors } = formState;

  async function handleRemoveSelection(index) {
    let selections = state.parameters;
    selections.splice(index, 1);
    dispatch({
      type: "SET_PARAMETERS_SELECTION",
      payload: selections,
    });
  }

  async function setOnParams() {
    dispatch({
      type: "SET_PARAMETERS",
      payload: false,
    });
  }

  async function handleAddSelection() {
    let Step =
      parseFloat(
        state.parameters[state.parameters.length - 1]?.container.name.split(
          "-"
        )[1]
      ) + 1;
    !Step && (Step = 1);
    let selectionObject = {
      container: { name: `container-${Step}`, value: null },
      volume: { name: `volume-${Step}`, value: null, label: null },
      heating: { name: `heating-${Step}`, value: null },
      product: { name: `product-${Step}`, value: null },
      system: { name: `system-${Step}`, value: null },
      machine: { name: `machine-${Step}`, value: null },
    };
    let selections = state.parameters;
    selections.push(selectionObject);
    dispatch({
      type: "SET_PARAMETERS_SELECTION",
      payload: selections,
    });
  }

  async function handleParams(data) {
    dispatch({ type: "SET_IS_LOADING", payload: true });
    let formParams = state.parameters;
    const dataToCompare = Object.values(data);
    const filteredData = Object.values(data).filter(
      (v) => v !== null && v !== ""
    );
    let structure = Object.assign(
      {},
      JSON.parse(JSON.stringify(FromStructure))
    );
    if (
      filteredData.length === dataToCompare.length &&
      filteredData.length === Object.values(formParams).length * 5
    ) {
      dispatch({ type: "SET_CURRENT_BUILD", payload: [] });
      for (const [index, entry] of formParams.entries()) {
        let match = false;
        const container = data[entry.container.name];
        const volume = data[entry.volume.name];
        const volLabel = entry.volume.label;
        const heating = data[entry.heating.name];
        const product = data[entry.product.name];
        const machine = data[entry.machine.name];
        const mapContainer = map[container];
        const containerLabel = mapContainer?.label;
        const containerUnits = mapContainer?.units;
        formParams[index].container.value = container;
        formParams[index].volume.value = volume;
        formParams[index].heating.value = heating;
        formParams[index].product.value = product;
        formParams[index].machine.value = machine;
        const position =
          FormMap.map[container]?.volumes[volume]?.heating[heating];
        let systemName = position?.system;
        systemName === "Volumetric Deluxe" && (systemName = "volumetricDeluxe");
        systemName === "Pnuem-Peristaltic" && (systemName = "pneumPeristaltic");
        let containerValue = `${systemName}-${containerLabel}-${volLabel}-${containerUnits}`;
        let pertainsObject = {
          product,
          machine,
          container: [containerValue],
        };
        if (position && position.requires) {
          for (const [set, value] of Object.entries(position.requires)) {
            let defaultIndex = 0;
            let duped = Object.assign(
              {},
              JSON.parse(JSON.stringify(value.default))
            );
            if (set === "trays") {
              let system = position.system;
              let system_type;
              if (system === "Volumetric Deluxe") system_type = "Volumetric";
              else if (system === "Pnuem-Peristaltic")
                system_type = "Pneumatic";
              else system_type = system[0].toUpperCase() + system.slice(1);
              // if (containerLabel !== "2 piece Capsules") {
              try {
                const tray_response = await axios({
                  method: "post",
                  url: "/inventory/tray/",
                  config: { headers: { "Content-Type": "application/json" } },
                  headers: {
                    "X-CSRFToken":
                      "OROsBbAGbBdam2JE6BQ5oxPi2eIVn6syDJ8pOMJbj55okrxJytm8EjKzsOPhNfdk",
                  },
                  data: {
                    robot_type: "300",
                    setup_type: system_type,
                    number_of_nozzles: 2,
                  },
                });
                let objectKey = `${
                  tray_response.data[1].sku
                }-${tray_response.data[1].title
                  .split("-")
                  .join("")} ${containerLabel} ${volLabel} ${containerUnits}`;
                duped[objectKey] = "1";
              } catch (error) {
                console.error("ERROR IS HERE", error.response);
              }
              // } else {
              //   try {
              //     const tray_response = await axios({
              //       method: "post",
              //       url: "/inventory/filter/tray",
              //       config: { headers: { "Content-Type": "application/json" } },
              //       headers: {
              //         "X-CSRFToken":
              //           "OROsBbAGbBdam2JE6BQ5oxPi2eIVn6syDJ8pOMJbj55okrxJytm8EjKzsOPhNfdk",
              //       },
              //       data: {
              //         variances: ["Capsule", "300"],
              //       },
              //     });
              //     const matches = tray_response.data.filter((s) =>
              //       s.title.includes(`Size ${volLabel}`)
              //     );
              //     let key = `${matches[0].sku}-${matches[0].title}`;
              //     duped[key] = "1";
              //   } catch (error) {
              //     console.error("ERROR IS HERE", error.response);
              //   }
              // }
            }
            if (!structure[set].defaults[defaultIndex]) {
              structure[set].defaults[defaultIndex] = duped;
              structure[set].pertains[defaultIndex] = pertainsObject;
            } else {
              for (const index of structure[set].defaults.keys()) {
                if (structure[set].pertains[index].machine === machine) {
                  defaultIndex = index;
                  match = true;
                  break;
                }
              }
              if (!defaultIndex && !match) {
                defaultIndex = structure[set].defaults.length;
                structure[set].defaults[defaultIndex] = duped;
                structure[set].pertains[defaultIndex] = pertainsObject;
              } else if (value.addOn.extension) {
                if (
                  pertainsObject.machine !==
                    structure[set].pertains[defaultIndex].machine ||
                  pertainsObject.product !==
                    structure[set].pertains[defaultIndex].product
                ) {
                  for (const [i, v] of Object.entries(value.addOn.extension)) {
                    let positionI = structure[set].defaults[defaultIndex][i];
                    if (positionI) {
                      let values = parseFloat(
                        structure[set].defaults[defaultIndex][i]
                      );
                      structure[set].defaults[defaultIndex][i] = (
                        values + parseFloat(v)
                      ).toString();
                    } else {
                      structure[set].defaults[defaultIndex][i] = v;
                    }
                  }
                }
                structure[set].pertains[defaultIndex].container.indexOf(
                  containerValue
                ) === -1 &&
                  structure[set].pertains[defaultIndex].container.push(
                    containerValue
                  );
              } else if (value.addOn.expand) {
                structure[set].defaults[defaultIndex] = Object.assign(
                  {},
                  structure[set].defaults[defaultIndex],
                  duped
                );
                structure[set].pertains[defaultIndex].container.indexOf(
                  containerValue
                ) === -1 &&
                  structure[set].pertains[defaultIndex].container.push(
                    containerValue
                  );
              } else {
                for (const [i, v] of Object.entries(duped)) {
                  let positionI = structure[set].defaults[defaultIndex][i];
                  if (!positionI) {
                    structure[set].defaults[defaultIndex][i] = v;
                  }
                }
              }
            }
          }
        }
        structure.machine.pertains.length === 0 &&
          structure.machine.pertains.push(pertainsObject);
        const currentMachineRepeat = parseInt(machine) - 1;
        if (currentMachineRepeat > structure.machine.repeat) {
          structure.machine.repeat = currentMachineRepeat;
          structure.machine.defaults.push(structure.machine.defaults[0]);
          structure.machine.pertains.push(pertainsObject);
        }
      }
      state.currentBuild = [];
      let order = 0;
      for (const [index, value] of Object.entries(structure)) {
        if (typeof value !== "string") {
          if (!value.defaults[0]) {
            structure[value.previous].next = value.next;
            structure[value.next].previous = value.previous;
          } else {
            let price = 0;
            for (const [i, values] of value.defaults.entries()) {
              let product, machine;
              if (value.pertains[i]) {
                product = value.pertains[i].product;
                machine = value.pertains[i].machine;
              }
              price += await fetchPrice(values, value.structure);
              await handleFormSubmission(
                values,
                value.structure,
                order,
                null,
                product,
                machine
              );
              order++;
            }
            structure[index].price = price;
          }
        }
      }
      dispatch({ type: "SET_DEFAULT_VALUES", payload: structure });
      dispatch({
        type: "SET_IS_PARAMETERS",
        payload: {
          parameters: formParams,
          prods: state.products,
        },
      });
      updateTotalPrice(structure);
    }
    dispatch({ type: "SET_IS_LOADING", payload: false });
  }

  async function Validate(value, name, index) {
    if (value) {
      clearErrors(name);
      const entryValue = parseFloat(value);
      const mapEntry = Object.values(map[index].volumes);
      if (entryValue) {
        let valueToRegister = null;
        for (const entries of mapEntry) {
          if (entryValue > entries.min && entryValue < entries.max) {
            valueToRegister = entries.value;
          }
        }
        if (Number.isInteger(valueToRegister)) {
          setValue(name, valueToRegister);
        } else {
          setValue(name, null);
          setError(name, {
            type: "manual",
            message: map[index].error,
          });
        }
      }
    }
    return null;
  }
  useEffect(() => {
    for (const [index, entry] of state.parameters.entries()) {
      setValue(entry.container.name, entry.container.value);
      setValue(entry.volume.name, entry.volume.value);
      setValue(entry.heating.name, entry.heating.value);
      setValue(entry.machine.name, entry.machine.value);
      // setValue(`capsules-${index}`, entry.volume.label);
      if (!entry.product.value && state.products[index]) {
        setValue(entry.product.name, state.products[index].product.value);
      } else setValue(entry.product.name, entry.product.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function setParams() {
      const formInputs = state.parameters;
      Object.values(errors).length === 0 && handleParams(getValues());
      // let paramV = getValues();
      // var filteredObject = Object.keys(paramV).reduce(function (r, e) {
      //   if (e.split("-")[0] !== "capsules") r[e] = paramV[e];
      //   return r;
      // }, {});
      // Object.values(errors).length === 0 && handleParams(filteredObject);
      const filteredMachines = Array.from(
        new Set(
          formInputs
            .map((entry) => {
              let value = getValues(entry.machine.name);
              if (value) return value;
            })
            .sort()
        )
      );
      let Prams = formInputs.map((entry, index) => {
        let parameterProducts = [];
        const volToValidate = document.getElementById(entry.volume.name);
        if (
          volToValidate &&
          volToValidate.value &&
          getValues(entry.container.name)
          //  &&
          // !map[getValues(entry.container.name)].dropdown
        ) {
          formInputs[index].volume.label = volToValidate.value;
          document.querySelectorAll('input[type="number"]')[index].value =
            volToValidate.value;
          entry.volume.label = volToValidate.value;
          Validate(
            volToValidate.value,
            entry.volume.name,
            getValues(entry.container.name)
          );
        }
        if (
          //   getValues(entry.container.name) &&
          //   map[getValues(entry.container.name)].dropdown &&
          //   getValues(`capsules-${index}`)
          // ) {
          //   entry.volume.label = getValues(`capsules-${index}`);
          //   setValue(entry.volume.name, 0);
          // }
          // if (
          getValues(entry.heating.name) &&
          (!Number.isInteger(getValues(entry.volume.name)) ||
            !map[getValues(entry.container.name)].volumes[
              getValues(entry.volume.name)
            ].heating[getValues(entry.heating.name)])
        ) {
          unregister(entry.heating.name);
        }
        if (state.products.length !== 0) {
          for (const entry of state.products) {
            parameterProducts.push({
              label: entry.product.value,
              value: entry.product.value,
            });
          }
        }
        let machineOptions = [
          <option value={1}>{`Filling Machine #1`}</option>,
        ];
        let machineCounter = 1;
        for (const entry of filteredMachines) {
          let currentV = parseInt(entry);
          if (currentV === machineCounter && currentV < formInputs.length) {
            machineCounter++;
            machineOptions.push(
              <option key={currentV} value={machineCounter}>
                {`Filling Machine #${machineCounter}`}
              </option>
            );
          }
        }
        if (
          Number.isInteger(getValues(entry.volume.name)) &&
          map[getValues(entry.container.name)].volumes[
            getValues(entry.volume.name)
          ].noHeat
        ) {
          setValue(entry.heating.name, "0");
        }
        if (index === 0) setValue(entry.machine.name, "1");
        else {
          !getValues(entry.machine.name) &&
            setValue(
              entry.machine.name,
              getValues(formInputs[index - 1].machine.name)
            );
        }
        return (
          <div
            key={index}
            className="grid grid-cols-1 gap-2 lg:gap-5 sm:grid-cols-13 py-2 "
          >
            <div className="flex flex-row justify-start items-start col-span-2">
              <p className="text-lg font-medium mr-1 self-end ">{index + 1}.</p>
              <div className="w-full self-end">
                {errors[entry.product.name] && (
                  <p className="text-red-600 text-xs">
                    {errors[entry.product.name].message}
                  </p>
                )}
                <DropDown
                  props={{
                    name: entry.product.name,
                    options: parameterProducts,
                  }}
                  register={register}
                />
              </div>
            </div>
            <div className="self-end col-span-2">
              <DropDown
                props={{
                  name: entry.container.name,
                  options: Object.values(map),
                }}
                register={register}
              />
            </div>
            <div className="col-span-2 self-end">
              {errors[entry.volume.name] && (
                <p className="text-red-600 text-xs">
                  {errors[entry.volume.name].message}
                </p>
              )}
              {/* {getValues(entry.container.name) &&
              map[getValues(entry.container.name)].dropdown ? (
                <div className="flex flex-row">
                  <select
                    name={`capsules-${index}`}
                    defaultValue={entry.volume.label}
                    id={`capsules-${index}`}
                    {...register(`capsules-${index}`, {
                      required: true,
                    })}
                    className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm p-1 bg-white border border-gray-300 rounded-md"
                  >
                    <option value="">Select...</option>
                    {map[getValues(entry.container.name)].dropdown.map(
                      (option, index) => {
                        return (
                          <option key={index} value={option.value}>
                            {option.label}
                          </option>
                        );
                      }
                    )}
                  </select>
                </div>
              ) : ( */}
              <div className="flex flex-row">
                <input
                  type="number"
                  step=".01"
                  defaultValue={entry.volume.label}
                  disabled={getValues(entry.container.name) ? false : true}
                  className="shadow-sm sm:text-sm p-1 border border-gray-300 rounded-l-md w-3/5 "
                  id={entry.volume.name}
                  onBlur={(e) => {
                    Validate(
                      e.target.value,
                      entry.volume.name,
                      getValues(entry.container.name)
                    );
                    formInputs[index].volume.label = e.target.value;
                  }}
                />
                <span className="flex w-2/5 items-center px-3 rounded-r-md border border-l-0 border-gray-400 bg-gray-50 text-gray-500 sm:text-sm">
                  {getValues(entry.container.name)
                    ? map[getValues(entry.container.name)].units
                    : "unit"}
                </span>
              </div>
              {/* )} */}
            </div>

            <div className="flex flex-col self-end col-span-2">
              {Number.isInteger(getValues(entry.volume.name)) &&
                map[getValues(entry.container.name)].volumes[
                  getValues(entry.volume.name)
                ].noHeat && (
                  <p className="text-xs leading-3 flex flex-row items-center text-gray-500">
                    <InformationCircleIcon
                      className="h-5 w-5 text-gray-400 hover:text-gray-900 mr-1"
                      aria-hidden="true"
                    />
                    Disabled Heating for selected container
                  </p>
                )}
              <DropDown
                props={{
                  name: entry.heating.name,
                  options: Number.isInteger(getValues(entry.volume.name))
                    ? Object.values(
                        map[getValues(entry.container.name)].volumes[
                          getValues(entry.volume.name)
                        ].heating
                      )
                    : // : getValues(entry.volume.name) &&
                      //   map[getValues(entry.container.name)].dropdown
                      // ? Object.values(
                      //     map[getValues(entry.container.name)].volumes["0"]
                      //       .heating
                      //   )
                      [],
                }}
                register={register}
              />
            </div>
            <select
              {...register(entry.machine.name, {
                required: true,
              })}
              name={entry.machine.name}
              id={entry.machine.name}
              className="col-span-2 self-end w-full focus:ring-indigo-500 focus:border-indigo-500 shadow-sm sm:text-sm p-1 bg-white border border-gray-300 rounded-md"
            >
              <option value="">Select...</option>
              {machineOptions}
            </select>
            <div className="relative mt-1 self-end col-span-2">
              <input
                disabled={true}
                className="self-end shadow-sm sm:text-sm p-1 border border-gray-300 w-full rounded-md"
                value={
                  getValues(entry.heating.name) && getValues(entry.machine.name)
                    ? map[getValues(entry.container.name)].volumes[
                        getValues(entry.volume.name)
                      ].heating[getValues(entry.heating.name)].system
                    : ""
                }
              />
              {getValues(entry.machine.name) && getValues(entry.heating.name) && (
                <button
                  type="button"
                  onClick={() => handleSystemOverlay(true, {})}
                  className="absolute inset-y-0 right-0 pr-3 animate-pulse text-blue-500 hover:text-blue-800  focus:outline-none flex items-center"
                >
                  <svg
                    className="visible h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              )}
            </div>
            <div className="self-end col-span-1">
              <button
                className="flex flex-row w-min justify-start items-center text-red-400 hover:text-red-500 focus:outline-none"
                onClick={() => {
                  handleRemoveSelection(index);
                  unregister(entry.container.name);
                  unregister(entry.volume.name);
                  unregister(entry.heating.name);
                  unregister(entry.product.name);
                  unregister(entry.machine.name);
                }}
                type="button"
              >
                <XIcon className="h-5 w-5 ml-4" aria-hidden="true" />
              </button>
            </div>
          </div>
        );
      });
      setParameters(Prams);
    }
    const currentErrors = JSON.stringify(errors, (name, val) => {
      if (name !== "ref") return val;
    });
    if (
      JSON.stringify(watchFields) !== JSON.stringify(watchAllFields) ||
      state.parameters.length !== parameters?.length ||
      errorFields !== currentErrors
    ) {
      setErrorFields(currentErrors);
      setWatchFields(watchAllFields);
      setParams();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors, watchAllFields, state.parameters]);

  async function submitParam(data) {
    const isContained = state.products.every((element) =>
      Object.values(data).includes(element.product.value)
    );
    if (!isContained) {
      const product = Object.keys(data)[4];
      setError(product, {
        type: "manual",
        message: "Products in this build are not used",
      });
    } else {
      setOnParams();
      toReview && !state.isLoading && updateFormPage("review");
    }
  }
  return {
    submitParam,
    handleSubmit,
    handleAddSelection,
    parameters,
    setToReview,
  };
};
export default useEstimateParameters;
