import React from "react";

const cards = [
  {
    department: "Marketing",
    departmentPath:
      "M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122",
    color: "indigo",
    okr: "Incoming Leads",
    change: "Increased",
    value: "29 Leads",
    changeValue: "10.21%",
    indicator: "green",
    indicatorPath:
      "M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z",
  },
  {
    department: "Marketing",
    departmentPath:
      "M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122",
    color: "indigo",
    okr: "Conversion Rate",
    change: "Decreased",
    value: "26.91%",
    changeValue: "1.32%",
    indicator: "red",
    indicatorPath:
      "M14.707 10.293a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L9 12.586V5a1 1 0 012 0v7.586l2.293-2.293a1 1 0 011.414 0z",
  },
  {
    department: "Sales",
    departmentPath:
      "M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z",
    color: "green",
    okr: "New Clients",
    change: "Increased",
    value: "11 Clients",
    changeValue: "5.12%",
    indicator: "green",
    indicatorPath:
      "M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z",
  },
  {
    department: "Sales",
    departmentPath:
      "M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z",
    color: "green",
    okr: "New Clients",
    change: "Increased",
    value: "11 Clients",
    changeValue: "5.12%",
    indicator: "green",
    indicatorPath:
      "M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z",
  },
  {
    department: "Engineering",
    departmentPath:
      "M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z",
    color: "red",
    okr: "NPI Success",
    change: "Decreased",
    value: "76.81%",
    changeValue: "9.12%",
    indicator: "red",
    indicatorPath:
      "M14.707 10.293a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L9 12.586V5a1 1 0 012 0v7.586l2.293-2.293a1 1 0 011.414 0z",
  },
  {
    department: "Software",
    departmentPath:
      "M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z",
    color: "blue",
    okr: "Automation Success",
    change: "Increased",
    value: "16.81%",
    changeValue: "9.12%",
    indicator: "green",
    indicatorPath:
      "M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z",
  },
];

const GoalsGrid = ({
  toggleExpand,
  setToggleExpand,
  pinnedProjects,
  setPinnedProjects,
}) => {
  // async function updatePinned(entry) {
  //   pinnedProjects.push(entry);
  //   setPinnedProjects(pinnedProjects);
  // }
  const goalCard = cards.map((entry, index) => {
    const {
      department,
      color,
      okr,
      change,
      indicator,
      indicatorPath,
      value,
      changeValue,
      departmentPath,
    } = entry;
    return (
      <li
        key={index}
        className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200"
      >
        <div className="flex flex-col bg-white overflow-hidden">
          <div className="bg-white px-4 border-b border-gray-200 sm:px-6">
            <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
              <div className="ml-4 mt-2">
                <h3 className="text-base leading-6 font-medium text-gray-900">
                  {department}
                </h3>
              </div>
              <div className="ml-4 mt-2 flex-shrink-0">
                {/* <button
                  href="#"
                  className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500"
                > */}
                <button
                  // onClick={() => setPinnedProjects(pinnedProjects.push())}
                  className="py-4 hover:text-gray-500 "
                >
                  <svg
                    className="w-5 h-5 text-red-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M3 6a3 3 0 013-3h10a1 1 0 01.8 1.6L14.25 8l2.55 3.4A1 1 0 0116 13H6a1 1 0 00-1 1v3a1 1 0 11-2 0V6z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
                {/* </button> */}
              </div>
            </div>
          </div>
          <div className="flex-grow px-4 py-5 sm:p-6">
            <div className="flex items-center">
              <div className={`flex-shrink-0 bg-${color}-500 rounded-md p-3`}>
                <svg
                  className="h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d={departmentPath}
                  />
                </svg>
              </div>
              <div className="ml-5 w-0 flex-1">
                <dt className="text-sm font-medium text-gray-500 truncate">
                  {okr}
                </dt>
                <div className="flex items-baseline">
                  <div className="text-base font-bold text-gray-900">
                    {value}
                  </div>

                  <div
                    className={`ml-2 flex items-baseline text-sm font-semibold text-${indicator}-600`}
                  >
                    <svg
                      className={`self-center flex-shrink-0 h-5 w-5 text-${indicator}-500`}
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d={indicatorPath}
                        clipRule="evenodd"
                      />
                    </svg>
                    <span className="sr-only">{change} by</span>
                    {changeValue}
                  </div>
                </div>
                <dt className="text-xs font-medium text-gray-500 truncate">
                  Target: 28
                </dt>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="-mt-px flex divide-x divide-gray-200">
            <button className="w-0 flex-1 flex hover:bg-gray-100">
              <p
                href="#"
                className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
              >
                <svg
                  className="w-5 h-5 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z" />
                  <path
                    fillRule="evenodd"
                    d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z"
                    clipRule="evenodd"
                  />
                </svg>
                <span className="ml-3">Target</span>
              </p>
            </button>
            <button className="-ml-px w-0 flex-1 flex hover:bg-gray-100">
              <button
                href="#"
                className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500"
              >
                <svg
                  className="w-5 h-5 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke="#374151"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M3 8V4m0 0h4M3 4l4 4m8 0V4m0 0h-4m4 0l-4 4m-8 4v4m0 0h4m-4 0l4-4m8 4l-4-4m4 4v-4m0 4h-4"
                  />
                </svg>
                <span className="ml-3">Expand</span>
              </button>
            </button>
          </div>
        </div>
      </li>
    );
  });
  return (
    <div
      className={`sm:px-6 lg:px-8 sm:py-6 lg:py-8 ${
        toggleExpand ? "hidden" : `visible`
      }`}
    >
      <ul className="grid grid-cols-1 gap-6 grid-cols-1 lg:grid-cols-2 xl:grid-cols-4 2xl:grid-cols-5">
        {goalCard}
      </ul>
    </div>
  );
};

export default GoalsGrid;
