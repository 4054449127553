import React, { useEffect } from "react";
import "./App.css";
import axios from "axios";

//COOKIES
import { useCookies } from "react-cookie";

// HOOKS
import useConnectAuth from "./hooks/useConnectAuth";

// VIEWS
import Login from "./views/Login";
import Router from "./router";

function App() {
  const [cookies, setCookie] = useCookies(["user"]);
  const { isUserLoggedIn, handleSignOut } = useConnectAuth();

  useEffect(() => {
    async function updateTokens() {
      if (!isUserLoggedIn) handleSignOut();
      if (cookies.rome) {
        try {
          const response = await axios({
            method: "post",
            url: "/auth/token/refresh/",
            config: { headers: { "Content-Type": "application/json" } },
            headers: {
              "X-CSRFToken":
                "OROsBbAGbBdam2JE6BQ5oxPi2eIVn6syDJ8pOMJbj55okrxJytm8EjKzsOPhNfdk",
            },
            data: {
              refresh: cookies.rome,
            },
          });
          setCookie("amsterdam", response.data.access, {
            path: "/",
            secure: true,
            sameSite: true,
          });
        } catch (error) {
          console.error("ERROR IS HERE", error.response);
        }
      }
    }
    updateTokens();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (!isUserLoggedIn) {
    return <Login />;
  } else {
    return <Router />;
  }
}

export default App;
