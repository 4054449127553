import React from "react";
import { useFormContext } from "react-hook-form";

// HOOKS
import useEstimate from "../../../hooks/useEstimate";

const SimpleDropDown = ({ props }) => {
  let isRequired = false;
  const { estimateState, handleProductOverlay } = useEstimate();
  const {
    register,
    getValues,
    formState: { errors },
  } = useFormContext();
  const dropDownOptions = props.options.map((option, index) => {
    return (
      <option
        onClick={() => console.log(option.value)}
        key={index}
        value={option.value}
      >
        {option.label}
      </option>
    );
  });
  if (props.validation) {
    if (props.validation.dependant) {
      for (const entry of props.validation.dependant) {
        isRequired = entry ? (getValues(entry) ? true : false) : false;
        if (isRequired) break;
      }
    }
    if (!isRequired && props.validation.default) {
      for (const entry of props.validation.default) {
        isRequired = entry
          ? estimateState.defaultValues[entry.index][entry.VariableName]
            ? true
            : false
          : false;
        if (isRequired) break;
      }
    }
  }
  return (
    <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
      <div className="col-span-2">
        <label
          htmlFor="project_name"
          className="block text-lg font-medium text-gray-900 sm:mt-px"
        >
          {props.label}
        </label>
        <p className="text-gray-500 text-base">{props.subtitle}</p>
        <button
          onClick={() => handleProductOverlay(true, props)}
          type="button"
          className="animate-pulse text-blue-400 hover:text-indigo-900 text-base font-semibold"
        >
          View Details
        </button>
        {errors[props.name] && (
          <p className=" text-xs tracking-wide  pt-2 lg:pt-1` text-red-600">
            Selection is Required
          </p>
        )}
      </div>
      <div>
        <select
          {...register(props.name)}
          {...register(props.name, {
            required: isRequired,
          })}
          id={props.name}
          name={props.name}
          className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm p-1 bg-white border border-gray-300 rounded-md"
        >
          <option value="">Select...</option>
          {dropDownOptions}
        </select>
      </div>
    </div>
  );
};

export default SimpleDropDown;
