import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useContext } from "react";

// COMPONENTS
import DropDown from "../../components/FormComponents/dropdown/simple";

// CONTEXT_API
import { EstimateContext } from "../../context/Estimate";

let categoryOptions = [
  { label: "cannabis", value: "cannabis" },
  { label: "Cosmetics", value: "cosmetics" },
  { label: "Food & Beverage", value: "food" },
];

const useEstimateProducts = () => {
  const [state, dispatch] = useContext(EstimateContext);
  const [products, setProducts] = useState([]);
  const {
    handleSubmit,
    watch,
    register,
    setValue,
    setError,
    clearErrors,
    getValues,
    formState,
  } = useForm();
  const [watchFields, setWatchFields] = useState(null);
  const watchAllFields = watch();
  const { errors } = formState;

  async function handleSubmitProds(data) {
    let formProds = state.products;
    for (const [index, entry] of formProds.entries()) {
      const product = data[entry.product.name];
      const category = data[entry.category.name];
      formProds[index].product.value = product;
      formProds[index].category.value = category;
    }
    let params = state.parameters;
    while (formProds.length > params.length) {
      let Step =
        parseFloat(params[params.length - 1]?.container.name.split("-")[1]) + 1;
      !Step && (Step = 1);
      let selectionObject = {
        container: { name: `container-${Step}`, value: null },
        volume: { name: `volume-${Step}`, value: null, label: null },
        heating: { name: `heating-${Step}`, value: null },
        product: { name: `product-${Step}`, value: null },
        system: { name: `system-${Step}`, value: null },
        machine: { name: `machine-${Step}`, value: null },
      };
      params.push(selectionObject);
    }

    dispatch({
      type: "SET_IS_PARAMETERS",
      payload: {
        parameters: params,
        prods: formProds,
      },
    });
    dispatch({
      type: "SET_PARAMETERS",
      payload: true,
    });
  }

  async function handleProducts(value) {
    clearErrors("products");
    let productsValue = parseInt(value);
    if (!productsValue || productsValue < 1 || productsValue > 10) {
      setError("products", {
        type: "manual",
        message: "value must range between 1 - 10 unique liquids",
      });
    } else {
      let selections = [];
      for (let i = 1; i < productsValue + 1; i++) {
        let selectionObject = {
          product: { name: `product-${i}`, value: null },
          category: { name: `category-${i}`, value: null },
        };
        selections.push(selectionObject);
      }
      dispatch({
        type: "SET_PRODUCTS_SELECTION",
        payload: selections,
      });
    }
  }

  useEffect(() => {
    for (const entry of state.products) {
      setValue(entry.product.name, entry.product.value);
      setValue(entry.category.name, entry.category.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function setParams() {
      const formInputs = state.products;
      let prods = formInputs.map((entry, index) => {
        clearErrors(entry.product.name);
        // const AllProducts = Object.fromEntries(
        //   Object.entries(getValues()).filter(
        //     ([key, value]) => key.split("-")[0] === "product"
        //   )
        // );
        // let isDuplication =
        //   new Set(Object.values(AllProducts)).size !==
        //   Object.values(AllProducts).length;
        // if (isDuplication) {
        //   setError(entry.product.name, {
        //     type: "manual",
        //     message: "Product can not have a duplicate name",
        //   });
        // }
        return (
          <div
            key={index}
            className="ml-3 lg:ml-8 grid grid-cols-2 xl:grid-cols-3 gap-5 py-2 "
          >
            <div className="flex flex-row justify-start items-start">
              <input
                type="text"
                id="product-name"
                placeholder="Ex: Caffeinated Liquid"
                className="shadow-sm sm:text-sm placeholder-gray-500 placeholder-opacity-50 p-1 border border-gray-300 rounded-md w-full lg:w-3/5 "
                {...register(entry.product.name, {
                  required: true,
                })}
              />
            </div>
            {errors[entry.product.name] && (
              <p className="text-red-600 text-xs ml-12">
                {errors[entry.product.name].message}
              </p>
            )}
            <div className="w-full lg:w-3/5">
              <DropDown
                props={{
                  name: entry.category.name,
                  options: categoryOptions,
                }}
                register={register}
              />
            </div>
          </div>
        );
      });
      setProducts(prods);
    }
    if (
      JSON.stringify(watchFields) !== JSON.stringify(watchAllFields) ||
      state.products.length !== products?.length
    ) {
      setWatchFields(watchAllFields);
      setParams();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchAllFields]);

  return {
    handleSubmitProds,
    products,
    handleSubmit,
    handleProducts,
    errors,
  };
};
export default useEstimateProducts;
