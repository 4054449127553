import React from "react";

import sampleData from "./sampleData";

const DirectoryList = () => {
  // const [directory] = useState(sampleData);
  let letters = Object.keys(sampleData);

  return (
    <nav className="relative h-full overflow-y-auto" aria-label="Directory">
      {letters.map((letter, index) => {
        let entries = sampleData[letter];
        return (
          <React.Fragment key={index}>
            <div className="z-10 sticky top-0 border-t border-b border-gray-200 bg-gray-50 px-6 py-1 text-sm font-medium text-gray-500">
              <h3>{letter}</h3>
            </div>
            <ul className="relative z-0 divide-y divide-gray-200">
              {entries.map(({ imageUrl, name, industry }, index) => {
                return (
                  <li className="bg-white" key={index}>
                    <div className="relative px-6 py-5 flex items-center space-x-3 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">
                      <div className="flex-shrink-0">
                        <img
                          className="h-10 w-10 rounded-full"
                          src={imageUrl}
                          alt=""
                        />
                      </div>
                      <div className="flex-1 min-w-0">
                        <p className="focus:outline-none">
                          {/* Extend touch target to entire panel  */}
                          <span
                            className="absolute inset-0"
                            aria-hidden="true"
                          ></span>
                          <p className="text-sm font-medium text-gray-900">
                            {name}
                          </p>
                          <p className="text-sm text-gray-500 truncate">
                            {industry}
                          </p>
                        </p>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </React.Fragment>
        );
      })}
    </nav>
  );
};

export default DirectoryList;
