import React from "react";

const CardWButtonHeader = ({ heading, children }) => {
  return (
    <div className="bg-white overflow-hidden shadow border border-gray-100 rounded-lg mr-8 ml-8 mt-8">
      <div className="border-b border-gray-200 py-4 mb-2 flex flex-row justify-between items-center mx-6">
        <div>
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            {heading.title}
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            {heading.subTitle}
          </p>
        </div>
        <div className="mt-3 flex sm:mt-0 sm:ml-4">
          <a
            href={heading.linkTo}
            className="inline-flex ml-3  items-center px-5 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            {heading.button}
          </a>
        </div>
      </div>
      <div className="px-4 py-5 sm:p-6">{children}</div>
    </div>
  );
};

export default CardWButtonHeader;
