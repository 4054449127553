import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { Helmet } from "react-helmet";
import axios from "axios";
import { Switch } from "@headlessui/react";

//COMPONENT
import SimpleCard from "../../../components/Card/simpleCard";

// DATA
import ChartOptions from "./chartOptions";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const ForecastView = () => {
  const { RevenueBreakdownOptions, MonthlyRevenueForecast, HeatMapOptions } =
    ChartOptions;
  const [RevenueBreakdown, setRevenueBreakdown] = useState([]);
  const [ForecastedRevenue, setForecastedRevenue] = useState([]);
  const [ErrorsTable, setErrorsTable] = useState([]);
  const [ProductMap, setProductsMap] = useState([]);
  const [ActualProductMap, setActualProductMap] = useState([]);
  const [isActualMap, seIsActualMap] = useState(false);

  const TableHeaders = [
    "Cause",
    "Related Deal",
    "Pertains To ",
    "Sales Rep",
    "Link",
  ];

  useEffect(() => {
    async function getProductHeatMap() {
      const Products = await axios({
        method: "get",
        url: "/pipedrive/forecast/products",
        config: { headers: { "Content-Type": "application/json" } },
        headers: {
          "X-CSRFToken":
            "OROsBbAGbBdam2JE6BQ5oxPi2eIVn6syDJ8pOMJbj55okrxJytm8EjKzsOPhNfdk",
        },
      });
      let Series = [];
      let ActualSeries = [];
      for (const [key, value] of Object.entries(Products.data)) {
        const Segment = {
          name: key,
          data: [
            value["2021-1"] ? value["2021-1"].forecasted : 0,
            value["2021-2"] ? value["2021-2"].forecasted : 0,
            value["2021-3"] ? value["2021-3"].forecasted : 0,
            value["2021-4"] ? value["2021-4"].forecasted : 0,
            value["2021-5"] ? value["2021-5"].forecasted : 0,
            value["2021-6"] ? value["2021-6"].forecasted : 0,
            value["2021-7"] ? value["2021-7"].forecasted : 0,
            value["2021-8"] ? value["2021-8"].forecasted : 0,
            value["2021-9"] ? value["2021-9"].forecasted : 0,
            value["2021-10"] ? value["2021-10"].forecasted : 0,
            value["2021-11"] ? value["2021-11"].forecasted : 0,
            value["2021-12"] ? value["2021-12"].forecasted : 0,
          ],
        };
        const realSegment = {
          name: key,
          data: [
            value["2021-1"] && value["2021-1"].actual
              ? value["2021-1"].actual
              : 0,
            value["2021-2"] && value["2021-2"].actual
              ? value["2021-2"].actual
              : 0,
            value["2021-3"] && value["2021-3"].actual
              ? value["2021-3"].actual
              : 0,
            value["2021-4"] && value["2021-4"].actual
              ? value["2021-4"].actual
              : 0,
            value["2021-5"] && value["2021-5"].actual
              ? value["2021-5"].actual
              : 0,
            value["2021-6"] && value["2021-6"].actual
              ? value["2021-6"].actual
              : 0,
            value["2021-7"] && value["2021-7"].actual
              ? value["2021-7"].actual
              : 0,
            value["2021-8"] && value["2021-8"].actual
              ? value["2021-8"].actual
              : 0,
            value["2021-9"] && value["2021-9"].actual
              ? value["2021-9"].actual
              : 0,
            value["2021-10"] && value["2021-10"].actual
              ? value["2021-10"].actual
              : 0,
            value["2021-11"] && value["2021-11"].actual
              ? value["2021-11"].actual
              : 0,
            value["2021-12"] && value["2021-12"].actual
              ? value["2021-12"].actual
              : 0,
          ],
        };
        Series.push(Segment);
        ActualSeries.push(realSegment);
      }
      setProductsMap(Series);
      setActualProductMap(ActualSeries);
    }

    async function getErrorsTable() {
      const Errors = await axios({
        method: "get",
        url: "/pipedrive/forecast/errors",
        config: { headers: { "Content-Type": "application/json" } },
        headers: {
          "X-CSRFToken":
            "OROsBbAGbBdam2JE6BQ5oxPi2eIVn6syDJ8pOMJbj55okrxJytm8EjKzsOPhNfdk",
        },
      });
      if (Errors.data) {
        setErrorsTable(Errors.data);
      }
    }

    async function getForecastedRevenue() {
      const forecastedRev = await axios({
        method: "get",
        url: "/pipedrive/forecast/monthly",
        config: { headers: { "Content-Type": "application/json" } },
        headers: {
          "X-CSRFToken":
            "OROsBbAGbBdam2JE6BQ5oxPi2eIVn6syDJ8pOMJbj55okrxJytm8EjKzsOPhNfdk",
        },
      });
      if (forecastedRev.data) {
        let ForecastedData = [
          {
            name: "Forecasted",
            data: [],
          },
          {
            name: "Real",
            data: [],
          },
        ];
        for (const value of Object.values(forecastedRev.data)) {
          ForecastedData[0].data.push(value.forecasted);
          ForecastedData[1].data.push(value.actual);
        }
        setForecastedRevenue(ForecastedData);
      }
    }

    async function getRevenueBreakdown() {
      const currentDate = new Date();
      const BreakdownRev = await axios({
        method: "post",
        url: "/pipedrive/forecast/monthly/salesRep",
        config: { headers: { "Content-Type": "application/json" } },
        headers: {
          "X-CSRFToken":
            "OROsBbAGbBdam2JE6BQ5oxPi2eIVn6syDJ8pOMJbj55okrxJytm8EjKzsOPhNfdk",
        },
        data: {
          year: currentDate.getFullYear(),
          month: currentDate.getMonth() + 1,
        },
      });
      if (BreakdownRev.data) {
        let series = [];
        for (const [key, value] of Object.entries(BreakdownRev.data)) {
          series.push({
            name: key,
            data: [value.forecasted],
          });
        }
        setRevenueBreakdown(series);
      }
    }
    getRevenueBreakdown();
    getForecastedRevenue();
    getErrorsTable();
    getProductHeatMap();
  }, []);

  async function handleResolve(index) {
    axios({
      method: "post",
      url: `/pipedrive/forecast/errors/resolve/${index}`,
      config: { headers: { "Content-Type": "application/json" } },
      headers: {
        "X-CSRFToken":
          "OROsBbAGbBdam2JE6BQ5oxPi2eIVn6syDJ8pOMJbj55okrxJytm8EjKzsOPhNfdk",
      },
    });
  }
  return (
    <SimpleCard>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Forecast | ATG Staff</title>
      </Helmet>
      <div className="px-4 py-5">
        <dl className="grid grid-cols-1 gap-5 lg:grid-cols-1">
          <div className="bg-white  shadow rounded-lg col-span-2">
            <div className="px-4 py-5 sm:py-4 sm:px-6">
              <Chart
                options={MonthlyRevenueForecast}
                series={ForecastedRevenue}
                type="bar"
                width="100%"
                height="430"
              />
            </div>
          </div>
          <div className="bg-white overflow-hidden shadow rounded-lg col-span-3">
            <div className="py-2 px-1">
              <Chart
                options={RevenueBreakdownOptions}
                series={RevenueBreakdown}
                type="bar"
                width="100%"
                height="170"
              />
            </div>
          </div>
        </dl>
      </div>
      <div className="px-4 py-5">
        <h2 className="pb-6 text-xl font-lg">Deal Errors</h2>
        <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-auto h-60 border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full relative divide-y divide-gray-200">
                  <thead className="sticky top-0 bg-red-50">
                    <tr>
                      {TableHeaders.map((header) => (
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-bold text-red-800 uppercase tracking-wider"
                        >
                          {header}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="bg-gray-50 divide-y divide-gray-200">
                    {ErrorsTable &&
                      ErrorsTable.length !== 0 &&
                      ErrorsTable.map((Error) => (
                        <tr key={Error.deal_id}>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                            {Error.cause}
                          </td>
                          <td className="whitespace-nowrap px-6 py-4 text-left text-sm font-medium flex flex-row items-center">
                            <p className="text-gray-400 mx-2 ">
                              {Error.deal_id} |{" "}
                            </p>
                            <a
                              href={`https://atgpharma.pipedrive.com/deal/${Error.deal_id}`}
                              target="_blank"
                              rel="noreferrer"
                              className="text-indigo-500 hover:text-indigo-900"
                            >
                              Deal Link
                            </a>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {Error.product_name}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
                            {Error.sales_rep}
                          </td>
                          <td className="whitespace-nowrap px-6 py-4 text-left text-sm font-medium">
                            <button
                              onClick={() => handleResolve(Error.id)}
                              className="inline-flex items-start px-2 py-1 border border-transparent rounded-md shadow-sm text-xs font-medium text-white bg-green-500 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                            >
                              Resolve
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="px-4 py-5">
        <h2 className="pb-6 text-xl font-medium gap-6 grid grid-cols-2">
          {isActualMap ? "Actual" : "Forecasted"} Product Heat-Map
          <Switch.Group as="div" className="flex items-center">
            <Switch.Label as="span" className="mr-3">
              <span className="text-sm font-medium text-gray-900">
                Forecasted Products
              </span>
            </Switch.Label>
            <Switch
              checked={isActualMap}
              onChange={seIsActualMap}
              className={classNames(
                isActualMap ? "bg-indigo-600" : "bg-gray-200",
                "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              )}
            >
              <span className="sr-only">Use setting</span>
              <span
                aria-hidden="true"
                className={classNames(
                  isActualMap ? "translate-x-5" : "translate-x-0",
                  "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                )}
              />
            </Switch>
            <Switch.Label as="span" className="ml-3">
              <span className="text-sm font-medium text-gray-900">
                Actual Products
              </span>
            </Switch.Label>
          </Switch.Group>
        </h2>
        <Chart
          options={HeatMapOptions}
          series={isActualMap ? ActualProductMap : ProductMap}
          type="heatmap"
          width="100%"
          height="1800"
        />
      </div>
    </SimpleCard>
  );
};

export default ForecastView;
