import React, { useEffect, useState } from "react";
import axios from "axios";
import { ArrowLeftIcon } from "@heroicons/react/outline";

//COOKIES
import { useCookies } from "react-cookie";

// COMPONENTS
import Card from "../../../../../components/Card/simpleCard";

const OrderEstimateUniqueView = () => {
  const [cookies, setCookie] = useCookies(["user"]);
  const [estimateDetails, setEstimateDetails] = useState(null);
  const [estimateBreakdown, setEstimateBreakdown] = useState(null);
  const [estimateID, setEstimateID] = useState(null);
  const [buildPrices, setBuildPrices] = useState([]);

  useEffect(() => {
    async function getEstimateDetails() {
      setEstimateID(window.location.href.split("/").pop());
      try {
        const response = await axios({
          method: "get",
          url: `/sales/estimate/${window.location.href.split("/").pop()}`,
          config: { headers: { "Content-Type": "application/json" } },
          headers: {
            "X-CSRFToken":
              "OROsBbAGbBdam2JE6BQ5oxPi2eIVn6syDJ8pOMJbj55okrxJytm8EjKzsOPhNfdk",
            Authorization: `Bearer ${cookies.amsterdam}`,
          },
        });
        setEstimateDetails(response.data);
        let structures = response.data.data_json.systems;
        let builds = {};
        let RunningBuildPrices = [];
        if (structures && structures.length !== 0) {
          for (const entry of structures) {
            if (!builds[entry.machine]) builds[entry.machine] = [];
            let clonedComps = [...entry.components];
            for (const compEntry of clonedComps) {
              builds[entry.machine].push(compEntry);
            }
          }
          let lineItem = 1;
          for (const [index, entry] of Object.entries(builds)) {
            RunningBuildPrices[index] = 0;
            for (const item of entry) {
              item.lineItem = lineItem++;
              RunningBuildPrices[index] += item.price;
            }
          }
          setEstimateBreakdown(builds);
          setBuildPrices(RunningBuildPrices);
        }
        try {
          const order_response = await axios({
            method: "get",
            url: `/sales/orders/${response.data.order}`,
            config: { headers: { "Content-Type": "application/json" } },
            headers: {
              "X-CSRFToken":
                "OROsBbAGbBdam2JE6BQ5oxPi2eIVn6syDJ8pOMJbj55okrxJytm8EjKzsOPhNfdk",
              Authorization: `Bearer ${cookies.amsterdam}`,
            },
          });
          let lead = order_response.data?.lead;
          if (lead)
            setCookie("leadDetails", lead, {
              path: "/",
              secure: true,
              sameSite: true,
            });
        } catch (e) {
          console.error("ERROR IS HERE", e.response);
        }
      } catch (error) {
        console.error("ERROR IS HERE", error.response);
      }
    }
    getEstimateDetails();
  }, []);

  return (
    <div>
      <Card>
        <div className="py-5 flex flex-row items-start justify-between">
          <div>
            <h2
              id="applicant-information-title"
              className="text-lg leading-6 font-medium text-gray-900"
            >
              Estimate Information
            </h2>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Estimate details and Overview.
            </p>
          </div>
          <div className="flex flex-row gap-4">
            <a
              type="button"
              href={`/sales/orders/${window.location.href.split("/")[5]}`}
              className="inline-flex items-center justify-center px-4 py-2 shadow-sm text-lg font-medium rounded-md text-white bg-red-500 hover:bg-red-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
            >
              <ArrowLeftIcon className="h-5 w-5 mr-1" aria-hidden="true" />

              <span>Back</span>
            </a>
            <a
              href={`/sales/estimate?uuid=${estimateID}`}
              className="inline-flex items-center justify-center px-4 py-2 shadow-sm text-lg font-medium rounded-md text-white bg-blue-600 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
            >
              <span>Edit Estimate</span>
            </a>
          </div>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Estimate ID</dt>
              <dd className="mt-1 text-base font-medium text-gray-900">
                {estimateDetails?.estimate_id}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Email address
              </dt>
              <dd className="mt-1 text-base font-medium text-gray-900">
                {estimateDetails?.email}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Estimate Total
              </dt>
              <dd className="mt-1 text-xl font-medium text-green-800">
                {estimateDetails?.data_json?.estimate_total
                  ?.toFixed(2)
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                {estimateDetails?.data_json?.currency}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Created On</dt>
              <dd className="mt-1  text-xl font-medium text-gray-900">
                {estimateDetails?.created_at.split("T")[0]}
              </dd>
            </div>
          </dl>
        </div>
      </Card>
      <Card>
        <h2 className="text-lg leading-6 font-medium text-gray-900 py-6 ">
          ESTIMATE BREAKDOWN:
        </h2>
        <table className="min-w-full border-2 border-gray-200 divide-y divide-gray-200">
          <thead className="bg-gray-200">
            <tr>
              <th
                scope="col"
                className="px-6 py-4 text-left text-sm font-medium text-gray-900 uppercase tracking-wider"
              >
                Component
              </th>
              <th
                scope="col"
                className="px-6 py-4 text-left text-sm font-medium text-gray-900 uppercase tracking-wider"
              >
                Cost per Unit
              </th>
              <th
                scope="col"
                className="px-6 py-4 text-left text-sm font-medium text-gray-900 uppercase tracking-wider"
              >
                Quantity
              </th>
              <th
                scope="col"
                className="px-6 py-4 text-left text-sm font-medium text-gray-900 uppercase tracking-wider"
              >
                Discount
              </th>
              <th
                scope="col"
                className="px-6 py-4 text-sm text-right font-medium text-gray-900 uppercase tracking-wider"
              >
                Price
              </th>
              <th scope="col" className="relative px-6 py-3">
                <span className="sr-only">Edit</span>
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {estimateBreakdown &&
              estimateBreakdown.length !== 0 &&
              Object.entries(estimateBreakdown).map(([key, subject], i) => (
                <React.Fragment>
                  <tr key={i} className="bg-gray-50 ">
                    <td className="py-2 px-6 text-xl text-gray-900 font-semibold">
                      {key ? `Build #${key}` : "Add-Ons"}:
                    </td>
                    <td />
                    <td />
                    <td />
                    <td className="px-6 py-4 whitespace-nowrap font-bold leading-2 text-green-800 text-right text-lg">
                      $
                      {buildPrices[key]
                        ?.toFixed(2)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </td>
                    <td />
                  </tr>
                  {subject.map((entry, index) => (
                    <tr key={index}>
                      <td className="flex flex-row items-center">
                        <div
                          className={`pl-12 ${
                            index !== 0 ? "pl-20" : "font-semibold"
                          } py-4 whitespace-nowrap text-gray-600`}
                        >
                          {entry.productName}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span>$</span>
                        {entry.cost
                          ?.toFixed(2)
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {entry.qty}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {entry.discount}%
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-gray-600 text-right">
                        <span>$</span>
                        {entry.price
                          ?.toFixed(2)
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </td>
                    </tr>
                  ))}
                </React.Fragment>
              ))}
          </tbody>
        </table>
      </Card>
    </div>
  );
};

export default OrderEstimateUniqueView;
