import React from "react";

const SimpleWLink = ({ data, headers, linkTo, linkTitle }) => {
  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  {headers.map((entry, index) => {
                    return (
                      <th
                        key={index}
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {entry}
                      </th>
                    );
                  })}
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Edit
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {data &&
                  data.length !== 0 &&
                  data.map((entry, index) => {
                    const entryKeys = Object.keys(entry);
                    return (
                      <tr key={index}>
                        {entryKeys.map((dataKey, index) => {
                          return (
                            <td
                              key={index}
                              className={`${
                                dataKey === "status"
                                  ? "px-3 py-0.5 m-4 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"
                                  : "px-6 py-4 whitespace-nowrap text-sm font-medium "
                              }`}
                            >
                              {entry[dataKey]}
                            </td>
                          );
                        })}
                        <td>
                          <a
                            href={`${linkTo}/${entry.id ? entry.id : ""}`}
                            className="text-blue-600 hover:text-blue-900"
                          >
                            {linkTitle}
                          </a>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SimpleWLink;
