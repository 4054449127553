import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";

// HOOKS
import useEstimate from "./index";

const useEstimateForm = () => {
  const {
    estimateState,
    updateFormPage,
    handleFormSubmission,
    fetchPrice,
    updateTotalPrice,
    updateStructure,
    updateBuildIndex,
  } = useEstimate();

  const { headObject, formStructure, pageOrder } = estimateState;
  const [currentFormStructure, setCurrentFormStructure] = useState(null);
  const methods = useForm();
  const { reset, setValue, watch } = methods;
  const [watchFields, setWatchFields] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [startingPrice, setStartingPrice] = useState(null);
  const watchAllFields = watch();

  useEffect(() => {
    if (formStructure[headObject]) {
      setStartingPrice(null);
      const { defaults, index } = formStructure[headObject];
      reset({}, { keepDefaultValues: true });
      setCurrentFormStructure(formStructure[headObject]);
      if (defaults[index]) {
        Object.entries(defaults[index]).forEach((entry) => {
          const [key, value] = entry;
          setValue(key, value);
        });
      }
      setIsSubmitting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageOrder, reset]);

  useEffect(() => {
    async function updateTotals() {
      let price;
      if (!startingPrice) {
        price = await fetchPrice(
          formStructure[headObject].defaults[currentFormStructure.index],
          currentFormStructure.structure
        );
        setStartingPrice(price);
      }
      let runningPrice =
        formStructure[headObject].price -
        (startingPrice ? startingPrice : price);
      runningPrice = runningPrice < 0 ? 0 : runningPrice;
      let currentPrice = await fetchPrice(
        watchAllFields,
        currentFormStructure.structure
      );
      let total = runningPrice + currentPrice;
      formStructure[headObject].price = total;
      updateTotalPrice(formStructure);
      setStartingPrice(currentPrice);
    }
    if (JSON.stringify(watchFields) !== JSON.stringify(watchAllFields)) {
      setWatchFields(watchAllFields);
      if (
        currentFormStructure &&
        !isSubmitting &&
        Object.entries(watchAllFields).length !== 0
      ) {
        updateTotals();
        handleTrayDefaults(
          watchAllFields,
          currentFormStructure.structure.category,
          currentFormStructure.head
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchAllFields]);

  async function updatePage(next, increment) {
    const { head } = currentFormStructure;
    let struct = estimateState.formStructure;
    if (increment) {
      struct[head].index += 1;
      updateFormPage("increment", next);
      updateStructure(struct);
    } else {
      if (struct[next].index > 0) struct[next].index -= 1;
      updateStructure(struct);
      updateFormPage("decrement", next);
    }
  }

  async function validateNext(next) {
    const { formStructure, buildIndex } = estimateState;
    let machineIndex = null;
    let machineStruct = formStructure.machine;
    if (next === "machine") {
      if (machineStruct.pertains[machineStruct.index]) {
        machineIndex = machineStruct.pertains[machineStruct.index].machine;
        machineIndex && updateBuildIndex(machineIndex);
      } else {
        updatePage("packaging", true);
      }
    }
    if (next) {
      let potentialNext = formStructure[next];
      let comparingIndex = machineIndex ? machineIndex : buildIndex;
      if (potentialNext.pertains.length === 0) {
        if (!(machineStruct.index > machineStruct.repeat))
          validateNext("machine");
        else updatePage(next, true);
      } else if (
        parseInt(potentialNext.pertains[potentialNext.index]?.machine) !==
        parseInt(comparingIndex)
      ) {
        validateNext(potentialNext.next);
      } else {
        updatePage(next, true);
      }
    } else {
      updateFormPage("review");
    }
  }

  async function handleDecrement(previous, key) {
    setIsSubmitting(true);
    const { structure, index, pertains } = currentFormStructure;
    const { headObject } = estimateState;
    const { formStructure, buildIndex } = estimateState;
    let machineStruct = formStructure.machine;
    let comparingIndex = key ? key : buildIndex;
    if (previous) {
      let potentialPrevious = formStructure[previous];
      if (
        parseInt(
          potentialPrevious.pertains[potentialPrevious.index - 1]?.machine
        ) !== parseInt(comparingIndex) &&
        previous !== "packaging"
      ) {
        handleDecrement(potentialPrevious.previous, comparingIndex);
      } else {
        updatePage(previous);
      }
    } else {
      if (
        machineStruct.index === machineStruct.repeat &&
        machineStruct.pertains.length > 1
      ) {
        let workingIndex = (parseInt(buildIndex) - 1).toString();
        updateBuildIndex(workingIndex);
        handleDecrement("vessel", workingIndex);
      } else updateFormPage("container");
    }
    await handleFormSubmission(
      watchAllFields,
      structure,
      pageOrder,
      headObject,
      pertains[index] ? pertains[index].product : "",
      pertains[index] ? pertains[index].machine : ""
    );
  }

  async function setTrayEntries(machine, nozzle, system, actual) {
    !formStructure.trays.pertains[formStructure.trays.index].entries &&
      (formStructure.trays.pertains[formStructure.trays.index].entries = {});
    try {
      const tray_response = await axios({
        method: "post",
        url: "/inventory/tray/",
        config: { headers: { "Content-Type": "application/json" } },
        headers: {
          "X-CSRFToken":
            "OROsBbAGbBdam2JE6BQ5oxPi2eIVn6syDJ8pOMJbj55okrxJytm8EjKzsOPhNfdk",
        },
        data: {
          robot_type: machine,
          setup_type: system,
          number_of_nozzles: nozzle,
        },
      });

      let trayEntries = {
        individual: `${tray_response.data[0].sku}-${tray_response.data[0].title
          .split("-")
          .join("")}`,
        set: `${tray_response.data[1].sku}-${tray_response.data[1].title
          .split("-")
          .join("")}`,
      };
      let systemName = actual ? actual : system;
      systemName === "volumetric" && (systemName = "Volumetric");
      formStructure.trays.pertains[formStructure.trays.index].entries[
        systemName
      ] = trayEntries;
    } catch (error) {
      console.error("ERROR IS HERE", error.response);
    }
  }

  async function handleTrayDefaults(data, category, head) {
    if (category === "Machine" && data.auto_filling_machine) {
      let systemType = data.auto_filling_machine.split(" ")[1];
      systemType &&
        systemType.length > 3 &&
        (systemType = systemType.slice(0, -1));
      formStructure.trays.pertains[formStructure.trays.index].machineType =
        systemType;
    } else if (category === "setup") {
      let nozzle;
      switch (head) {
        case "peristaltic":
          const filteredData = Object.values(data).filter(
            (v) =>
              v !== null &&
              typeof v === "string" &&
              v !== "" &&
              v.split(" ").pop() === "Pump"
          );
          nozzle = filteredData.reduce(function (a, b) {
            a =
              typeof v === "string"
                ? parseInt(a.split(" ").slice(-2, -1)[0])
                : a;
            b = parseInt(b.split(" ").slice(-2, -1)[0]);
            return a > b ? a : b;
          });
          typeof nozzle === "string" &&
            (nozzle = parseInt(nozzle.split(" ").slice(-2, -1)[0]));
          await setTrayEntries(
            formStructure.trays.pertains[formStructure.trays.index].machineType,
            nozzle,
            "Peristaltic"
          );
          break;
        case "volumetric":
        case "volumetricDeluxe":
          let nData = data.volumetric_nozzles
            ? data.volumetric_nozzles
            : data.volumetric_deluxe_nozzles;
          nozzle = parseInt(nData.split(" ").slice(-2, -1)[0]);
          await setTrayEntries(
            formStructure.trays.pertains[formStructure.trays.index].machineType,
            nozzle,
            "Volumetric",
            head
          );
          break;
        case "pneumatic":
          nozzle = parseInt(data.pneumatic_Nozzle.split(" ").slice(-2, -1)[0]);
          await setTrayEntries(
            formStructure.trays.pertains[formStructure.trays.index].machineType,
            nozzle,
            "Pneumatic"
          );
          break;
        case "pneumPeristaltic":
          nozzle = parseInt(
            data["pnuem-peri_nozzle"].split(" ").slice(-2, -1)[0]
          );
          await setTrayEntries(
            formStructure.trays.pertains[formStructure.trays.index].machineType,
            nozzle,
            "Peristaltic",
            head
          );
          break;
        default:
          break;
      }
    }
  }

  const submitForm = async (data) => {
    setIsSubmitting(true);
    const { structure, next, index, pertains } = currentFormStructure;
    const { headObject } = estimateState;
    await handleFormSubmission(
      data,
      structure,
      pageOrder,
      headObject,
      pertains[index] ? pertains[index].product : "",
      pertains[index] ? pertains[index].machine : ""
    );
    validateNext(next);
  };

  return {
    currentFormStructure,
    methods,
    submitForm,
    handleDecrement,
    isSubmitting,
    // formStructure,
  };
};
export default useEstimateForm;
