import React, { useEffect, useState } from "react";
import axios from "axios";

//COOKIES
import { useCookies } from "react-cookie";

// COMPONENTS
import CardWHeader from "../../../components/Card/cardWHeader";
import Table from "../../../components/Table/simpleWLink";

const OrderView = () => {
  const [OrdersData, setOrdersData] = useState(null);
  const [cookies] = useCookies(["user"]);

  const SampleHeaders = [
    "ID",
    "Organization",
    "Estimates Made",
    "Selected Estimate",
    "Status",
  ];

  useEffect(() => {
    async function getOrders() {
      try {
        const orders_response = await axios({
          method: "get",
          url: "/sales/orders",
          config: { headers: { "Content-Type": "application/json" } },
          headers: {
            "X-CSRFToken":
              "OROsBbAGbBdam2JE6BQ5oxPi2eIVn6syDJ8pOMJbj55okrxJytm8EjKzsOPhNfdk",
            Authorization: `Bearer ${cookies.amsterdam}`,
          },
        });
        const filteredOrders = await orders_response.data.results.map(
          (entry) => {
            return {
              id: entry.id,
              organization: entry.lead?.company_name,
              estimatesMade: entry.estimates.length,
              selectedEstimates: entry.selected_estimate
                ? entry.selected_estimate
                : "Pending",
              status: entry.status,
            };
          }
        );
        setOrdersData(filteredOrders);
      } catch (error) {
        console.error("ERROR IS HERE", error.response);
      }
    }
    getOrders();
  }, []);

  return (
    <div>
      <CardWHeader
        heading={{
          title: "Orders",
          subTitle:
            "Explore estimate status pertaining to an order and create new",
        }}
      >
        <Table
          headers={SampleHeaders}
          data={OrdersData}
          linkTo="/sales/orders"
          linkTitle="View"
        />
      </CardWHeader>
    </div>
  );
};

export default OrderView;
