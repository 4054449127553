import DynamicRoutesList from "./routeValues";

const changeRoutesStructure = async (routes) => {
  const [parents, children] = routes.reduce(
    ([parents, children], entry) =>
      !entry.parent_route
        ? [[...parents, entry], children]
        : [parents, [...children, entry]],
    [[], []]
  );
  for (const route of parents) {
    route.element = DynamicRoutesList[route.label];
  }
  for (const route of children) {
    route.element = DynamicRoutesList[route.label];
    let parentObject = parents.find((entry) => {
      return entry.label === route.parent_route;
    });
    if (!parentObject) {
      parentObject = children.find((entry) => {
        return entry.label === route.parent_route;
      });
    }
    if (!parentObject.children) {
      parentObject.children = [];
      parentObject.children.push(route);
    } else {
      parentObject.children.push(route);
    }
  }
  return parents;
};

export default changeRoutesStructure;
