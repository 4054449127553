const NPSGaugeOptions = (score) => {
  let positive = score >= 0;
  return {
    chart: {
      height: 350,
      type: "radialBar",
      offsetY: -10,
    },
    plotOptions: {
      radialBar: {
        startAngle: -110,
        endAngle: 110,
        dataLabels: {
          name: {
            fontSize: "16px",
            color: undefined,
            offsetY: 120,
          },
          value: {
            offsetY: 76,
            fontSize: "22px",
            color: undefined,
            formatter: function (val) {
              return positive ? val : `-${val}`;
            },
          },
        },
      },
    },
    colors: positive ? ["#00E396"] : ["#EA3546"],
    stroke: {
      dashArray: 4,
    },
    labels: ["NPS Score"],
  };
};

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  NPSGaugeOptions,
};
