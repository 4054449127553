import React, {useReducer, createContext} from 'react';

export const UserContext = createContext ();

const initialState = {
  userObject: null,
  isUserLoggedIn: false,
  loading: false,
  error: false,
  routes: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_USER_DATA_INIT':
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case 'FETCH_USER_DATA_SUCCESS':
      return {
        ...state,
        isLoading: false,
        isError: false,
        userObject: action.payload.user,
        routes: action.payload.routes,
        isUserLoggedIn: true,
      };
    case 'FETCH_USER_DATA_FAILURE':
      return {...state, isLoading: false, isError: true};
    case 'RESET_USER_DATA':
      return {...state, userObject: null, isUserLoggedIn: false};
    default:
      return {...state};
  }
};

export const UserContextProvider = props => {
  const [state, dispatch] = useReducer (reducer, initialState);

  return (
    <UserContext.Provider value={[state, dispatch]}>
      {props.children}
    </UserContext.Provider>
  );
};
