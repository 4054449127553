import React, { useState, useEffect } from "react";
import { ArrowLeftIcon } from "@heroicons/react/outline";
import axios from "axios";

//COOKIES
import { useCookies } from "react-cookie";

// HOOKS
import useEstimate from "../../../hooks/useEstimate";

const ReviewView = () => {
  const { estimateState, updateFormPage, reviewTotalPrice } = useEstimate();
  const [cookies] = useCookies(["user"]);
  const [reviewBuild, setReviewBuild] = useState(null);
  const [onMountBuild, setOnMountBuild] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);
  const [focusedValue, setFocusedValue] = useState(null);
  const [focusedType, setFocusedType] = useState(null);
  const [buildPrices, setBuildPrices] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    let structure = JSON.parse(JSON.stringify(estimateState.buildSeries));
    let builds = {};
    let RunningBuildPrices = [];
    if (structure && structure.length !== 0) {
      const clonedSeries = [...structure[0]];
      for (const entry of clonedSeries) {
        if (!builds[entry.machine]) builds[entry.machine] = [];
        let clonedComps = [...entry.components];
        for (const compEntry of clonedComps) {
          builds[entry.machine].push(compEntry);
        }
      }
      let lineItem = 1;
      for (const [index, entry] of Object.entries(builds)) {
        RunningBuildPrices[index] = 0;
        for (const item of entry) {
          item.lineItem = lineItem++;
          RunningBuildPrices[index] += item.price;
        }
      }
      setReviewBuild(builds);
      setOnMountBuild(JSON.parse(JSON.stringify(builds)));
      setBuildPrices(RunningBuildPrices);
    }
  }, [estimateState.buildSeries]);

  async function handleBlur(parentIndex, childIndex, value, entity) {
    setFocusedType(null);
    let Build = reviewBuild;
    let prices = buildPrices;
    let onMount = JSON.parse(
      JSON.stringify(onMountBuild[parentIndex][childIndex])
    );
    let price = 0;
    let quantity = Build[parentIndex][childIndex].qty;
    if (entity === "cost") {
      if (value > onMount.cost) {
        price =
          (parseFloat(value) *
            quantity *
            (100 - Build[parentIndex][childIndex].discount)) /
          100;
        Build[parentIndex][childIndex].cost = parseFloat(value);
      } else {
        Build[parentIndex][childIndex].cost = onMount.cost;
        price = onMount.price;
      }
    } else {
      price =
        (Build[parentIndex][childIndex].cost *
          quantity *
          (100 - parseFloat(value))) /
        100;
      Build[parentIndex][childIndex].discount = parseFloat(value);
    }
    let currentTotalPrice = estimateState.totalPrice;
    let currentPrice = Build[parentIndex][childIndex].price;
    let newTotalPrice = currentTotalPrice - currentPrice + price;
    prices[parentIndex] =
      prices[parentIndex] - Build[parentIndex][childIndex].price + price;
    Build[parentIndex][childIndex].price = price;
    setBuildPrices(prices);
    setReviewBuild(Build);
    reviewTotalPrice(newTotalPrice);
  }

  async function handleFocus(parentIndex, childIndex, value, entity) {
    let Build = reviewBuild;
    setFocusedInput(Build[parentIndex][childIndex].lineItem);
    setFocusedValue(value);
    setFocusedType(entity);
  }
  async function finalizeEstimate() {
    setIsSubmitting(true);
    const EstimateID = window.location.href.split("=")[1];
    const mergedBuild = [].concat.apply([], estimateState.buildSeries);
    const data_json = {
      lead_id: cookies.leadDetails.id,
      estimateName: "Sample",
      estimate_id: Math.floor(Math.random() * (99999 - 0) + 0),
      estimate_total: estimateState.totalPrice,
      currency: "CAD",
      systems: mergedBuild,
      parameters: estimateState.parameters,
      products: estimateState.products,
      structure: estimateState.formStructure,
    };
    if (EstimateID) {
      try {
        const delete_estimate_res = await axios({
          method: "delete",
          url: `/sales/estimate/${EstimateID}`,
          config: { headers: { "Content-Type": "application/json" } },
          headers: {
            "X-CSRFToken":
              "OROsBbAGbBdam2JE6BQ5oxPi2eIVn6syDJ8pOMJbj55okrxJytm8EjKzsOPhNfdk",
            Authorization: `Bearer ${cookies.amsterdam}`,
          },
        });
      } catch (error) {
        console.error("ERROR IS HERE", error.response);
      }
    }
    try {
      const response = await axios({
        method: "post",
        url: "/sales/estimate",
        config: { headers: { "Content-Type": "application/json" } },
        headers: {
          "X-CSRFToken":
            "OROsBbAGbBdam2JE6BQ5oxPi2eIVn6syDJ8pOMJbj55okrxJytm8EjKzsOPhNfdk",
        },
        data: data_json,
      });
      console.log(response);
    } catch (error) {
      console.error("ERROR IS HERE", error.response);
    }
    updateFormPage("finalize");
  }
  return (
    <div>
      <div className="border-b border-gray-200 pb-4 mb-2 flex flex-row justify-between items-center mx-6">
        <div>
          <h3 className="text-xl font-semibold leading-6 text-gray-900">
            Review Build
          </h3>
        </div>
        <div className="mt-3 flex  flex-col sm:mt-0 sm:ml-4">
          <div className="flex flex-row">
            <button
              type="button"
              onClick={() => updateFormPage("unsetReview")}
              className="inline-flex items-center px-5 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-red-600 hover:bg-red-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <ArrowLeftIcon className="h-5 w-5 mr-1" aria-hidden="true" />
              Edit Build
            </button>
            <button
              onClick={finalizeEstimate}
              id="finalizeEstimate"
              className="inline-flex ml-3  items-center px-5 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              {isSubmitting && (
                <svg
                  className="animate-spin h-5 w-5 text-white self-center mr-2"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 40 40"
                  enableBackground="new 0 0 40 40"
                  xml="preserve"
                >
                  <path
                    opacity="0.2"
                    d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"
                  />
                  <path d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z" />
                </svg>
              )}
              Finalize
            </button>
          </div>
        </div>
      </div>
      <table
        className={`min-w-full border-2 border-gray-200 divide-y divide-gray-200 ${
          isSubmitting && "opacity-40"
        }`}
      >
        <thead className="bg-gray-200">
          <tr>
            <th
              scope="col"
              className="px-6 py-4 text-left text-sm font-medium text-gray-900 uppercase tracking-wider"
            >
              Component
            </th>
            <th
              scope="col"
              className="px-6 py-4 text-left text-sm font-medium text-gray-900 uppercase tracking-wider"
            >
              Cost per Unit
            </th>
            <th
              scope="col"
              className="px-6 py-4 text-left text-sm font-medium text-gray-900 uppercase tracking-wider"
            >
              Quantity
            </th>
            <th
              scope="col"
              className="px-6 py-4 text-left text-sm font-medium text-gray-900 uppercase tracking-wider"
            >
              Discount
            </th>
            <th
              scope="col"
              className="px-6 py-4 text-sm text-right font-medium text-gray-900 uppercase tracking-wider"
            >
              Price
            </th>
            {/* <th scope="col" className="relative px-6 py-3">
              <span className="sr-only">Edit</span>
            </th> */}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {reviewBuild &&
            reviewBuild.length !== 0 &&
            Object.entries(reviewBuild).map(([key, subject], i) => (
              <React.Fragment>
                <tr key={i} className="bg-gray-50 ">
                  <td className="py-2 px-6 text-xl text-gray-900 font-semibold">
                    {key ? `Build #${key}` : "Add-Ons"}:
                  </td>
                  <td />
                  <td />
                  <td />
                  <td className="px-6 py-4 whitespace-nowrap font-bold leading-2 text-green-800 text-right text-lg">
                    $
                    {estimateState.currency
                      ? (buildPrices[key] * 0.8)
                          .toFixed(2)
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : buildPrices[key]
                          ?.toFixed(2)
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </td>
                  {/* <td /> */}
                </tr>
                {subject.map((entry, index) => (
                  <tr key={index}>
                    <td className="flex flex-row items-center">
                      <div
                        className={`pl-12 ${
                          index !== 0 ? "pl-20" : "font-semibold"
                        } py-4 whitespace-nowrap text-gray-600`}
                      >
                        {entry.productName}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <input
                        type="number"
                        step="0.01"
                        min="0"
                        name={entry.productName + entry.lineItem}
                        value={
                          focusedType === "cost" &&
                          focusedInput === entry.lineItem
                            ? focusedValue < 0 || !focusedValue
                              ? 0
                              : focusedValue
                            : entry.cost
                        }
                        onFocus={() =>
                          handleFocus(key, index, entry.cost, "cost")
                        }
                        onBlur={(e) =>
                          handleBlur(key, index, e.target.value, "cost")
                        }
                        onChange={(e) => setFocusedValue(e.target.value)}
                        className="flex-1 block w-1/2 focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-md sm:text-sm p-1 border border-gray-300"
                      />
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">{entry.qty}</td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <input
                        type="number"
                        step="0.01"
                        min="0"
                        max="100"
                        value={
                          focusedType === "discount" &&
                          focusedInput === entry.lineItem
                            ? focusedValue > 100
                              ? 100
                              : focusedValue < 0 || !focusedValue
                              ? 0
                              : focusedValue
                            : entry.discount
                        }
                        onFocus={() =>
                          handleFocus(key, index, entry.discount, "discount")
                        }
                        onBlur={(e) =>
                          handleBlur(key, index, e.target.value, "discount")
                        }
                        onChange={(e) => setFocusedValue(e.target.value)}
                        className="flex-1  focus:ring-indigo-500 w-1/4 focus:border-indigo-500 rounded-md sm:text-sm p-1 border border-gray-300"
                      />
                      <span className=" ml-2 text-gray-600">%</span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-gray-600 text-right">
                      <span>$</span>
                      {estimateState.currency
                        ? (entry.price * 0.8)
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        : entry.price
                            ?.toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </td>
                    {/* <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <a
                        href="/"
                        className="text-indigo-600 hover:text-indigo-900"
                      >
                        Edit
                      </a>
                    </td> */}
                  </tr>
                ))}
              </React.Fragment>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default ReviewView;
