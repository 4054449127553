import React from "react";
import { CheckIcon, PlusCircleIcon } from "@heroicons/react/outline";

// HOOKS
import useEstimate from "../../../hooks/useEstimate";
import useEstimateParameters from "../../../hooks/useEstimate/useEstimateParameters";

const ParameterView = () => {
  const { estimateState, updateFormPage } = useEstimate();
  const {
    submitParam,
    handleSubmit,
    handleAddSelection,
    setToReview,
    parameters,
  } = useEstimateParameters();
  return (
    <form onSubmit={handleSubmit(submitParam)} className="xl:mx-6">
      <div className="border-b border-gray-200 pb-4 flex flex-col md:flex-row justify-between items-center mb-4 ">
        <div>
          <h3 className="text-xl leading-6 font-semibold text-gray-900">
            Production Selection
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            Provide production details to configure the appropriate ATG setup(s)
          </p>
        </div>
        <div className="mt-3 flex sm:mt-0 sm:ml-4">
          <button
            type="button"
            onClick={() => updateFormPage("product")}
            className="inline-flex items-center px-5 py-2 border border-gray-300 rounded-md shadow-sm text-base font-medium text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Return to Liquid Details
          </button>
          <button
            type="submit"
            className="inline-flex ml-3  items-center px-5 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Build Step-By-Step
          </button>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-2 lg:gap-5 sm:grid-cols-13 font-medium">
        <h3 className="ml-4 col-span-2">Liquid</h3>
        <h3 className="col-span-2">Filling Into:</h3>
        <h3 className="col-span-2">Volume</h3>
        <h3 className="col-span-2">Requires Heating?</h3>
        <h3 className="col-span-2">On Machine:</h3>
        <h3 className="col-span-2">System Selected</h3>
        <h3 className="col-span-1">Remove</h3>
      </div>
      {parameters}
      <div className="border-t border-gray-200 pb-4 mt-8 flex flex-row justify-between items-center mx-6">
        <button
          type="button"
          onClick={handleAddSelection}
          className="inline-flex mt-4 items-center px-3 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-blue-600 hover:bg-blue-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          <PlusCircleIcon className="h-5 w-5 mr-2" aria-hidden="true" />
          Add Setup
        </button>
        <button
          type="submit"
          id="completeBuild"
          disabled={estimateState.isLoading}
          onClick={() => setToReview(true)}
          className="inline-flex mt-4 items-center px-3 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-green-600 hover:bg-green-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          {estimateState.isLoading ? (
            <svg
              className="animate-spin h-5 w-5 text-white self-center mr-2"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 40 40"
              enableBackground="new 0 0 40 40"
              xml="preserve"
            >
              <path
                opacity="0.2"
                d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"
              />
              <path d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z" />
            </svg>
          ) : (
            <CheckIcon className="h-5 w-5 mr-2" aria-hidden="true" />
          )}
          Build For Me
        </button>
      </div>
    </form>
  );
};

export default ParameterView;
