import React from "react";

// HOOKS
import useEstimateProducts from "../../../hooks/useEstimate/useEstimateProducts";

const ProductView = () => {
  const { handleSubmit, handleSubmitProds, products, handleProducts, errors } =
    useEstimateProducts();

  return (
    <form onSubmit={handleSubmit(handleSubmitProds)} className="xl:mx-6">
      <div className="border-b border-gray-200 pb-4 flex flex-col md:flex-row justify-between items-end md:items-center mb-4 ">
        <div>
          <h3 className="text-xl leading-6 font-semibold text-gray-900">
            Liquid Details
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            The following parameters assist in determining setups required to
            fill the client's products.
          </p>
        </div>
        <div className="mt-3 flex sm:mt-0 sm:ml-4">
          <button
            type="submit"
            className="inline-flex ml-3  items-center px-5 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Next
          </button>
        </div>
      </div>
      <div className="ml-2 w-full flex flex-col lg:flex-row items-start lg:items-end">
        <h3 className="text-lg font-medium text-gray-800 ">
          1. How many unique liquids are required to be filled?
          <span className="italic tracking-tight whitespace-normal md:whitespace-pre flex flex-row text-xs text-gray-400 ">
            2 fluids from the same setup that can cross contaminate are
            considered 1 liquid
          </span>
        </h3>
        <div>
          <div className="flex flex-row mt-4 lg:mt-0 lg:ml-12 mb-2 h-4/5">
            <input
              type="number"
              step="1"
              min="1"
              defaultValue={1}
              onChange={(e) => handleProducts(e.target.value)}
              className="shadow-sm sm:text-sm p-1 border border-gray-300 rounded-l-md"
            />
            <span className="flex items-center px-3 rounded-r-md border border-l-0 border-gray-400 bg-gray-50 text-gray-500 sm:text-sm">
              Liquids
            </span>
          </div>
          {errors.products && (
            <p className="text-red-600 text-xs ml-12">
              {errors.products.message}
            </p>
          )}
        </div>
      </div>
      <h3 className="ml-2 font-medium text-lg text-gray-800 mt-8 mb-4">
        2. Complete details for each liquid
      </h3>
      <div className="ml-3 lg:ml-8 grid grid-cols-2 xl:grid-cols-3 gap-5 font-medium">
        <h3>Liquid Name</h3>
        <h3>Pertaining Industry</h3>
      </div>
      {products}
    </form>
  );
};

export default ProductView;
