import React from "react";
import { useFormContext } from "react-hook-form";

// FORM COMPONENTS
import SimpleText from "../../components/FormComponents/text/simpleText";
import SimpleRadio from "../../components/FormComponents/radio/simpleRadio";
import SimpleDropdown from "../../components/FormComponents/dropdown/simpleWLabel";
import ModularTable from "../../components/FormComponents/table/modularTable";
import TextWTag from "../../components/FormComponents/text/textWTag";
import SimpleCheckbox from "../../components/FormComponents/checkbox/simpleCheckbox";
import TraysTable from "../../components/FormComponents/table/traysTable";

const formComponents = {
  SimpleText,
  SimpleRadio,
  SimpleDropdown,
  ModularTable,
  TextWTag,
  SimpleCheckbox,
  TraysTable,
};

const FormComponentSelection = ({ props }) => {
  const methods = useFormContext();
  const SelectedComponent = formComponents[props.type];
  return <SelectedComponent props={props} {...methods} />;
};

export default FormComponentSelection;

export { formComponents };
