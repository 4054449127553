import React, { Fragment, useState, useEffect } from "react";
import { CheckIcon } from "@heroicons/react/solid";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationIcon, ArrowLeftIcon } from "@heroicons/react/outline";

//COOKIES
import { useCookies } from "react-cookie";

// HOOKS
import useEstimate from "../../../../../hooks/useEstimate";

// COMPONENTS
import Table from "../../../../../components/Table/simpleEstimateSelector";

const SampleHeaders = ["ID", "Last Updated", "Total"];

const OrderDetailsView = ({ order }) => {
  const [cookies, setCookie] = useCookies(["user"]);
  const { updateFormPage, predictEstimate } = useEstimate();
  const [estimateData, setEstimateData] = useState([]);
  const [leadData, setLeadData] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    async function handleEstimateData() {
      if (order) {
        setLeadData(order.lead);
        const filteredEstimates = order.estimates.map((entry) => {
          return {
            estimate_id: entry.estimate_id,
            lastUpdated: entry.updated_at.split("T")[0],
            total: `$${entry.data_json?.estimate_total
              ?.toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
          };
        });
        setEstimateData(filteredEstimates);
      }
    }
    handleEstimateData();
  }, [order]);
  async function submitOrder(data) {
    if (cookies.QBA === "true") {
      predictEstimate(data.estimate_predicted);
      updateFormPage("submit");
    } else {
      setDialogOpen(true);
    }
  }
  console.log(order);
  if (order) {
    return (
      <div>
        <Transition.Root show={dialogOpen} as={Fragment}>
          <Dialog
            as="div"
            static
            className="fixed z-10 inset-0 overflow-y-auto"
            open={dialogOpen}
            onClose={setDialogOpen}
          >
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                  <div>
                    <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                      <ExclamationIcon
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-gray-900"
                      >
                        QBO Connection Missing
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          A valid recent connection with QuickBooks online is
                          required to view, edit, and submit orders.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6">
                    <a
                      type="button"
                      href="/settings"
                      className="inline-flex justify-center w-full rounded-md border-2 border-transparent shadow-sm px-4 py-2 hover:text-gray-900  hover:border-gray-900  bg-red-500 hover:bg-transparent text-base font-medium  text-white focus:outline-none sm:text-sm"
                    >
                      Go to Settings
                    </a>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
        <div className="py-4 px-4 sm:px-6 lg:px-8 xl:flex xl:items-center xl:justify-between">
          <div className="flex-1 min-w-0">
            <h1 className="mt-2 text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
              {order.customer ? order.customer.name : order.lead.company_name}
            </h1>
            <p className="text-sm mt-2 font-medium text-gray-500">
              Industry:{" "}
              <span className="text-gray-900">{order.customer?.industry}</span>{" "}
            </p>
            <p className="text-sm font-medium text-gray-500">
              Order Created on{" "}
              <span className="text-gray-900">
                {order.created_at?.split("T")[0]}
              </span>{" "}
            </p>
          </div>
          <div className="mt-5 flex xl:mt-0 xl:ml-4">
            <span className="sm:ml-3 relative z-0 flex flex-row items-center">
              <a
                type="button"
                href="/sales/orders"
                className="inline-flex items-center justify-center px-4 py-2 mr-4 shadow-sm text-lg font-medium rounded-md text-white bg-red-500 hover:bg-red-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
              >
                <ArrowLeftIcon className="h-5 w-5 mr-1" aria-hidden="true" />

                <span>Back</span>
              </a>
              {order.status === "NEW" && (
                <button
                  type="submit"
                  id="submitOrder"
                  form="estimate-Selector"
                  className="inline-flex items-center justify-center px-4 py-2 shadow-sm text-lg font-medium rounded-md text-white bg-blue-600 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                >
                  <CheckIcon className="h-5 w-5 mr-1 " aria-hidden="true" />

                  <span>Submit Order</span>
                </button>
              )}
              {order.status !== "NEW" && order.link_uuid && (
                <a
                  href={`http://localhost:3001/review-estimates/${order.link_uuid}`}
                  type="button"
                  id="viewClientOrder"
                  form="estimate-Selector"
                  className="inline-flex items-center mr-1 justify-center px-4 py-2 shadow-sm text-lg font-medium rounded-md text-white bg-blue-600 hover:bg-blue-800  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                >
                  <span>Explore Client View</span>
                </a>
              )}
            </span>
          </div>
        </div>
        <div className="bg-white overflow-hidden shadow border border-gray-100 rounded-lg mr-8 ml-8 mt-8">
          <div className="border-b border-gray-200 py-4 mb-2 flex flex-row justify-between items-center mx-6">
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Estimate
              </h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">
                All Estimates linked to an Order for the Client
              </p>
            </div>
            {order.status === "NEW" && (
              <div className="mt-3 flex sm:mt-0 sm:ml-4">
                <a
                  onClick={() =>
                    setCookie("leadDetails", leadData, {
                      path: "/",
                      secure: true,
                      sameSite: true,
                    })
                  }
                  href="/sales/estimate"
                  className="inline-flex ml-3  items-center px-5 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Add New
                </a>
              </div>
            )}
          </div>
          <div className="px-4 py-5 sm:p-6">
            <Table
              headers={SampleHeaders}
              data={estimateData}
              linkTo="/sales/estimate"
              linkTitle="View"
              orderID={order.id}
              submitOrder={submitOrder}
              selected={order.selected_estimate}
            />
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <h1>INVALID ORDER ID</h1>
      </div>
    );
  }
};

export default OrderDetailsView;
