import Objects from "./formObjects";
import Defaults from "./formDefaults";

const {
  machineSelection,
  volumetricSelection,
  volumetricDeluxeSelection,
  peristalticSelection,
  pneumaticSelection,
  pnuemPeristalticSelection,
  productTrays,
  pressureVessel,
  packagingSelection,
  documentationSelection,
} = Objects;

const { machineDef } = Defaults;
const formStructure = {
  machine: {
    structure: machineSelection,
    defaults: [machineDef],
    pertains: [],
    price: 0,
    index: 0,
    repeat: 0,
    head: "machine",
    next: "volumetric",
    previous: null,
  },
  volumetric: {
    structure: volumetricSelection,
    defaults: [null],
    pertains: [],
    price: 0,
    index: 0,
    repeat: 0,
    head: "volumetric",
    next: "volumetricDeluxe",
    previous: "machine",
  },
  volumetricDeluxe: {
    structure: volumetricDeluxeSelection,
    defaults: [null],
    pertains: [],
    price: 0,
    index: 0,
    repeat: 0,
    head: "volumetricDeluxe",
    next: "peristaltic",
    previous: "volumetric",
  },
  peristaltic: {
    structure: peristalticSelection,
    defaults: [null],
    pertains: [],
    price: 0,
    index: 0,
    repeat: 0,
    next: "pneumatic",
    head: "peristaltic",
    previous: "volumetricDeluxe",
  },
  pneumatic: {
    structure: pneumaticSelection,
    defaults: [null],
    pertains: [],
    price: 0,
    index: 0,
    repeat: 0,
    next: "pneumPeristaltic",
    head: "pneumatic",
    previous: "peristaltic",
  },
  pneumPeristaltic: {
    structure: pnuemPeristalticSelection,
    defaults: [null],
    pertains: [],
    price: 0,
    index: 0,
    repeat: 0,
    next: "trays",
    head: "pneumPeristaltic",
    previous: "pneumatic",
  },
  trays: {
    structure: productTrays,
    defaults: [null],
    pertains: [],
    price: 0,
    index: 0,
    repeat: 0,
    next: "vessel",
    head: "trays",
    previous: "pneumPeristaltic",
  },
  vessel: {
    structure: pressureVessel,
    defaults: [null],
    pertains: [],
    price: 0,
    index: 0,
    repeat: 0,
    next: "packaging",
    head: "vessel",
    previous: "trays",
  },
  packaging: {
    structure: packagingSelection,
    defaults: [{}],
    pertains: [],
    price: 0,
    index: 0,
    repeat: 0,
    next: null,
    head: "packaging",
    previous: "vessel",
  },
  // documentation: {
  //   structure: documentationSelection,
  //   defaults: [{}],
  //   pertains: [],
  //   price: 0,
  //   index: 0,
  //   repeat: 0,
  //   next: null,
  //   head: "documentation",
  //   previous: "packaging",
  // },
};

export default formStructure;
