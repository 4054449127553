import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import axios from "axios";

// COMPONENTS
import CardWButtonHeader from "../../../components/Card/cardWButtonHeader";
import Table from "../../../components/Table/multiLineWLink";

const SampleHeaders = [
  "ID",
  "Organization",
  "Contact",
  "Expected Purchase Date",
  "Status",
  "Funding",
];

const LeadsView = () => {
  const [leadsData, setLeadsData] = useState(null);

  useEffect(() => {
    async function getLeads() {
      try {
        const leads_response = await axios({
          method: "get",
          url: "sales/lead",
          config: { headers: { "Content-Type": "application/json" } },
          headers: {
            "X-CSRFToken":
              "OROsBbAGbBdam2JE6BQ5oxPi2eIVn6syDJ8pOMJbj55okrxJytm8EjKzsOPhNfdk",
          },
        });
        setLeadsData(leads_response.data.results);
      } catch (error) {
        console.error("ERROR IS HERE", error.response);
      }
    }
    getLeads();
  }, []);
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Leads | ATG Staff</title>
      </Helmet>
      <CardWButtonHeader
        heading={{
          title: "Leads",
          subTitle: "Qualified leads breakdown",
          linkTo: "/",
          button: "Add New",
        }}
      >
        <Table
          headers={SampleHeaders}
          data={leadsData}
          linkTo="/sales/estimate"
        />
      </CardWButtonHeader>
    </div>
  );
};

export default LeadsView;
