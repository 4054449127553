import React, { useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";

// HOOKS
import useEstimate from "../../../hooks/useEstimate";

const TraysTable = ({ props }) => {
  const { estimateState } = useEstimate();
  const [tableForm, setTableForm] = useState([]);
  const { register, reset } = useFormContext();
  const { pertains, index, defaults } = estimateState.formStructure.trays;
  const TableHeaders = props.headers.map((option, i) => {
    return (
      <th
        key={i}
        className="p-3 text-left text-xs font-medium  uppercase text-gray-800  hidden lg:table-cell"
      >
        {option}
      </th>
    );
  });
  useEffect(() => {
    reset({});
  }, []);

  useEffect(() => {
    async function setTable() {
      const { container, product, entries } = pertains[index];
      const TableEntries = container.map((i, value) => {
        if (i && entries[i.split("-")[0]]) {
          let belongArr = i.split("-");
          let belongContainer = `${belongArr[1]} ${belongArr[2]} ${belongArr[3]}`;
          let set = `${entries[belongArr[0]].set} ${belongContainer}`;
          let ind = `${entries[belongArr[0]].individual} ${belongContainer}`;
          return {
            setup: belongArr[0],
            container: belongContainer,
            product: product,
            set,
            ind,
          };
        }
      });
      TableEntries[0] && setTableForm(TableEntries);
    }
    if (
      pertains[index] &&
      pertains[index].container &&
      Array.isArray(pertains[index].container) &&
      pertains[index].container
    )
      setTable();
  }, [pertains]);

  return (
    <div className={`px-4 mt-2 h-${props.height} `}>
      <div className="h-full overflow-auto object-contain ">
        <table className="h-full border rounded-lg w-full table-auto">
          <thead className="bg-gray-100 border border-gray-300 rounded-lg">
            {TableHeaders}
          </thead>
          <tbody>
            {tableForm.length !== 0 &&
              tableForm.map((i, value) => {
                return (
                  <tr
                    key={value}
                    className={`${
                      value % 2 === 0 ? "bg-white" : "bg-gray-50"
                    } flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0 w-full`}
                  >
                    <td className="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                      <input
                        disabled={true}
                        className="self-end shadow-sm sm:text-sm p-1 text-left border border-gray-300 rounded-md"
                        value={i.setup}
                      />
                    </td>
                    <td className="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                      <input
                        disabled={true}
                        className="self-end shadow-sm sm:text-sm p-1 text-left border border-gray-300 rounded-md"
                        value={i.container}
                      />
                    </td>
                    <td className="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                      <input
                        disabled={true}
                        className="self-end shadow-sm sm:text-sm p-1 border border-gray-300 rounded-md"
                        value={i.product}
                      />
                    </td>
                    <td>
                      <div className="flex flex-row ">
                        <input
                          type="number"
                          min="0"
                          className="shadow-sm sm:text-sm p-1 pr-0 border border-gray-300 rounded-l-md "
                          id={props.name}
                          {...register(i.set.replace(".", ","))}
                          defaultValue={
                            defaults[index] && defaults[index][i.set]
                              ? defaults[index][i.set]
                              : defaults[index] &&
                                defaults[index][i.set.replace(".", ",")]
                              ? defaults[index][i.set.replace(".", ",")]
                              : 1
                          }
                          placeholder="0 QTY"
                        />
                        <span className="flex items-center px-3  pl-1 rounded-r-md border border-l-0 border-gray-400 bg-gray-50 text-gray-500 sm:text-xs">
                          3 Trays /QTY
                        </span>
                      </div>
                    </td>
                    <td>
                      <div className="flex flex-row ">
                        <input
                          type="number"
                          className="shadow-sm sm:text-sm p-1 pr-0 border border-gray-300 rounded-l-md "
                          id={props.name}
                          min={0}
                          {...register(i.ind.replace(".", ","))}
                          defaultValue={
                            defaults[index] && defaults[index][i.ind]
                              ? defaults[index][i.ind]
                              : defaults[index] &&
                                defaults[index][i.ind.replace(".", ",")]
                              ? defaults[index][i.ind.replace(".", ",")]
                              : 0
                          }
                          placeholder="0 QTY"
                        />
                        <span className="flex items-center px-3  pl-1 rounded-r-md border border-l-0 border-gray-400 bg-gray-50 text-gray-500 sm:text-xs">
                          1 Tray /QTY
                        </span>
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TraysTable;
