import React from "react";

// VIEWS
import DashboardView from "../../views/Dashboard";
import SalesView from "../../views/Sales";
import StagesView from "../../views/Sales/stages";
import EstimatesView from "../../views/Sales/estimate";
import Forecast from "../../views/Sales/forecast";
import SalesOrderView from "../../views/Sales/orders";
import SalesLeadsView from "../../views/Sales/leads";
import SalesOrderUniqueView from "../../views/Sales/orders/unique";
import SalesOrderUniqueDetailsView from "../../views/Sales/orders/unique/detailView";
import OrderEstimateView from "../../views/Sales/orders/unique/estimates";
import OrderEstimateListView from "../../views/Sales/orders/unique/estimates/estimateList";
import OrderEstimateUniqueView from "../../views/Sales/orders/unique/estimates/unique";
import SalesOrderList from "../../views/Sales/orders/orderList";
import ClientsView from "../../views/Clients/index";
import DirectoryView from "../../views/Clients/directory";
import NPSView from "../../views/Support/nps";
import SupportView from "../../views/Support";
import TicketsView from "../../views/Support/tickets";
import UniqueTicket from "../../views/Support/tickets/uniqueTicket";
import AllTickets from "../../views/Support/tickets/allSupportTickets";
import SettingsView from "../../views/Profile/settings";

const DynamicRoutesList = {
  Dashboard: <DashboardView />,
  Clients: <ClientsView />,
  Sales: <SalesView />,
  Support: <SupportView />,
  Stages: <SalesLeadsView />,
  Estimate: <EstimatesView />,
  SalesForecast: <Forecast />,
  SalesOrder: <SalesOrderView />,
  SalesLeads: <SalesLeadsView />,
  UniqueOrder: <SalesOrderUniqueView />,
  OrderList: <SalesOrderList />,
  Directory: <DirectoryView />,
  NPS: <NPSView />,
  SupportTicket: <TicketsView />,
  UniqueSupportTicket: <UniqueTicket />,
  AllSupportTickets: <AllTickets />,
  OrderEstimate: <OrderEstimateView />,
  UniqueEstimate: <OrderEstimateUniqueView />,
  OrderDetails: <SalesOrderUniqueDetailsView />,
  OrderEstimateDetails: <OrderEstimateListView />,
  Settings: <SettingsView />,
};

export default DynamicRoutesList;
