import React from "react";
import { Outlet } from "react-router-dom";
import { Helmet } from "react-helmet";

// COMPONENTS
import GeneralHeader from "../../components/Header/pageNav";

const SalesView = () => {
  const subMenus = [
    {
      title: "Leads",
      link: "leads",
    },
    {
      title: "Orders",
      link: "orders",
    },
    {
      title: "Forecast",
      link: "forecast",
    },
  ];
  return (
    <main
      className="flex-1 relative z-0 overflow-y-auto focus:outline-none"
      tabIndex="0"
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Sales | ATG Staff</title>
      </Helmet>
      <GeneralHeader pageTitle={"Sales"} subMenus={subMenus} />
      <Outlet />
    </main>
  );
};

export default SalesView;
