import React, { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/react/solid";

// HOOKS
import useEstimate from "../../hooks/useEstimate";

//API
import axios from "axios";

function MasterOverLay({ allProducts, prop, setOverlayState }) {
  return (
    <div className="h-full bg-white p-8 overflow-y-auto">
      <div className="pb-16 space-y-6">
        <div className="mb-4 flex items-end w-full align-end justify-start text-left">
          <div>
            <h2 className="text-lg self-end font-medium text-gray-900">
              <span className="sr-only">Details for </span>
              {prop.label}
            </h2>
          </div>
        </div>
        <div>
          <div className="mt-2 flex items-center justify-between">
            <p className="text-sm text-gray-500 italic">{prop.subtitle}</p>
          </div>
        </div>
        <div>
          <h3 className="font-medium text-gray-900">Variants</h3>
          <div className="mt-2 flex items-center justify-between">
            <div className="bg-white shadow overflow-hidden sm:rounded-md w-full ">
              <ul className="divide-y divide-gray-200 w-full">
                {allProducts.length !== 0 &&
                  allProducts.map((variant) => (
                    <li
                      key={variant.id}
                      onClick={() =>
                        setOverlayState({
                          isIndividual: true,
                          individualData: variant,
                        })
                      }
                      className="w-full"
                    >
                      <p className="block hover:bg-gray-50">
                        <div className="px-4 py-4 sm:px-6">
                          <div className="flex items-center justify-between">
                            <p className="text-sm font-medium text-indigo-600 truncate">
                              {variant.title}
                            </p>
                            <div className="ml-5 flex-shrink-0">
                              <ChevronRightIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </div>
                          </div>
                          <div className="mt-2 sm:flex sm:justify-start">
                            <div className="m-0 flex-shrink-0 flex">
                              <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-indigo-50 text-blue-800">
                                SKU: {variant.sku}
                              </p>
                            </div>
                            <div className="ml-2 flex-shrink-0 flex">
                              <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-50 text-green-800">
                                Price: ${" "}
                                {variant.price
                                  .toFixed(2)
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </p>
                            </div>
                          </div>
                        </div>
                      </p>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function VariantOverLay({ allProducts, overlayState, setOverlayState }) {
  const { title, BOM, price } = overlayState.individualData;
  return (
    <div className="h-full bg-white p-8 overflow-y-auto">
      <div className=" flex flex-row justify-between items-center mb-4 ">
        {allProducts.length !== 0 && (
          <div
            onClick={() =>
              setOverlayState({ isIndividual: false, individualData: {} })
            }
            className="flex-shrink-0 flex items-end"
          >
            <ChevronLeftIcon
              className="h-8 w-8 text-gray-400 hover:text-gray-900"
              aria-hidden="true"
            />
          </div>
        )}
        <div className="flex items-start justify-between text-right">
          <div>
            <h2 className="text-lg font-medium text-gray-900 flex flex-row justify start">
              {title}
            </h2>
          </div>
        </div>
      </div>
      <div>
        <div className="my-2 flex items-center justify-between">
          <h3 className="font-bold text-gray-900">Product Cost</h3>
          <p className="text-lg font-semibold text-green-500">
            ${price.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </p>
        </div>
      </div>
      <div className="pb-16 space-y-6">
        {BOM.length !== 0 && (
          <div>
            <h3 className="font-medium text-gray-900">Bill of Materials</h3>
            <div className="flex flex-col mt-2">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Name
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Quantity
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {BOM.map((material) => (
                          <tr key={material.name}>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                              {material.name}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                              {material.qty}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div>
          <h3 className="font-medium text-gray-900">Information</h3>
          <dl className="mt-2 border-t border-b border-gray-200 divide-y divide-gray-200">
            <div className="py-3 flex justify-between text-sm font-medium">
              <dt className="text-gray-500">Available Stock</dt>
              <dd className="text-red-500">{overlayState.props}</dd>
            </div>
            <div className="py-3 flex justify-between text-sm font-medium">
              <dt className="text-gray-500">Lead Time</dt>
              <dd className="text-gray-900">-</dd>
            </div>
            <div className="py-3 flex justify-between text-sm font-medium">
              <dt className="text-gray-500">Last Re-Stocked</dt>
              <dd className="text-gray-900">-</dd>
            </div>
            <div className="py-3 flex justify-between text-sm font-medium">
              <dt className="text-gray-500">Notes</dt>
              <dd className="text-gray-900">-</dd>
            </div>
            <div className="py-3 flex justify-between text-sm font-medium">
              <dt className="text-gray-500">Documentation</dt>
              <dd>
                <button
                  type="button"
                  className="ml-6 bg-white rounded-md text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  View<span className="sr-only"></span>
                </button>
              </dd>
            </div>
          </dl>
        </div>
        <div>
          <h3 className="font-medium text-gray-900">Stock Requested By</h3>
          <ul className="mt-2 border-t border-b border-gray-200 divide-y divide-gray-200">
            <li className="py-3 flex justify-between items-center">
              <div className="flex items-center">
                <img
                  src="https://images.unsplash.com/photo-1502685104226-ee32379fefbe?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3&w=1024&h=1024&q=80"
                  alt=""
                  className="w-8 h-8 rounded-full"
                />
                <p className="ml-4 text-sm font-medium text-gray-900">
                  Aimee Douglas
                </p>
              </div>
              <button
                type="button"
                className="ml-6 bg-white rounded-md text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Contact<span className="sr-only"> Aimee Douglas</span>
              </button>
            </li>
          </ul>
        </div>
        <div className="flex">
          <button
            type="button"
            className="flex-1 bg-indigo-600 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Request Stock Order
          </button>
        </div>
      </div>
    </div>
  );
}

export default function OverLayRightFormWithImage() {
  const { handleProductOverlay, estimateState } = useEstimate();
  const { productOverlayDetails, productOverLay } = estimateState;
  const [open, setOpen] = useState(false);
  const [products, setProducts] = useState([]);
  const [overlayState, setOverlayState] = useState({
    isIndividual: false,
    individualData: {},
  });
  useEffect(() => {
    setOpen(estimateState.productOverLay);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productOverLay]);

  useEffect(() => {
    if (!open) {
      handleProductOverlay(false, {});
      setOverlayState({ isIndividual: false, individualData: {} });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    async function setData() {
      const { options } = productOverlayDetails;
      let products = [];
      if (options) {
        for (const entry of options) {
          const productDetails = entry.value.split("-");
          const code = productDetails[0];
          try {
            const response = await axios({
              method: "post",
              url: `/inventory/products/${code}`,
              config: { headers: { "Content-Type": "application/json" } },
              headers: {
                "X-CSRFToken":
                  "OROsBbAGbBdam2JE6BQ5oxPi2eIVn6syDJ8pOMJbj55okrxJytm8EjKzsOPhNfdk",
              },
              data: {
                currency: estimateState.currency,
              },
            });
            products.push(response.data);
          } catch (error) {
            console.error("ERROR IS HERE", error.response);
          }
        }
        setProducts(products);
      } else {
        setProducts([]);
        for (const entry of productOverlayDetails) {
          const { name, options, select } = entry;
          if (name && !select) {
            const productDetails = name.split("-");
            const code = productDetails[0];
            try {
              const response = await axios({
                method: "post",
                url: `/inventory/products/${code}`,
                config: { headers: { "Content-Type": "application/json" } },
                headers: {
                  "X-CSRFToken":
                    "OROsBbAGbBdam2JE6BQ5oxPi2eIVn6syDJ8pOMJbj55okrxJytm8EjKzsOPhNfdk",
                },
                data: {
                  currency: estimateState.currency,
                },
              });
              setOverlayState({
                isIndividual: true,
                individualData: response.data,
              });
            } catch (error) {
              console.error("ERROR IS HERE", error.response);
            }
          } else if (options && options.length !== 0) {
            for (const entry of options) {
              const productDetails = entry.value.split("-");
              const code = productDetails[0];
              try {
                const response = await axios({
                  method: "post",
                  url: `/inventory/products/${code}`,
                  config: { headers: { "Content-Type": "application/json" } },
                  headers: {
                    "X-CSRFToken":
                      "OROsBbAGbBdam2JE6BQ5oxPi2eIVn6syDJ8pOMJbj55okrxJytm8EjKzsOPhNfdk",
                  },
                  data: {
                    currency: estimateState.currency,
                  },
                });
                products.push(response.data);
              } catch (error) {
                console.error("ERROR IS HERE", error.response);
              }
            }
            setProducts(products);
          }
        }
      }
    }
    if (productOverLay && Object.keys(productOverlayDetails).length !== 0)
      setData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productOverLay]);

  const toggleShow = (e) => {
    e.preventDefault();
    handleProductOverlay(false, {});
    setOverlayState({ isIndividual: false, individualData: {} });
    setOpen(false);
  };
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 overflow-hidden"
        open={open}
        onClose={setOpen}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="relative w-full">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-500"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-500"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 left-0 -ml-8 pt-4 pr-2 flex sm:-ml-10 sm:pr-4">
                    <button
                      className="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                      onClick={toggleShow}
                    >
                      <span className="sr-only">Close panel</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                {!overlayState.isIndividual ? (
                  <MasterOverLay
                    allProducts={products}
                    prop={productOverlayDetails}
                    setOverlayState={setOverlayState}
                  />
                ) : (
                  <VariantOverLay
                    allProducts={products}
                    overlayState={overlayState}
                    setOverlayState={setOverlayState}
                  />
                )}
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
