import { useState, useContext } from "react";

//COOKIES
import { useCookies } from "react-cookie";

//API
import axios from "axios";

// CONTEXT_API
import { UserContext } from "../context/User";

const useConnectAuth = () => {
  const [routesData, setRoutesData] = useState(
    JSON.parse(localStorage.getItem("routes"))
  );
  const [cookies, setCookie, removeCookie] = useCookies(["user"]);
  const [state, dispatch] = useContext(UserContext);

  const handleSignIn = async (email, password) => {
    if (!cookies.user) {
      const login_response = await axios({
        method: "post",
        url: "/auth/login/staff/",
        config: { headers: { "Content-Type": "application/json" } },
        headers: {
          "X-CSRFToken":
            "OROsBbAGbBdam2JE6BQ5oxPi2eIVn6syDJ8pOMJbj55okrxJytm8EjKzsOPhNfdk",
        },
        data: {
          email: email,
          password: password,
        },
      });
      const tokens = login_response.data.tokens;
      const QBAccess = login_response.data.valid_qbo_access;
      const access = tokens.split(`access': '`).pop().split(`'}`)[0];
      const refresh = tokens.split(`{'refresh': '`).pop().split(`',`)[0];
      setCookie("QBA", QBAccess, {
        path: "/",
        secure: true,
        sameSite: true,
      });
      setCookie("rome", refresh, {
        path: "/",
        secure: true,
        sameSite: true,
      });
      setCookie("amsterdam", access, {
        path: "/",
        secure: true,
        sameSite: true,
      });
      const routes_response = await axios({
        method: "get",
        url: "/routes/",
        config: { headers: { "Content-Type": "application/json" } },
        headers: {
          "X-CSRFToken":
            "OKzo3NRdApXaacgg8Vos6cz9U2UsUjcG4zrI00sm5xr2oaF4dngY9sl4bsuzgJlrQ",
          Authorization: `Bearer ${access}`,
        },
      });
      let setDate = new Date();
      setDate.setHours(setDate.getHours() + 1);
      setRoutesData(routes_response.data.results);
      localStorage.setItem(
        "routes",
        JSON.stringify(routes_response.data.results)
      );
      setCookie("user", login_response.data, { path: "/", expires: setDate });
    }
  };

  const handleSignOut = async () => {
    try {
      removeCookie("user", { path: "/" });
      removeCookie("rome", { path: "/" });
      removeCookie("QBA", { path: "/" });
      removeCookie("amsterdam", { path: "/" });
      let login = localStorage.getItem("login");
      localStorage.clear();
      localStorage.setItem("login", login);
      dispatch({ type: "RESET_USER_DATA" });
    } catch (error) {
      console.error("user sign out error", error);
    }
  };
  return {
    state,
    handleSignIn,
    handleSignOut,
    isUserLoggedIn: cookies.user,
    routes: routesData,
  };
};

export default useConnectAuth;
