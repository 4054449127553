import React from "react";
const OrderEstimateView = () => {
  return (
    <div>
      <h1>ESTIMATES</h1>
    </div>
  );
};

export default OrderEstimateView;
