import React, { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationIcon, CheckIcon } from "@heroicons/react/outline";
import { useForm } from "react-hook-form";
import axios from "axios";

//COOKIES
import { useCookies } from "react-cookie";

const SimpleEstimateSelector = ({
  data,
  headers,
  linkTitle,
  orderID,
  submitOrder,
  selected,
}) => {
  const [open, setOpen] = useState(false);
  const [cookies] = useCookies(["user"]);
  const { handleSubmit, register, setValue, formState } = useForm();
  const [estimateSelected, setEstimateSelected] = useState(null);
  const cancelButtonRef = useRef(null);
  const { errors } = formState;

  async function handleEstimateDeletion(entry) {
    setOpen(true);
    try {
      const response = await axios({
        method: "delete",
        url: `/sales/estimate/${estimateSelected.estimate_id}`,
        config: { headers: { "Content-Type": "application/json" } },
        headers: {
          "X-CSRFToken":
            "OROsBbAGbBdam2JE6BQ5oxPi2eIVn6syDJ8pOMJbj55okrxJytm8EjKzsOPhNfdk",
          Authorization: `Bearer ${cookies.amsterdam}`,
        },
      });
      window.location.reload();
    } catch (error) {
      console.error("ERROR IS HERE", error.response);
    }
  }
  if (data.length === 1) setValue("estimate_predicted", data[0].estimate_id);
  return (
    <div className="flex flex-col">
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          open={open}
          onClose={setOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationIcon
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-gray-900"
                      >
                        Delete Estimate
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Are you sure you want to Delete the Estimate?This
                          action cannot be undone.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={handleEstimateDeletion}
                  >
                    Delete
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          {errors.estimate_predicted && (
            <p className="text-red-600 mb-1 text-right animate-pulse ">
              A predicted estimate selection is required to process Order!
            </p>
          )}
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <form id="estimate-Selector" onSubmit={handleSubmit(submitOrder)}>
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    {headers.map((entry, index) => {
                      return (
                        <th
                          key={index}
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          {entry}
                        </th>
                      );
                    })}
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {selected ? "View" : "Edit"}
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {selected ? "Selected" : "Predicted"}
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {data &&
                    data.length !== 0 &&
                    data.map((entry, index) => {
                      const entryKeys = Object.keys(entry);
                      return (
                        <tr key={index}>
                          {entryKeys.map((dataKey, index) => {
                            return (
                              <td
                                key={index}
                                className="px-6 py-4 whitespace-nowrap text-sm font-medium "
                              >
                                {entry[dataKey]}
                              </td>
                            );
                          })}
                          <td>
                            <a
                              href={`${orderID}/estimates/${entry.estimate_id}`}
                              className={`text-blue-600 hover:text-blue-900 text-right ${
                                selected && "ml-4"
                              }`}
                            >
                              {linkTitle}{" "}
                            </a>
                            {!selected && (
                              <button
                                type="button"
                                onClick={() => {
                                  setOpen(true);
                                  setEstimateSelected(entry);
                                }}
                                className="text-red-600 hover:text-red-900 text-right"
                              >
                                <span className="text-gray-800 "> | </span>
                                Delete
                              </button>
                            )}
                          </td>
                          {selected ? (
                            <td className="flex justify-start px-10">
                              {selected === entry.estimate_id && (
                                <CheckIcon
                                  className="h-6 w-6 text-green-600"
                                  aria-hidden="true"
                                />
                              )}
                            </td>
                          ) : (
                            <td className="flex justify-start px-12 py-4">
                              <input
                                type="radio"
                                id="predictEstimate"
                                value={entry.estimate_id}
                                {...register("estimate_predicted", {
                                  required: true,
                                })}
                              />
                            </td>
                          )}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SimpleEstimateSelector;
