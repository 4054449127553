const machineSelection = {
  title: "Base Unit Selection",
  description:
    "Select ONE of the following options. Only one base be selected per estimate division. Manual and Automatic can be paired to expedite production start.",
  systemType: "Filling machine",
  category: "Machine",
  form: [
    {
      type: "SimpleRadio",
      name: "auto_filling_machine",
      label: "Automatic Units",
      lineItem: 1,
      subtitle: " > 1,000 Units per Day",
      options: [
        {
          value: "500204-RoboCAP 200 Series",
          label: "RoboCAP 200 Series",
          code: "500204",
        },
        {
          value: "500205-RoboCAP 300 Series",
          label: "RoboCAP 300 Series",
          code: "500205",
        },
        {
          value: "500206-RoboCAP 300Z Series",
          label: "RoboCAP 300Z Series",
          code: "500206",
        },
        {
          value: "500209-RoboCAP 500 Series",
          label: "RoboCAP 500 Series",
          code: "500209",
        },
        {
          value: "500210-RoboCAP 500Z Series",
          label: "RoboCAP 500Z Series",
          code: "500210",
        },
      ],
    },
    {
      type: "SimpleRadio",
      name: "manual_filling_machine",
      label: "Manual Units",
      lineItem: 2,
      subtitle: " < 1,000 Units per Day",
      options: [
        {
          value: "500082-Manual Unit 1 Nozzle (ML1)",
          label: "Manual Unit 1 Nozzle (ML1)",
          code: "500082",
        },
        {
          value: "500127-Manual Unit 2 Nozzle (ML2)",
          label: "Manual Unit 2 Nozzle (ML2)",
          code: "500127",
        },
      ],
    },
  ],
};

const volumetricSelection = {
  title: "Volumetric Filling Options",
  description: "When highly accurate dispense based on volume is required",
  systemType: "Volumetric Setup",
  category: "setup",
  form: [
    {
      type: "SimpleDropdown",
      name: "volumetric_nozzles",
      label: "Number of Dispense Nozzle",
      validation: { dependant: ["volumetric_tip"] },
      lineItem: 1,
      options: [
        {
          value: "500078-Standard 1 Nozzle",
          label: "1 Nozzle",
        },
        {
          value: "500079-Standard 2 Nozzle",
          label: "2 Nozzles",
        },
        {
          value: "500080-Standard 3 Nozzle",
          label: "3 Nozzles",
        },
        {
          value: "500081-Standard 4 Nozzle",
          label: "4 Nozzles",
        },
      ],
    },
    {
      type: "SimpleDropdown",
      name: "volumetric_tip",
      label: "Custom Needle Tips",
      validation: { dependant: ["volumetric_nozzles"] },
      lineItem: 2,
      options: [
        {
          value: "200188-18Ga Cannula",
          label: "18Ga Cannula",
        },
        {
          value: "200132-12Ga Cannula",
          label: "12Ga Cannula",
        },
        {
          value: "200131-14Ga Cannula",
          label: "14Ga Cannula",
        },
        {
          value: "200133-16Ga Cannula",
          label: "16Ga Cannula",
        },
        {
          value: "200134-20Ga Cannula",
          label: "20Ga Cannula",
        },
      ],
    },
    {
      type: "TextWTag",
      isChange: true,
      label: "Change Over Kit",
      subtitle: "Nozzle contact points swap",
      isQuantitative: true,
      isAside: true,
      unit: "Qty",
      tag: "volumetric_nozzles",
      options: {
        "500078-Standard 1 Nozzle": {
          value: "500066-volumetric 1 nozzle ChangeOver",
          label: "1",
        },
        "500079-Standard 2 Nozzle": {
          value: "500067-volumetric 2 nozzle ChangeOver",
          label: "2",
        },
        "500080-Standard 3 Nozzle": {
          value: "500068-volumetric 3 nozzle ChangeOver",
          label: "3",
        },
        "500081-Standard 4 Nozzle": {
          value: "500069-volumetric 4 nozzle ChangeOver",
          label: "4",
        },
      },
    },
    {
      type: "ModularTable",
      name: "spare",
      height: "72",
      title: "Spare Parts",
      isAside: true,
      isTable: true,
      TableInputs: [
        {
          name: "200135-5ml glass syringe",
          isQuantitative: true,
        },
        {
          name: "200303-25ml glass syringe",
          isQuantitative: true,
        },
        {
          name: "200173-#15 springs",
          isQuantitative: true,
        },
        {
          name: "200174-stainless steel balls",
          isQuantitative: true,
        },
        {
          name: "200172-teflon seal",
          isQuantitative: true,
        },
        {
          name: "200188-18Ga Cannula",
          isQuantitative: true,
        },
        {
          name: "200132-12Ga Cannula",
          isQuantitative: true,
        },
        {
          name: "200131-14Ga Cannula",
          isQuantitative: true,
        },
        {
          name: "200133-16Ga Cannula",
          isQuantitative: true,
        },
        {
          name: "200134-20Ga Cannula",
          isQuantitative: true,
        },
        {
          name: "200110-PTFEN Product Tubing",
          isQuantitative: true,
        },
        {
          name: "500232-VF Dual Valve Assembly",
          isQuantitative: true,
        },
      ],
      subtitle: "Purpose of Capsule Counter",
      headers: ["Item", "Quantity"],
      options: [
        [
          {
            label: "5ml Glass Syringe [200135]",
          },
          {
            input: "number",
            name: "200135-5ml glass syringe",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
        ],
        [
          {
            label: "25ml Glass Syringe [200303]",
          },
          {
            input: "number",
            name: "200303-25ml glass syringe",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
        ],
        [
          {
            label: "#15 Springs [200173]",
          },
          {
            input: "number",
            name: "200173-#15 springs",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold font-bold text-center text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
        ],
        [
          {
            label: "Stainless Steel Balls [200174]",
          },
          {
            input: "number",
            name: "200174-stainless steel balls",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
        ],
        [
          {
            label: "Dual Check Value Teflon Seal [200172]",
          },
          {
            input: "number",
            name: "200172-teflon seal",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
        ],
        [
          {
            label: "18Ga Cannula [200188]",
          },
          {
            input: "number",
            name: "200188-18Ga Cannula",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
        ],
        [
          {
            label: "12Ga Cannula [200132]",
          },
          {
            input: "number",
            name: "200132-12Ga Cannula",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
        ],
        [
          {
            label: "14Ga Cannula [200131]",
          },
          {
            input: "number",
            name: "200131-14Ga Cannula",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
        ],
        [
          {
            label: "16Ga Cannula [200133]",
          },
          {
            input: "number",
            name: "200133-16Ga Cannula",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
        ],
        [
          {
            label: "20Ga Cannula [200134]",
          },
          {
            input: "number",
            name: "200134-20Ga Cannula",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
        ],
        [
          {
            label: "PTFEN Product Tubing[200110]",
          },
          {
            input: "number",
            name: "200110-PTFEN Product Tubing",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
        ],
        [
          {
            label: "VF Dual Valve Assembly [500232]",
          },
          {
            input: "number",
            name: "500232-VF Dual Valve Assembly",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
        ],
      ],
    },
  ],
};

const volumetricDeluxeSelection = {
  title: "Volumetric Deluxe Filling Options",
  description:
    "When highly accurate dispense based on volume is required greater than 2ml",
  systemType: "Volumetric Setup",
  category: "setup",
  form: [
    {
      type: "SimpleDropdown",
      name: "volumetric_deluxe_nozzles",
      label: "Number of Dispense Nozzle",
      validation: { dependant: ["volumetric_deluxe_tip"] },
      lineItem: 1,
      options: [
        {
          value: "500236-Deluxe 1 Nozzle",
          label: "1 Nozzle",
        },
        {
          value: "500237-Deluxe 2 Nozzle",
          label: "2 Nozzles",
        },
        {
          value: "500238-Deluxe 3 Nozzle",
          label: "3 Nozzles",
        },
        {
          value: "500239-Deluxe 4 Nozzle",
          label: "4 Nozzles",
        },
      ],
    },
    {
      type: "SimpleDropdown",
      name: "volumetric_deluxe_tip",
      label: "Custom Needle Tips",
      validation: { dependant: ["volumetric_deluxe_nozzles"] },
      lineItem: 2,
      options: [
        {
          value: "200188-18Ga Cannula",
          label: "18Ga Cannula",
        },
        {
          value: "200132-12Ga Cannula",
          label: "12Ga Cannula",
        },
        {
          value: "200131-14Ga Cannula",
          label: "14Ga Cannula",
        },
        {
          value: "200133-16Ga Cannula",
          label: "16Ga Cannula",
        },
        {
          value: "200134-20Ga Cannula",
          label: "20Ga Cannula",
        },
      ],
    },
    {
      type: "TextWTag",
      isChange: true,
      label: "Change Over Kit",
      subtitle: "Nozzle contact points swap",
      isQuantitative: true,
      isAside: true,
      unit: "Qty",
      tag: "volumetric_deluxe_nozzles",
      options: {
        "500236-Deluxe 1 Nozzle": {
          value: "500066-volumetric 1 nozzle ChangeOver",
          label: "1",
        },
        "500237-Deluxe 2 Nozzle": {
          value: "500067-volumetric 2 nozzle ChangeOver",
          label: "2",
        },
        "500238-Deluxe 3 Nozzle": {
          value: "500068-volumetric 3 nozzle ChangeOver",
          label: "3",
        },
        "500239-Deluxe 4 Nozzle": {
          value: "500069-volumetric 4 nozzle ChangeOver",
          label: "4",
        },
      },
    },
    {
      type: "ModularTable",
      name: "spare",
      height: "72",
      title: "Spare Parts",
      isAside: true,
      isTable: true,
      TableInputs: [
        {
          name: "200135-5ml glass syringe",
          isQuantitative: true,
        },
        {
          name: "200303-25ml glass syringe",
          isQuantitative: true,
        },
        {
          name: "200173-#15 springs",
          isQuantitative: true,
        },
        {
          name: "200174-stainless steel balls",
          isQuantitative: true,
        },
        {
          name: "200172-teflon seal",
          isQuantitative: true,
        },
        {
          name: "200188-18Ga Cannula",
          isQuantitative: true,
        },
        {
          name: "200132-12Ga Cannula",
          isQuantitative: true,
        },
        {
          name: "200131-14Ga Cannula",
          isQuantitative: true,
        },
        {
          name: "200133-16Ga Cannula",
          isQuantitative: true,
        },
        {
          name: "200134-20Ga Cannula",
          isQuantitative: true,
        },
        {
          name: "200110-PTFEN Product Tubing",
          isQuantitative: true,
        },
        {
          name: "500232-VF Dual Valve Assembly",
          isQuantitative: true,
        },
      ],
      subtitle: "Purpose of Capsule Counter",
      headers: ["Item", "Quantity"],
      options: [
        [
          {
            label: "5ml Glass Syringe [200135]",
          },
          {
            input: "number",
            name: "200135-5ml glass syringe",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
        ],
        [
          {
            label: "25ml Glass Syringe [200303]",
          },
          {
            input: "number",
            name: "200303-25ml glass syringe",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
        ],
        [
          {
            label: "#15 Springs [200173]",
          },
          {
            input: "number",
            name: "200173-#15 springs",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
        ],
        [
          {
            label: "Stainless Steel Balls [200174]",
          },
          {
            input: "number",
            name: "200174-stainless steel balls",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
        ],
        [
          {
            label: "Dual Check Value Teflon Seal [200172]",
          },
          {
            input: "number",
            name: "200172-teflon seal",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
        ],
        [
          {
            label: "18Ga Cannula [200188]",
          },
          {
            input: "number",
            name: "200188-18Ga Cannula",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
        ],
        [
          {
            label: "12Ga Cannula [200132]",
          },
          {
            input: "number",
            name: "200132-12Ga Cannula",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
        ],
        [
          {
            label: "14Ga Cannula [200131]",
          },
          {
            input: "number",
            name: "200131-14Ga Cannula",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
        ],
        [
          {
            label: "16Ga Cannula [200133]",
          },
          {
            input: "number",
            name: "200133-16Ga Cannula",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
        ],
        [
          {
            label: "20Ga Cannula [200134]",
          },
          {
            input: "number",
            name: "200134-20Ga Cannula",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
        ],
        [
          {
            label: "PTFEN Product Tubing[200110]",
          },
          {
            input: "number",
            name: "200110-PTFEN Product Tubing",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
        ],
        [
          {
            label: "VF Dual Valve Assembly [500232]",
          },
          {
            input: "number",
            name: "500232-VF Dual Valve Assembly",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
        ],
      ],
    },
  ],
};

const peristalticSelection = {
  title: "Peristaltic Filling Options",
  description:
    "When high output with fluid that does not require heating to flow is required.",
  systemType: "Peristaltic Setup",
  category: "setup",
  form: [
    {
      type: "SimpleDropdown",
      name: "low_vis_nozzle",
      label: "Low Viscosity Product",
      subtitle: 'Uses small 15" tubing',
      options: [
        {
          value: "500212-AF600 YZ1515x 1 Pump",
          label: "AF600 YZ1515x 1 Pump",
          code: "500212",
        },
        {
          value: "500213-AF600 YZ1515x 2 Pump",
          label: "AF600 YZ1515x 2 Pump",
          code: "500213",
        },
        {
          value: "500214-AF600 YZ1515x 3 Pump",
          label: "AF600 YZ1515x 3 Pump",
          code: "500214",
        },
        {
          value: "500215-AF600 YZ1515x 4 Pump",
          label: "AF600 YZ1515x 4 Pump",
          code: "500215",
        },
      ],
    },
    {
      type: "SimpleDropdown",
      name: "avg_vis_nozzle",
      label: "Average Viscosity Product",
      subtitle: 'Uses 24" tubing',
      options: [
        {
          value: "500141-AF600 YZ2515 1 Pump",
          label: "AF600 YZ2515 1 Pump",
        },
        {
          value: "500142-AF600 YZ2515 2 Pump",
          label: "AF600 YZ2515 2 Pump",
        },
        {
          value: "500143-AF600 YZ2515 3 Pump",
          label: "AF600 YZ2515 3 Pump",
        },
        {
          value: "500144-AF600 YZ2515 4 Pump",
          label: "AF600 YZ2515 4 Pump",
        },
      ],
    },
    {
      type: "SimpleDropdown",
      name: "high_vis_nozzle",
      label: "High Viscosity Product",
      subtitle: 'Uses 36" tubing',
      options: [
        {
          value: "500219-DZ25 3L 1 Pump",
          label: "DZ25-3L 1 Pump",
        },
        {
          value: "500220-DZ25 3L 2 Pump",
          label: "DZ25-3L 2 Pump",
        },
        {
          value: "500221-DZ25 3L 3 Pump",
          label: "DZ25-3L 3 Pump",
        },
        {
          value: "500222-DZ25 3L 4 Pump",
          label: "DZ25-3L 4 Pump",
        },
      ],
    },
    {
      type: "SimpleDropdown",
      name: "extr_vis_nozzle",
      label: "Extreme Viscosity Product",
      subtitle: 'Uses 24" or 36" tubing',
      options: [
        {
          value: "500184-AF600II 1 Pump",
          label: "AF600II 1 Pump",
        },
        {
          value: "500185-AF600II 2 Pump",
          label: "AF600II 2 Pump",
        },
        {
          value: "500186-AF600II 3 Pump",
          label: "AF600II 3 Pump",
        },
        {
          value: "500187-AF600II 4 Pump",
          label: "AF600II 4 Pump",
        },
      ],
    },
    {
      type: "ModularTable",
      name: "spare",
      title: "Additional Tubing",
      height: "72",
      label: "ATC-100 Capsule Counter",
      isAside: true,
      isTable: true,
      TableInputs: [
        {
          name: "200116-#15 Additional Silicone Tubing",
          isQuantitative: true,
        },
        {
          name: "15 Silicone Tubing Certified",
          isOption: true,
          optionFor: "#15 Additional Silicone Tubing",
        },
        {
          name: "200117-#24 Additional Silicone Tubing",
          isQuantitative: true,
        },
        {
          name: "24 Silicone Tubing Certified",
          isOption: true,
          optionFor: "#24 Additional Silicone Tubing",
        },
        {
          name: "200118-#36 Additional Silicone Tubing",
          isQuantitative: true,
        },
        {
          name: "36 Silicone Tubing Certified",
          isOption: true,
          optionFor: "#36 Additional Silicone Tubing",
        },
      ],
      subtitle: "Purpose of Capsule Counter",
      headers: ["Tube Type", "Quantity", "CSA Certified"],
      options: [
        [
          {
            label: "#15 Silicone Tubing (200116/200213)",
          },
          {
            input: "number",
            name: "200116-#15 Additional Silicone Tubing",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
          {
            input: "checkbox",
            inputStyle:
              "focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded",
            name: "15 Silicone Tubing Certified",
            value: "200213-#15 Additional Silicone Tubing Certified",
          },
        ],
        [
          {
            label: "#24 Silicone Tubing (200117/200212)",
          },
          {
            input: "number",
            name: "200117-#24 Additional Silicone Tubing",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
          {
            input: "checkbox",
            inputStyle:
              "focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded",
            name: "24 Silicone Tubing Certified",
            value: "200212-#24 Additional Silicone Tubing  Certified",
          },
        ],
        [
          {
            label: "#36 Silicone Tubing (200118/200211)",
          },
          {
            input: "number",
            name: "200118-#36 Additional Silicone Tubing",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
          {
            input: "checkbox",
            inputStyle:
              "focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded",
            name: "36 Silicone Tubing Certified",
            value: "200211-#36 Additional Silicone Tubing Certified",
          },
        ],
      ],
    },
    {
      type: "ModularTable",
      name: "spare",
      title: "Spare Parts",
      height: "52",
      isAside: true,
      isTable: true,
      TableInputs: [
        {
          name: "200140-#15 Food Grade Check Valve",
          isQuantitative: true,
        },
        {
          name: "200145-#15 SS Countersink",
          isQuantitative: true,
        },
        {
          name: "200143-#24 Food Grade Check Valve",
          isQuantitative: true,
        },
        {
          name: "200148-#24 SS Countersink",
          isQuantitative: true,
        },
        {
          name: "200144-#36 Food Grade Check Valve",
          isQuantitative: true,
        },
        {
          name: "200149-#36 SS Countersink",
          isQuantitative: true,
        },
      ],
      subtitle: "Purpose of Capsule Counter",
      headers: ["Item", "Quantity"],
      options: [
        [
          {
            label: "#15 Food Grade Check Valve [200140]",
          },
          {
            input: "number",
            name: "200140-#24 Food Grade Check Valve",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
        ],
        [
          {
            label: "#15 SS Countersink [200145]",
          },
          {
            input: "number",
            name: "200145-#24 SS Countersink",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
        ],
        [
          {
            label: "#24 Food Grade Check Valve [200143]",
          },
          {
            input: "number",
            name: "200143-#24 Food Grade Check Valve",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
        ],
        [
          {
            label: "#24 SS Countersink [200148]",
          },
          {
            input: "number",
            name: "200148-#24 SS Countersink",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
        ],
        [
          {
            label: "#36 Food Grade Check Valve [200144]",
          },
          {
            input: "number",
            name: "200144-#24 Food Grade Check Valve",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
        ],
        [
          {
            label: "#36 SS Countersink [200149]",
          },
          {
            input: "number",
            name: "200149-#24 SS Countersink",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
        ],
      ],
    },
  ],
};

const pneumaticSelection = {
  title: "Pneumatic Filling Options",
  description:
    "When high output with limited accuracy based on timed dispense is required",
  systemType: "Pneumatic Setup",
  category: "setup",
  form: [
    {
      type: "SimpleDropdown",
      name: "pneumatic_Nozzle",
      label: "Number of Dispense Nozzle",
      validation: { dependant: ["pneumatic_tip"] },
      options: [
        {
          value: "500137-Pneumatic 1 Nozzle",
          label: "1 Nozzle [500236]",
          code: "500236",
        },
        {
          value: "500138-Pneumatic 2 Nozzles",
          label: "2 Nozzles [500237]",
          code: "500237",
        },
        {
          value: "500139-Pneumatic 3 Nozzles",
          label: "3 Nozzles [500238]",
          code: "500238",
        },
        {
          value: "500140-Pneumatic 4 Nozzles",
          label: "4 Nozzles [500239]",
          code: "500239",
        },
      ],
    },
    {
      type: "SimpleRadio",
      name: "pneumatic_tip",
      label: "Custom Needle Tips",
      validation: { dependant: ["pneumatic_Nozzle"] },
      options: [
        {
          value: "400036-2mm Dispense Tip",
          label: "2mm Tip",
          name: "vf1",
        },
        {
          value: "400037-1mm Dispense Tip",
          label: "1mm Tip",
          name: "vf2",
        },
      ],
    },
    {
      type: "TextWTag",
      isChange: true,
      label: "Change Over Kit",
      subtitle: "Nozzle contact points swap",
      isQuantitative: true,
      isAside: true,
      unit: "Qty",
      tag: "pneumatic_Nozzle",
      options: {
        "500137-Pneumatic 1 Nozzle": {
          value: "500062-pneumatic 1 nozzle ChangeOver",
          label: "1",
        },
        "500138-Pneumatic 2 Nozzles": {
          value: "500063-pneumatic 2 nozzle ChangeOver",
          label: "2",
        },
        "500139-Pneumatic 3 Nozzles": {
          value: "500064-pneumatic 3 nozzle ChangeOver",
          label: "3",
        },
        "500140-Pneumatic 4 Nozzles": {
          value: "500065-pneumatic 4 nozzle ChangeOver",
          label: "4",
        },
      },
    },
    {
      type: "ModularTable",
      name: "spare",
      title: "Spare Parts",
      isAside: true,
      height: "72",
      label: "ATC-100 Capsule Counter",
      isTable: true,
      TableInputs: [
        {
          name: "400037-1mm Dispense Tip",
          isQuantitative: true,
        },
        {
          name: "400036-2mm Dispense Tip",
          isQuantitative: true,
        },
        {
          name: "200128-Cylinder Valve Washer",
          isQuantitative: true,
        },
        {
          name: "400013-Cylinder Valve Plunger Seat",
          isQuantitative: true,
        },
        {
          name: "200127-PTFE O-Rings",
          isQuantitative: true,
        },
        {
          name: "200110-PTFEN Product Tubing",
          isQuantitative: true,
        },
        {
          name: "400011-Cylinder Valve Plunger",
          isQuantitative: true,
        },
        {
          name: "200129-Cylinder Valve Retaining Nut",
          isQuantitative: true,
        },
        {
          name: "200122-Valve Plunger Lock Nut",
          isQuantitative: true,
        },
        {
          name: "200296-Cylinder Valve Housing",
          isQuantitative: true,
        },
        {
          name: "400012-Cylinder Valve Packing Gland",
          isQuantitative: true,
        },
      ],
      subtitle: "Purpose of Capsule Counter",
      headers: ["Item", "Quantity"],
      options: [
        [
          {
            label: "1mm Dispense Tip [400037]",
          },
          {
            input: "number",
            name: "400037-1mm Dispense Tip",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
        ],
        [
          {
            label: "2mm Dispense Tip [400036]",
          },
          {
            input: "number",
            name: "400036-2mm Dispense Tip",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
        ],
        [
          {
            label: "Cylinder Valve Washer [200128]",
          },
          {
            input: "number",
            name: "200128-Cylinder Valve Washer",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
        ],
        [
          {
            label: "Cylinder Valve Plunger Seat [400013]",
          },
          {
            input: "number",
            name: "400013-Cylinder Valve Plunger Seat",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
        ],
        [
          {
            label: "PTFE O-Rings [200127]",
          },
          {
            input: "number",
            name: "200127-PTFE O-Rings",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
        ],
        [
          {
            label: "PTFEN Product Tubing[200110]",
          },
          {
            input: "number",
            name: "200110-PTFEN Product Tubing",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
        ],
        [
          {
            label: "Cylinder Valve Plunger[400011]",
          },
          {
            input: "number",
            name: "400011-Cylinder Valve Plunger",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
        ],
        [
          {
            label: "Cylinder Valve Retaining Nut200129]",
          },
          {
            input: "number",
            name: "200129-Cylinder Valve Retaining Nut",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
        ],
        [
          {
            label: "Valve Plunger Lock Nut[200122]",
          },
          {
            input: "number",
            name: "200122-Valve Plunger Lock Nut",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
        ],
        [
          {
            label: "Cylinder Valve Housing[200296]",
          },
          {
            input: "number",
            name: "200296-Cylinder Valve Housing",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
        ],
        [
          {
            label: "Cylinder Valve Packing Gland[400012]",
          },
          {
            input: "number",
            name: "400012-Cylinder Valve Packing Gland",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
        ],
      ],
    },
  ],
};
const pnuemPeristalticSelection = {
  title: "Pneum-Peristaltic Filling Options",
  description:
    "A hybrid of the pneumatic and peristaltic setup for products requiring additional back pressure with high output such as creams",
  systemType: "Pneum-Peristaltic Setup",
  category: "setup",
  form: [
    {
      type: "SimpleDropdown",
      name: "pnuem-peri_nozzle",
      label: "Pneum-Perstaltic Filling Option",
      options: [
        {
          value: "500343-Cream Filling 1 pump/nozzle",
          label: "Cream Filling 1 Pump/Nozzle",
          code: "500343",
        },
        {
          value: "500344-Cream Filling 2 pump/nozzle",
          label: "Cream Filling 2 Pump/Nozzle",
          code: "500344",
        },
      ],
    },
    {
      type: "SimpleDropdown",
      name: "pneumatic_upgrade",
      label: "Pneumatic Upgrade Kit",
      subtitle: "upgrade kit from peristaltic",
      options: [
        {
          value: "500202-Pneumatic 1 nozzle cream upgrade",
          label: "Pneumatic 1 Nozzle",
          code: "500202",
        },
        {
          value: "500203-Pneumatic 2 nozzle cream upgrade",
          label: "Pneumatic 2 Nozzle",
          code: "500203",
        },
      ],
    },
    {
      type: "SimpleDropdown",
      name: "peristaltic_upgrade",
      label: "Peristaltic Upgrade Kit",
      subtitle: "upgrade kit from pneumatic",
      options: [
        {
          value: "500340-Peristaltic 1 pump cream upgrade",
          label: "Peristaltic 1 Pump",
          code: "500340",
        },
        {
          value: "500341-Peristaltic 2 pump cream upgrade",
          label: "Peristaltic 2 Pump",
          code: "500341",
        },
      ],
    },
    {
      type: "TextWTag",
      isChange: true,
      label: "Change Over Kit",
      subtitle: "Nozzle contact points swap",
      isQuantitative: true,
      isAside: true,
      unit: "Qty",
      tag: "peri_nozzle",
      options: {
        "500343-Cream Filling 1 pump/nozzle": {
          value: "500202-cream Filling 1 nozzle ChangeOver",
          label: "1",
        },
        "500344-Cream Filling 2 pump/nozzle": {
          value: "500203-cream Filling 2 nozzle ChangeOver",
          label: "2",
        },
      },
    },
    {
      type: "ModularTable",
      name: "spare",
      title: "Spare Parts",
      height: "52",
      isAside: true,
      isTable: true,
      TableInputs: [
        {
          name: "200284-8 32 x 1 inch Fasteners",
          isQuantitative: true,
        },
        {
          name: "200285-Round Nozzle 1/8 inch ID, 2-1/2 inch Long",
          isQuantitative: true,
        },
        {
          name: "200286-PVDF Barbed Hose Fitting",
          isQuantitative: true,
        },
        {
          name: "200287-316 SS Adapter for 3/8 inch ID 1/4 inch NPT Male",
          isQuantitative: true,
        },
        {
          name: "500211-Diaphragm Valve Mounting Bracket",
          isQuantitative: true,
        },
      ],
      subtitle: "Purpose of Capsule Counter",
      headers: ["Item", "Quantity"],
      options: [
        [
          {
            label: "8-32 x 1 inch Fasteners[200284]",
          },
          {
            input: "number",
            name: "200284-8-32 x 1 inch Fasteners",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
        ],
        [
          {
            label: "Round Nozzle 1/8 inch ID, 2-1/2 inch Long[200285]",
          },
          {
            input: "number",
            name: "200285-Round Nozzle 1/8 inch ID, 2-1/2 inch Long",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
        ],
        [
          {
            label: "PVDF Barbed Hose Fitting[200286]",
          },
          {
            input: "number",
            name: "200286-PVDF Barbed Hose Fitting",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
        ],
        [
          {
            label: "316 SS Adapter for 3/8 inch ID 1/4 inch NPT Male[200287]",
          },
          {
            input: "number",
            name: "200287-316 SS Adapter for 3/8 inch ID 1/4 inch NPT Male",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
        ],
        [
          {
            label: "Diaphragm Valve Mounting Bracket[500211]",
          },
          {
            input: "number",
            name: "500211-Diaphragm Valve Mounting Bracket",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
        ],
      ],
    },
  ],
};
const productTrays = {
  title: "Product Tray Selection",
  description:
    "Based on the products the client is filling we will need to manufacture custom trays to hold their product container on our Robot",
  systemType: "Product Trays",
  category: "assembly",
  form: [
    {
      type: "TraysTable",
      name: "spare",
      title: "Tray Options",
      height: "44",
      isTable: true,
      headers: ["Setup", "container", "Liquid", "Set QTY", "Additional QTY"],
      TableInputs: [
        {
          name: "500031-300 series tray set #",
          isQuantitative: true,
          isSubIndex: true,
        },
        {
          name: "500028-300S Additional Trays",
          isQuantitative: true,
        },
      ],
    },
    {
      type: "ModularTable",
      name: "spare",
      height: "72",
      title: "MonoBlox",
      isAside: true,
      isTable: true,
      contingency: "Cartridges",
      TableInputs: [
        {
          name: "500259-MonoBlox 16 Unit + Tray",
          isQuantitative: true,
        },
        {
          name: "MonoBlox 16 Unit + Tray Custom",
          isOption: true,
          optionFor: "MonoBlox 16 Unit + Tray",
        },
        {
          name: "500259-MonoBlox 32 Unit + Tray",
          isQuantitative: true,
        },
        {
          name: "MonoBlox 32 Unit + Tray Custom",
          isOption: true,
          optionFor: "MonoBlox 32 Unit + Tray",
        },
        {
          name: "500259-MonoBlox 16 Unit",
          isQuantitative: true,
        },
        {
          name: "MonoBlox 16 Unit Custom",
          isOption: true,
          optionFor: "MonoBlox 16 Unit",
        },
        {
          name: "500259-MonoBlox 32 Unit",
          isQuantitative: true,
        },
        {
          name: "MonoBlox 32 Unit Custom",
          isOption: true,
          optionFor: "MonoBlox 32 Unit",
        },
        {
          name: "500226-50Bloc Jig Package",
          isQuantitative: true,
        },
        {
          name: "500227-50Bloc Top Trays",
          isQuantitative: true,
        },
        {
          name: "500228-50Bloc Bottom Trays",
          isQuantitative: true,
        },
      ],
      subtitle: "Purpose of Capsule Counter",
      headers: ["Item", "Quantity", "Custom"],
      options: [
        [
          {
            label: "MonoBlox 16 Unit + Tray [500335/500337]",
          },
          {
            input: "number",
            name: "500335-MonoBlox 16 Unit + Tray",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
          {
            input: "checkbox",
            inputStyle:
              "focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded",
            name: "MonoBlox 16 Unit + Tray Custom",
            value: "500337-MonoBlox 16 Unit + Tray Custom",
          },
        ],
        [
          {
            label: "MonoBlox 32 Unit + Tray [500336/500338]",
          },
          {
            input: "number",
            name: "500336-MonoBlox 32 Unit + Tray",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
          {
            input: "checkbox",
            inputStyle:
              "focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded",
            name: "MonoBlox 32 Unit + Tray Custom",
            value: "500338-MonoBlox 32 Unit + Tray Custom",
          },
        ],
        [
          {
            label: "MonoBlox 16 Unit [500157/500159]",
          },
          {
            input: "number",
            name: "500157-MonoBlox 16 Unit",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
          {
            input: "checkbox",
            inputStyle:
              "focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded",
            name: "MonoBlox 16 Unit Custom",
            value: "500159-MonoBlox 16 Unit Custom",
          },
        ],
        [
          {
            label: "MonoBlox 32 Unit [500158/500160]",
          },
          {
            input: "number",
            name: "500158-MonoBlox 32 Unit",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
          {
            input: "checkbox",
            inputStyle:
              "focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded",
            name: "MonoBlox 32 Unit Custom",
            value: "500160-MonoBlox 32 Unit Custom",
          },
        ],
        [
          {
            label: "50Bloc Jig Package AVD [500226]",
          },
          {
            input: "number",
            name: "500226-50Bloc Jig Package",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
        ],
        [
          {
            label: "50Bloc Top Trays [500227]",
          },
          {
            input: "number",
            name: "500227-50Bloc Top Trays",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
        ],
        [
          {
            label: "50Bloc Bottom Trays [500228]",
          },
          {
            input: "number",
            name: "500228-50Bloc Bottom Trays",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
        ],
      ],
    },
  ],
};

const pressureVessel = {
  title: "Pressure Vessel Options",
  description:
    "All filling options EXCEPT for the peristaltic option, require a pressure vessel to hold product and operate the robot. Please select a air pressure connector and pressure vessel(s) based on the clients requirements.",
  systemType: "Pressure Vessel",
  category: "assembly",
  validation: [
    { index: 1, VariableName: "volumetric_System_Type" },
    { index: 3, VariableName: "pneumatic_Nozzle" },
  ],
  form: [
    {
      type: "SimpleRadio",
      name: "pou",
      label: "Point of Air (POU) Connector",
      validation: {
        default: [
          { index: 1, VariableName: "volumetric_System_Type" },
          { index: 3, VariableName: "pneumatic_Nozzle" },
        ],
      },
      options: [
        {
          value: "500023-12 PSI Input",
          label: "12 PSI Input",
        },
        {
          value: "500024-60 PSI Input",
          label: "60 PSI Input",
        },
      ],
    },
    {
      type: "ModularTable",
      name: "spare",
      title: "Vessel Options",
      height: "80",
      isTable: true,
      TableInputs: [
        {
          name: "500234-1 Litre Pressure Vessel w/ stand",
          isQuantitative: true,
        },
        {
          name: "1L mixer",
          dependant: "500234-1 Litre Pressure Vessel w/ stand",
        },
        {
          name: "500040-1 Litre Pressure Vessel (no stand)",
          isQuantitative: true,
        },
        {
          name: "1L mixer no stand",
          dependant: "500040-1 Litre Pressure Vessel (no stand)",
        },
        {
          name: "500034-1 Gallon Pressure Vessel",
          isQuantitative: true,
        },
        {
          name: "1G mixer",
          dependant: "500034-1 Gallon Pressure Vessel",
        },
        {
          name: "500036-3 Gallon Pressure Vessel",
          isQuantitative: true,
        },
        {
          name: "3G mixer",
          dependant: "500036-3 Gallon Pressure Vessel",
        },
        {
          name: "500037-5 Gallon Pressure Vessel",
          isQuantitative: true,
        },
        {
          name: "5G mixer",
          dependant: "500037-5 Gallon Pressure Vessel",
        },
        {
          name: "500038-10 Gallon Pressure Vessel",
          isQuantitative: true,
        },
        {
          name: "10G mixer",
          dependant: "500038-10 Gallon Pressure Vessel",
        },
      ],
      subtitle: "Purpose of Capsule Counter",
      headers: ["Vessel Type", "Quantity", "W/ Mixer"],
      options: [
        [
          {
            label: "1 Litre Pressure Vessel w/ stand",
          },
          {
            input: "number",
            name: "500234-1 Litre Pressure Vessel w/ stand",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
          {
            input: "checkbox",
            inputStyle:
              "focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded",
            name: "1L mixer",
            value: "500123-1L Mixer",
          },
        ],
        [
          {
            label: "1 Litre Pressure Vessel (no stand)",
          },
          {
            input: "number",
            name: "500040-1 Litre Pressure Vessel (no stand)",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
          {
            input: "checkbox",
            inputStyle:
              "focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded",
            name: "1L mixer no stand",
            value: "500123-1L Mixer",
          },
        ],
        [
          {
            label: "1 Gallon Pressure Vessel",
          },
          {
            input: "number",
            name: "500034-1 Gallon Pressure Vessel",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
          {
            input: "checkbox",
            inputStyle:
              "focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded",
            name: "1G mixer",
            value: "500123-1G Mixer",
          },
        ],
        [
          {
            label: "3 Gallon Pressure Vessel",
          },
          {
            input: "number",
            name: "500036-3 Gallon Pressure Vessel",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
          {
            input: "checkbox",
            inputStyle:
              "focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded",
            name: "3G mixer",
            value: "500123-3G Mixer",
          },
        ],
        [
          {
            label: "5 Gallon Pressure Vessel",
          },
          {
            input: "number",
            name: "500037-5 Gallon Pressure Vessel",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
          {
            input: "checkbox",
            inputStyle:
              "focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded",
            name: "5G mixer",
            value: "500123-5G Mixer",
          },
        ],
        [
          {
            label: "10 Gallon Pressure Vessel",
          },
          {
            input: "number",
            name: "500038-10 Gallon Pressure Vessel",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
          {
            input: "checkbox",
            inputStyle:
              "focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded",
            name: "10G mixer",
            value: "500123-10G Mixer",
          },
        ],
      ],
    },
    {
      type: "ModularTable",
      name: "spare",
      title: "Heating Options",
      height: "52",
      isTable: true,
      isAside: true,
      TableInputs: [
        {
          name: "1L-heating lines",
        },
        {
          name: "1L line selection",
          quantityFor: "1L-heating lines",
        },
        {
          name: "1G-heating lines",
        },
        {
          name: "1G line selection",
          quantityFor: "1G-heating lines",
        },
        {
          name: "3G-heating lines",
        },
        {
          name: "3G line selection",
          quantityFor: "3G-heating lines",
        },
        {
          name: "5G-heating lines",
        },
        {
          name: "5G line selection",
          quantityFor: "5G-heating lines",
        },
        {
          name: "10G-heating lines",
        },
        {
          name: "10G line selection",
          quantityFor: "10G-heating lines",
        },
      ],
      subtitle: "Purpose of Capsule Counter",
      headers: ["Vessel", "Lines", "Quantity"],
      options: [
        [
          {
            label: "1 Litre Options",
          },
          {
            select: true,
            name: "1L-heating lines",
            options: [
              {
                value: "500058-1L 1 Line Heating",
                label: "1 Line Heating [500058]",
                code: "500058",
              },
              {
                value: "500059-1L 2 Line Heating",
                label: "2 Line Heating [500059]",
                code: "500059",
              },
              {
                value: "500060-1L 3 Line Heating",
                label: "3 Line Heating [500060]",
                code: "500060",
              },
              {
                value: "500061-1L 4 Line Heating",
                label: "4 Line Heating [500061]",
                code: "500061",
              },
            ],
          },
          {
            input: "number",
            name: "1L line selection",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
        ],
        [
          {
            label: "1 Gallon Options",
          },
          {
            select: true,
            name: "1G-heating lines",
            options: [
              {
                value: "500042-1G 1 Line Heating",
                label: "1 Line Heating [500042]",
                code: "500042",
              },
              {
                value: "500043-1G 2 Line Heating",
                label: "2 Line Heating [500043]",
                code: "500043",
              },
              {
                value: "500044-1G 3 Line Heating",
                label: "3 Line Heating [500044]",
                code: "500044",
              },
              {
                value: "500045-1G 4 Line Heating",
                label: "4 Line Heating [500045]",
                code: "500045",
              },
            ],
          },
          {
            input: "number",
            name: "1G line selection",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
        ],
        [
          {
            label: "3 Gallon Options",
          },
          {
            select: true,
            name: "3G-heating lines",
            options: [
              {
                value: "500046-3G 1 Line Heating",
                label: "1 Line Heating [500046]",
                code: "500046",
              },
              {
                value: "500047-3G 2 Line Heating",
                label: "2 Line Heating [500047]",
                code: "500047",
              },
              {
                value: "500048-3G 3 Line Heating",
                label: "3 Line Heating [500048]",
                code: "500048",
              },
              {
                value: "500049-3G 4 Line Heating",
                label: "4 Line Heating [500049]",
                code: "500049",
              },
            ],
          },
          {
            input: "number",
            name: "3G line selection",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
        ],
        [
          {
            label: "5 Gallon Options",
          },
          {
            select: true,
            name: "5G-heating lines",
            options: [
              {
                value: "500050-5G 1 Line Heating",
                label: "1 Line Heating [500050]",
                code: "500050",
              },
              {
                value: "500051-5G 2 Line Heating",
                label: "2 Line Heating [500051]",
                code: "500051",
              },
              {
                value: "500052-5G 3 Line Heating",
                label: "3 Line Heating [500052]",
                code: "500052",
              },
              {
                value: "500053-5G 4 Line Heating",
                label: "4 Line Heating [500053]",
                code: "500053",
              },
            ],
          },
          {
            input: "number",
            name: "5G line selection",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
        ],
        [
          {
            label: "10 Gallon Options",
          },
          {
            select: true,
            name: "10G-heating lines",
            options: [
              {
                value: "500054-10G 1 Line Heating",
                label: "1 Line Heating [500054]",
                code: "500054",
              },
              {
                value: "500055-10G 2 Line Heating",
                label: "2 Line Heating [500055]",
                code: "500055",
              },
              {
                value: "500056-10G 3 Line Heating",
                label: "3 Line Heating [500056]",
                code: "500056",
              },
              {
                value: "500057-10G 4 Line Heating",
                label: "4 Line Heating [500057]",
                code: "500057",
              },
            ],
          },
          {
            input: "number",
            name: "10G line selection",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
        ],
      ],
    },
    {
      type: "ModularTable",
      name: "spare",
      title: "Spare Parts",
      height: "48",
      isAside: true,
      isTable: true,
      TableInputs: [
        {
          name: "200176-Vessel Cap",
          isQuantitative: true,
        },
        {
          name: "200051-Vessel Silicon Gasket",
          isQuantitative: true,
        },
        {
          name: "500041-Pressure Stand ONLY",
          isQuantitative: true,
        },
        {
          name: "200057-Stretch Coil Heater",
          isQuantitative: true,
        },
        {
          name: "200063-1 L Heating Jacket",
          isQuantitative: true,
        },
        {
          name: "200059-1 Gal Heating Jacket",
          isQuantitative: true,
        },
        {
          name: "200060-3 Gal Heating Jacket",
          isQuantitative: true,
        },
        {
          name: "200061-5 Gal Heating Jacket",
          isQuantitative: true,
        },
        {
          name: "200062-10 Gal Heating Jacket",
          isQuantitative: true,
        },
        {
          name: "200055-Heating Power Cord",
          isQuantitative: true,
        },
      ],
      subtitle: "Purpose of Capsule Counter",
      headers: ["Item", "Quantity"],
      options: [
        [
          {
            label: "Vessel Cap [200176]",
          },
          {
            input: "number",
            name: "200176-Vessel Cap",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
        ],
        [
          {
            label: "Vessel Silicon Gasket [200051]",
          },
          {
            input: "number",
            name: "200051-Vessel Silicon Gasket",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
        ],
        [
          {
            label: "Pressure Stand ONLY [500041]",
          },
          {
            input: "number",
            name: "500041-Pressure Stand ONLY",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
        ],
        [
          {
            label: "Stretch Coil Heater [200057]",
          },
          {
            input: "number",
            name: "200057-Stretch Coil Heater",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
        ],
        [
          {
            label: "1 Litre Heating Jacket [200063]",
          },
          {
            input: "number",
            name: "200063-1 L Heating Jacket",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
        ],
        [
          {
            label: "1 Gal Heating Jacket [200059]",
          },
          {
            input: "number",
            name: "200059-1 Gal Heating Jacket",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
        ],
        [
          {
            label: "3 Gal Heating Jacket [200060]",
          },
          {
            input: "number",
            name: "200060-3 Gal Heating Jacket",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
        ],
        [
          {
            label: "5 Gal Heating Jacket [200061]",
          },
          {
            input: "number",
            name: "200061-5 Gal Heating Jacket",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
        ],
        [
          {
            label: "10 Gal Heating Jacket [200062]",
          },
          {
            input: "number",
            name: "200062-10 Gal Heating Jacket",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
        ],
        [
          {
            label: "Heating Power Cord[200055]",
          },
          {
            input: "number",
            name: "200055-Heating Power Cord",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
        ],
      ],
    },
  ],
};

const packagingSelection = {
  title: "Packaging Options",
  description:
    "Add on to the clients production line by also providing them with post filling packaging equipment.",
  systemType: "Packaging equipment",
  category: "assembly",
  form: [
    {
      type: "ModularTable",
      name: "spare",
      title: "Equipment",
      height: "80",
      label: "ATC-100 Capsule Counter",
      isTable: true,
      TableInputs: [
        {
          name: "500247-ACT 100 Tabletop Capper",
          isQuantitative: true,
        },
        {
          name: "ACT 100 Certified",
          isOption: true,
          optionFor: "ACT 100 Tabletop Capper",
        },
        {
          name: "500248-ALR 1000 TableTop Bottle Labeler",
          isQuantitative: true,
        },
        {
          name: "ALR 100 Certified",
          isOption: true,
          optionFor: "ALR 1000 TableTop Bottle Labeler",
        },
        {
          name: "500250-ATC 100 Capsule Counter",
          isQuantitative: true,
        },
        {
          name: "ATC 100 Certified",
          isOption: true,
          optionFor: "ATC 100 Capsule Counter",
        },
        {
          name: "500251-ART 600 Accumulating Turntable",
          isQuantitative: true,
        },
        {
          name: "ART 600 Certified",
          isOption: true,
          optionFor: "ART 600 Accumulating Turntable",
        },
        {
          name: "500249-ALC 100 Hot Stamp Coder",
          isQuantitative: true,
        },
        {
          name: "ALC 100 Certified",
          isOption: true,
          optionFor: "ALC 100 Hot Stamp Coder",
        },
        {
          name: "500246-ACW 100 Semi auto Capper",
          isQuantitative: true,
        },
        {
          name: "ACW-100 Certified",
          isOption: true,
          optionFor: "ACW 100 Semi auto Capper",
        },
        {
          name: "500258-Slat Loader",
          isQuantitative: true,
        },
        {
          name: "Slat Loader Certified",
          isOption: true,
          optionFor: "Slat Loader",
        },
        {
          name: "500259-Capsule Bander",
          isQuantitative: true,
        },
        {
          name: "Capsule Bander Certified",
          isOption: true,
          optionFor: "Capsule Bander",
        },
        {
          name: "500112-Mono Press",
          isQuantitative: true,
        },
      ],
      subtitle: "Purpose of Capsule Counter",
      headers: ["Vessel Type", "Quantity", "CSA Certified"],
      options: [
        [
          {
            label: "ACT-100 Tabletop Capper (500247/500113)",
          },
          {
            input: "number",
            name: "500247-ACT 100 Tabletop Capper",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
          {
            input: "checkbox",
            inputStyle:
              "focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded",
            name: "ACT 100 Certified",
            value: "500113-ACT 100 Tabletop Capper Certified",
          },
        ],
        [
          {
            label: "ALR-1000 TableTop Bottle Labeler (500248/500114)",
          },
          {
            input: "number",
            name: "500248-ALR 1000 TableTop Bottle Labeler",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
          {
            input: "checkbox",
            inputStyle:
              "focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded",
            name: "ALR 100 Certified",
            value: "500114-ALR 1000 TableTop Bottle Labeler Certified",
          },
        ],
        [
          {
            label: "ATC-100 Capsule Counter (500250/500115)",
          },
          {
            input: "number",
            name: "500250-ATC 100 Capsule Counter",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
          {
            input: "checkbox",
            inputStyle:
              "focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded",
            name: "ATC 100 Certified",
            value: "500115-ATC 100 Capsule Counter Certified",
          },
        ],
        [
          {
            label: "ART-600 Accumulating Turntable (500251/500116)",
          },
          {
            input: "number",
            name: "500251-ART 600 Accumulating Turntable",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
          {
            input: "checkbox",
            inputStyle:
              "focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded",
            name: "ART 600 Certified",
            value: "500116-ART 600 Accumulating Turntable Certified",
          },
        ],
        [
          {
            label: "ALC-100 Hot Stamp Coder (500249/500179)",
          },
          {
            input: "number",
            name: "500249-ALC 100 Hot Stamp Coder",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
          {
            input: "checkbox",
            inputStyle:
              "focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded",
            name: "ALC 100 Certified",
            value: "500179-ALC 100 Hot Stamp Coder Certified",
          },
        ],
        [
          {
            label: "ACW-100 Semi-auto Capper (500246/500180)",
          },
          {
            input: "number",
            name: "500246-ACW 100 Semi auto Capper",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
          {
            input: "checkbox",
            inputStyle:
              "focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded",
            name: "ACW-100 Certified",
            value: "500180-ACW 100 Semi auto Capper Certified",
          },
        ],
        [
          {
            label: "Slat Loader (500258/500230)",
          },
          {
            input: "number",
            name: "500258-Slat Loader",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
          {
            input: "checkbox",
            inputStyle:
              "focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded",
            name: "Slat Loader Certified",
            value: "500230-Slat Loader Certified",
          },
        ],
        [
          {
            label: "Capsule Bander (500259/500231)",
          },
          {
            input: "number",
            name: "500259-Capsule Bander",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
          {
            input: "checkbox",
            inputStyle:
              "focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded",
            name: "Capsule Bander Certified",
            value: "500231-Capsule Bander Certified",
          },
        ],
        [
          {
            label: "Mono Press (500112)",
          },
          {
            input: "number",
            name: "500112-Mono Press",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
        ],
      ],
    },
    {
      type: "ModularTable",
      name: "spare",
      title: "Spare Parts",
      isAside: true,
      height: "80",
      label: "ATC-100 Capsule Counter",
      isTable: true,
      TableInputs: [
        {
          name: "200324-Labeller Conveyor Belt",
          isQuantitative: true,
        },
        {
          name: "200343-Labeller Red Roller",
          isQuantitative: true,
        },
        {
          name: "300070-Labeller Adjustable Knob",
          isQuantitative: true,
        },
        {
          name: "200309-Label Feed Spool",
          isQuantitative: true,
        },
      ],
      subtitle: "Purpose of Capsule Counter",
      headers: ["Item", "Quantity"],
      options: [
        [
          {
            label: "Labeller Conveyor Belt [200324]",
          },
          {
            input: "number",
            name: "200324-Labeller Conveyor Belt",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
        ],
        [
          {
            label: "Labeller Red Roller [200343]",
          },
          {
            input: "number",
            name: "200343-Labeller Red Roller",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
        ],
        [
          {
            label: "Labeller Adjustable Knob [300070]",
          },
          {
            input: "number",
            name: "300070-Labeller Adjustable Knob",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
        ],
        [
          {
            label: "Label Feed Spool [200309]",
          },
          {
            input: "number",
            name: "200309-Label Feed Spool",
            inputStyle:
              "focus:ring-indigo-500 focus:border-indigo-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300",
          },
        ],
      ],
    },
  ],
};

const documentationSelection = {
  title: "Documentation",
  description:
    "Based on the items selected thus far, clients may request documentation for the equipment for standards and procedures",
  systemType: "Documentation",
  category: "external",
  form: [
    {
      type: "SimpleCheckbox",
      name: "R200Doc",
      isCheckbox: true,
      label: "RoboCAP 200 Documentation",
      validation: {
        head: "machine",
        isValue: ["500204-RoboCAP 200 Series"],
        key: "auto_filling_machine",
      },
      options: [
        {
          value: "600001-RoboCAP 200 Std SOP's-Set Up & Operational",
          label: "SOP Setup & Operation",
        },
        {
          value: "600002-RoboCAP Rl 200 Std SOP's Cleaning Procedures",
          label: "SOP Cleaning Procedure",
        },
        {
          value:
            "600003-RoboCAP Rl200 Std SOP's Preventative Maintenance Procedures",
          label: "SOP Preventive Maintenance",
        },
        {
          value: "600004-RoboCAP Rl 200 Std IQ/OQ Protcol",
          label: "IQ/QQ Protocol",
        },
        {
          value: "600005-RoboCAP Rl200 Std FAT Protocol",
          label: "Std SAT Protocol",
        },
        {
          value: "600006-RoboCAP Rl200 Std SAT Protocol",
          label: "Std FAT Protocol",
        },
        {
          value: "600007-RoboCAP 200 Std PQ Protocol",
          label: "Std PQ Protocol",
        },
        {
          value: "600008-RoboCap 200 Execution of FAT,SAT,IQ/OQ,PQ",
          label: "Execution of FAT,SAT,IQ/OQ,PQ",
        },
      ],
    },
    {
      type: "SimpleCheckbox",
      name: "R300Doc",
      label: "RoboCAP 300 Documentation",
      isCheckbox: true,
      validation: {
        head: "machine",
        isValue: ["500205-RoboCAP 300 Series", "500206-RoboCAP 300Z Series"],
        key: "auto_filling_machine",
      },
      options: [
        {
          value: "600009-RoboCAP 300 SOP",
          label: "SOP Setup & Operation",
        },
        {
          value: "600010-RoboCAP 300 IQ/OQ",
          label: "IQ/QQ Protocol",
        },
        {
          value: "600011-RoboCAP 300 PQ Qualification Protocol Development",
          label: "Std PQ Protocol",
        },
        {
          value: "600056-RL-300 SOP  Cleaning Procedure",
          label: "SOP  Cleaning Procedure",
        },
      ],
    },
    {
      type: "SimpleCheckbox",
      name: "R300Doc",
      label: "ATC-1000 Documentation",
      isCheckbox: true,
      validation: {
        head: "packaging",
        key: "500250-ATC 100 Capsule Counter",
      },
      options: [
        {
          value: "600009-RoboCAP 300 SOP",
          label: "SOP Setup & Operation",
        },
        {
          value: "600010-RoboCAP 300 IQ/OQ",
          label: "IQ/QQ Protocol",
        },
        {
          value: "600011-RoboCAP 300 PQ Qualification Protocol Development",
          label: "Std PQ Protocol",
        },
        {
          value: "600056-RL-300 SOP  Cleaning Procedure",
          label: "SOP  Cleaning Procedure",
        },
      ],
    },
  ],
};
const objects = {
  machineSelection,
  volumetricSelection,
  volumetricDeluxeSelection,
  peristalticSelection,
  pneumaticSelection,
  pnuemPeristalticSelection,
  productTrays,
  pressureVessel,
  packagingSelection,
  documentationSelection,
};
export default objects;
