import React from "react";
import { Helmet } from "react-helmet";
import { Outlet } from "react-router-dom";

//COMPONENT
import SimpleCard from "../../../components/Card/simpleCard";

const TicketsView = () => {
  return (
    <SimpleCard>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Tickets | ATG Staff</title>
      </Helmet>
      <Outlet />
    </SimpleCard>
  );
};

export default TicketsView;
