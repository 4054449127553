import React from "react";
import { Outlet } from "react-router-dom";
import { Helmet } from "react-helmet";

const OrderEstimateView = () => {
  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Order | ATG Staff</title>
      </Helmet>
      <Outlet />
    </React.Fragment>
  );
};

export default OrderEstimateView;
