import React, { useState, useEffect } from 'react';
import { BrowserRouter, useRoutes } from 'react-router-dom';

// BASE WINDOW
import BaseWindow from './components/Base';

//HELPER
import changeRoutesStructure from './helper/changeRoutesStructure';

// HOOk
import useConnectAuth from './hooks/useConnectAuth';

const Router = () => {
  return (
    <BrowserRouter>
      <BaseWindow View={RouterView} />
    </BrowserRouter>
  );
};

export default Router;

const RouterView = () => {
  const { routes } = useConnectAuth();
  const [routeList, setRouteList] = useState([]);

  useEffect(() => {
    async function getRoute() {
      let temp = await changeRoutesStructure(routes);
      setRouteList(temp);
    }
    getRoute();
  }, [routes]);

  let element = useRoutes(routeList);
  return element;
};
