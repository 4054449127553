import React, { useReducer, createContext } from "react";

// DATA
import structure from "../hooks/useEstimate/data/formStructure";

export const EstimateContext = createContext();

const initialState = {
  pageOrder: 0,
  defaultValues: [],
  defaultPricing: [],
  buildPricing: [],
  currentBuild: [],
  buildSeries: [],
  isReview: false,
  isParameter: false,
  isProduct: true,
  isSubmit: false,
  isLoading: false,
  estimate: {},
  lineItem: 1,
  seriesIndex: 0,
  totalPrice: 0,
  workingPrice: 0,
  productOverLay: false,
  productOverlayDetails: {},
  systemOverLay: false,
  systemOverlayDetails: {},
  products: [
    {
      product: { name: "product-1", value: null },
      category: { name: "category-1", value: null },
    },
  ],
  parameters: [
    {
      container: { name: "container-1", value: null },
      volume: { name: "volume-1", value: null, label: null },
      heating: { name: "heating-1", value: null },
      product: { name: "product-1", value: null },
      system: { name: "system-1", value: null },
      machine: { name: "machine-1", value: null },
    },
  ],
  headObject: "machine",
  tailObject: "packaging",
  // tailObject: "documentation",
  buildIndex: "1",
  formStructure: Object.assign({}, JSON.parse(JSON.stringify(structure))),
  predicted_estimate: null,
  currency: "",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_FORM_PAGE":
      return {
        ...state,
        headObject: action.payload.hd,
        pageOrder: action.payload.page,
      };
    case "SET_DEFAULT_VALUES":
      return {
        ...state,
        formStructure: action.payload,
      };
    case "SET_BUILD_INDEX":
      return {
        ...state,
        buildIndex: action.payload,
      };
    case "SET_DEFAULT_PRICING":
      return {
        ...state,
        defaultPricing: action.payload,
      };
    case "SET_PREDICTED_ESTIMATE":
      return {
        ...state,
        predicted_estimate: action.payload,
      };
    case "SET_PARAMETERS":
      return {
        ...state,
        isParameter: action.payload,
      };
    case "SET_CURRENCY":
      return {
        ...state,
        currency: action.payload,
      };
    case "SET_PAGE":
      return {
        ...state,
        pageOrder: action.payload,
      };
    case "SET_IS_LOADING":
      return {
        ...state,
        isLoading: action.payload,
      };
    case "SET_ESTIMATE":
      return {
        ...state,
        estimate: action.payload,
      };
    case "SET_LINE_ITEM":
      return {
        ...state,
        lineItem: action.payload,
      };
    case "SET_CURRENT_BUILD":
      return {
        ...state,
        currentBuild: action.payload,
      };
    case "SET_IN_REVIEW":
      return {
        ...state,
        buildSeries: action.payload.buildSeries,
        buildPricing: action.payload.buildPrice,
        isParameter: false,
        isProduct: false,
        isReview: action.payload.status,
      };
    case "UNSET_IN_REVIEW":
      return {
        ...state,
        isReview: action.payload,
      };
    case "ADD_TO_BUILD":
      return {
        ...state,
        defaultValues: [],
        defaultPricing: [],
        currentBuild: [],
        pageOrder: 0,
        isReview: false,
        seriesIndex: action.payload,
      };
    case "UPDATE_PRICE":
      return {
        ...state,
        totalPrice: action.payload,
      };
    case "SET_IS_PARAMETERS":
      return {
        ...state,
        parameters: action.payload.parameters,
        products: action.payload.prods,
        isProduct: false,
      };
    case "SET_IS_PRODUCTS":
      return {
        ...state,
        isProduct: action.payload.isProduct,
        parameters: action.payload.parameters,
        isParameter: false,
      };
    case "SET_PARAMETERS_SELECTION":
      return {
        ...state,
        parameters: action.payload,
      };
    case "SET_PRODUCTS_SELECTION":
      return {
        ...state,
        products: action.payload,
      };
    case "SET_SUBMIT":
      return {
        ...state,
        isSubmit: action.payload,
      };
    case "SET_PRODUCT_OVERLAY":
      return {
        ...state,
        productOverLay: action.payload.status,
        productOverlayDetails: action.payload.details,
      };
    case "SET_SYSTEM_OVERLAY":
      return {
        ...state,
        systemOverLay: action.payload.status,
        systemOverlayDetails: action.payload.details,
      };
    case "JUMP_TO_REVIEW":
      return {
        ...state,
        isParameter: false,
        isProduct: false,
        isReview: true,
        buildSeries: [action.payload.buildSeries],
        buildPricing: [action.payload.totalPrice],
        currentBuild: action.payload.buildSeries,
        products: action.payload.products,
        parameters: action.payload.params,
        totalPrice: action.payload.totalPrice,
        formStructure: action.payload.structure,
      };
    default:
      return { ...state };
  }
};

export const EstimateContextProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <EstimateContext.Provider value={[state, dispatch]}>
      {props.children}
    </EstimateContext.Provider>
  );
};
