import React from "react";
import { Helmet } from "react-helmet";
//COMPONENT
import SimpleCard from "../../../components/Card/simpleCard";

const UniqueTicketView = () => {
  return (
    <SimpleCard>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Tickets | ATG Staff</title>
      </Helmet>
      <h1>unique</h1>
    </SimpleCard>
  );
};

export default UniqueTicketView;
