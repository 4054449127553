//OBJECTS
import defaults from "./formDefaults";
const {
  volumetricDef,
  volumetricDeluxeDef,
  peristalticDef,
  vesselDef,
  vesselWHeatDef,
  traysDef,
  pneumaticDef,
  pnuemPeristalticDef,
} = defaults;
const map = {
  0: {
    label: "Bottles",
    value: 0,
    units: "ml",
    error: "Bottle volumes must be between 10ml - 100ml",
    volumes: {
      0: {
        min: 9.9,
        max: 100.1,
        value: 0,
        noHeat: true,
        heating: {
          0: {
            label: "False",
            value: 0,
            system: "Peristaltic",
            requires: {
              peristaltic: peristalticDef,
              trays: traysDef,
            },
          },
        },
      },
    },
  },
  1: {
    label: "Tinctures",
    units: "ml",
    value: 1,
    error: "Tincture volumes must be between 10ml - 100ml",
    volumes: {
      0: {
        min: 9.9,
        max: 100.1,
        value: 0,
        noHeat: true,
        heating: {
          0: {
            label: "False",
            value: 0,
            system: "Peristaltic",
            requires: {
              peristaltic: peristalticDef,
              trays: traysDef,
            },
          },
        },
      },
    },
  },
  2: {
    label: "Vials",
    units: "ml",
    value: 2,
    error: "Vial volumes must be between 0.1ml - 100ml",
    volumes: {
      0: {
        min: 0.09,
        max: 2.001,
        value: 0,
        heating: {
          0: {
            label: "False",
            value: 0,
            system: "Volumetric",
            requires: {
              volumetric: volumetricDef,
              vessel: vesselDef,
              trays: traysDef,
            },
          },
          1: {
            label: "True",
            value: 1,
            system: "Volumetric",
            requires: {
              volumetric: volumetricDef,
              vessel: vesselWHeatDef,
              trays: traysDef,
            },
          },
        },
      },
      1: {
        min: 2.001,
        max: 9.9,
        value: 1,
        heating: {
          0: {
            label: "False",
            value: 0,
            system: "Volumetric Deluxe",
            requires: {
              volumetricDeluxe: volumetricDeluxeDef,
              vessel: vesselDef,
              trays: traysDef,
            },
          },
          1: {
            label: "True",
            value: 1,
            system: "Volumetric Deluxe",
            requires: {
              volumetricDeluxe: volumetricDeluxeDef,
              vessel: vesselWHeatDef,
              trays: traysDef,
            },
          },
        },
      },
      2: {
        min: 10,
        max: 100.1,
        value: 2,
        noHeat: true,
        heating: {
          0: {
            label: "False",
            value: 0,
            system: "Peristaltic",
            requires: {
              peristaltic: peristalticDef,
              trays: traysDef,
            },
          },
        },
      },
    },
  },
  3: {
    label: "Cartridges",
    units: "g",
    error: "Cartridge volumes must be between 0.1g - 9.9g",
    value: 3,
    volumes: {
      0: {
        min: 0.09,
        max: 2.001,
        value: 0,
        heating: {
          0: {
            label: "False",
            value: 0,
            system: "Volumetric",
            requires: {
              volumetric: volumetricDef,
              vessel: vesselDef,
              trays: traysDef,
            },
          },
          1: {
            label: "True",
            value: 1,
            system: "Volumetric",
            requires: {
              volumetric: volumetricDef,
              vessel: vesselWHeatDef,
              trays: traysDef,
            },
          },
        },
      },
      1: {
        min: 2.001,
        max: 10,
        value: 1,
        heating: {
          0: {
            label: "False",
            value: 0,
            system: "Volumetric Deluxe",
            requires: {
              volumetricDeluxe: volumetricDeluxeDef,
              vessel: vesselDef,
              trays: traysDef,
            },
          },
          1: {
            label: "True",
            value: 1,
            system: "Volumetric Deluxe",
            requires: {
              volumetricDeluxe: volumetricDeluxeDef,
              vessel: vesselWHeatDef,
              trays: traysDef,
            },
          },
        },
      },
    },
  },
  4: {
    label: "Syringes",
    units: "ml",
    value: 4,
    error: "Syringes volumes must be between 0.1ml - 9.9ml",
    volumes: {
      0: {
        min: 0.09,
        max: 2.001,
        value: 0,
        heating: {
          0: {
            label: "False",
            value: 0,
            system: "Volumetric",
            requires: {
              volumetric: volumetricDef,
              vessel: vesselDef,
              trays: traysDef,
            },
          },
          1: {
            label: "True",
            value: 1,
            system: "Volumetric",
            requires: {
              volumetric: volumetricDef,
              vessel: vesselWHeatDef,
              trays: traysDef,
            },
          },
        },
      },
      1: {
        min: 2.001,
        max: 10,
        value: 1,
        heating: {
          0: {
            label: "False",
            value: 0,
            system: "Volumetric Deluxe",
            requires: {
              volumetricDeluxe: volumetricDeluxeDef,
              vessel: vesselDef,
              trays: traysDef,
            },
          },
          1: {
            label: "True",
            value: 1,
            system: "Volumetric Deluxe",
            requires: {
              volumetricDeluxe: volumetricDeluxeDef,
              vessel: vesselWHeatDef,
              trays: traysDef,
            },
          },
        },
      },
    },
  },
  5: {
    label: "2 piece Capsules",
    units: "ml",
    dropdown: [
      {
        value: "0",
        label: "Size 0",
      },
      {
        value: "00",
        label: "Size 00",
      },
      {
        value: "000",
        label: "Size 000",
      },
      {
        value: "1",
        label: "Size 1",
      },
      {
        value: "2",
        label: "Size 2",
      },
      {
        value: "3",
        label: "Size 3",
      },
      {
        value: "4",
        label: "Size 4",
      },
    ],
    value: 5,
    error: "Capsules volumes must be between 0.1ml - 1.3ml",
    volumes: {
      0: {
        min: 0.09,
        max: 1.4,
        value: 0,
        heating: {
          0: {
            label: "False",
            value: 0,
            system: "Volumetric",
            requires: {
              volumetric: volumetricDef,
              vessel: vesselDef,
              trays: traysDef,
            },
          },
          1: {
            label: "True",
            value: 1,
            system: "Volumetric",
            requires: {
              volumetric: volumetricDef,
              vessel: vesselWHeatDef,
              trays: traysDef,
            },
          },
        },
      },
    },
  },
  6: {
    label: "oral Spray",
    units: "ml",
    value: 6,
    error: "Spray volumes must be between 10ml - 100ml",
    volumes: {
      0: {
        min: 9.9,
        max: 100.1,
        value: 0,
        noHeat: true,
        heating: {
          0: {
            label: "False",
            value: 0,
            system: "Peristaltic",
            requires: {
              peristaltic: peristalticDef,
              trays: traysDef,
            },
          },
        },
      },
    },
  },
  7: {
    label: "gel container",
    units: "g",
    value: 7,
    error: "gel volumes must be between 10g - 100g",
    volumes: {
      0: {
        min: 9.9,
        max: 100.1,
        value: 0,
        heating: {
          0: {
            label: "False",
            value: 0,
            system: "Pnuem-Peristaltic",
            requires: {
              pneumPeristaltic: pnuemPeristalticDef,
              vessel: vesselDef,
              trays: traysDef,
            },
          },
          1: {
            label: "True",
            value: 1,
            system: "Pnuem-Peristaltic",
            requires: {
              pneumPeristaltic: pnuemPeristalticDef,
              vessel: vesselWHeatDef,
              trays: traysDef,
            },
          },
        },
      },
    },
  },
  8: {
    label: "Cream container",
    units: "g",
    value: 8,
    error: "Cream volumes must be between 10g - 100g",
    volumes: {
      0: {
        min: 9.9,
        max: 100.1,
        value: 0,
        heating: {
          0: {
            label: "False",
            value: 0,
            system: "Pnuem-Peristaltic",
            requires: {
              pneumPeristaltic: pnuemPeristalticDef,
              vessel: vesselDef,
              trays: traysDef,
            },
          },
          1: {
            label: "True",
            value: 1,
            system: "Pnuem-Peristaltic",
            requires: {
              pneumPeristaltic: pnuemPeristalticDef,
              vessel: vesselWHeatDef,
              trays: traysDef,
            },
          },
        },
      },
    },
  },
  9: {
    label: "Chap stick",
    units: "g",
    error: "Chap volumes must be between 0.1g - 10g",
    value: 9,
    volumes: {
      0: {
        min: 0.09,
        max: 2.001,
        value: 0,
        heating: {
          0: {
            label: "False",
            value: 0,
            system: "Volumetric",
            requires: {
              volumetric: volumetricDef,
              vessel: vesselDef,
              trays: traysDef,
            },
          },
          1: {
            label: "True",
            value: 1,
            system: "Volumetric",
            requires: {
              volumetric: volumetricDef,
              vessel: vesselWHeatDef,
              trays: traysDef,
            },
          },
        },
      },
      1: {
        min: 2.001,
        max: 10.1,
        value: 1,
        heating: {
          0: {
            label: "False",
            value: 0,
            system: "Volumetric Deluxe",
            requires: {
              volumetricDeluxe: volumetricDeluxeDef,
              vessel: vesselDef,
              trays: traysDef,
            },
          },
          1: {
            label: "True",
            value: 1,
            system: "Volumetric Deluxe",
            requires: {
              volumetricDeluxe: volumetricDeluxeDef,
              vessel: vesselWHeatDef,
              trays: traysDef,
            },
          },
        },
      },
    },
  },
  10: {
    label: "Salves",
    units: "g",
    value: 10,
    error: "Salves volumes must be between 10g - 100g",
    volumes: {
      0: {
        min: 9.9,
        max: 100.1,
        value: 0,
        heating: {
          0: {
            label: "False",
            value: 0,
            system: "Pneumatic",
            requires: {
              pneumatic: pneumaticDef,
              vessel: vesselDef,
              trays: traysDef,
            },
          },
          1: {
            label: "True",
            value: 1,
            system: "Pneumatic",
            requires: {
              pneumatic: pneumaticDef,
              vessel: vesselWHeatDef,
              trays: traysDef,
            },
          },
        },
      },
    },
  },
  11: {
    label: "Balms",
    units: "g",
    error: "Balms volumes must be between 10g - 100g",
    value: 11,
    volumes: {
      0: {
        min: 9.9,
        max: 100.1,
        value: 0,
        heating: {
          0: {
            label: "False",
            value: 0,
            system: "Pneumatic",
            requires: {
              pneumatic: pneumaticDef,
              vessel: vesselDef,
              trays: traysDef,
            },
          },
          1: {
            label: "True",
            value: 1,
            system: "Pneumatic",
            requires: {
              pneumatic: pneumaticDef,
              vessel: vesselWHeatDef,
              trays: traysDef,
            },
          },
        },
      },
    },
  },
  12: {
    label: "Gummy mold",
    units: "g",
    error: "Gummy volumes must be between 0.1g - 10g",
    value: 12,
    volumes: {
      0: {
        min: 0.09,
        max: 2.001,
        value: 0,
        heating: {
          0: {
            label: "False",
            value: 0,
            system: "Volumetric",
            requires: {
              volumetric: volumetricDef,
              vessel: vesselDef,
              trays: traysDef,
            },
          },
          1: {
            label: "True",
            value: 1,
            system: "Volumetric",
            requires: {
              volumetric: volumetricDef,
              vessel: vesselWHeatDef,
              trays: traysDef,
            },
          },
        },
      },
      1: {
        min: 2.001,
        max: 10.1,
        value: 1,
        heating: {
          0: {
            label: "False",
            value: 0,
            system: "Volumetric Deluxe",
            requires: {
              volumetricDeluxe: volumetricDeluxeDef,
              vessel: vesselDef,
              trays: traysDef,
            },
          },
          1: {
            label: "True",
            value: 1,
            system: "Volumetric Deluxe",
            requires: {
              volumetricDeluxe: volumetricDeluxeDef,
              vessel: vesselWHeatDef,
              trays: traysDef,
            },
          },
        },
      },
    },
  },
  13: {
    label: "Candy mold",
    units: "g",
    value: 13,
    error: "Candy volumes must be between 0.1g - 10g",
    volumes: {
      0: {
        min: 0.09,
        max: 2.001,
        value: 0,
        heating: {
          0: {
            label: "False",
            value: 0,
            system: "Volumetric",
            requires: {
              volumetric: volumetricDef,
              vessel: vesselDef,
              trays: traysDef,
            },
          },
          1: {
            label: "True",
            value: 1,
            system: "Volumetric",
            requires: {
              volumetric: volumetricDef,
              vessel: vesselWHeatDef,
              trays: traysDef,
            },
          },
        },
      },
      1: {
        min: 2.001,
        max: 10.1,
        value: 1,
        heating: {
          0: {
            label: "False",
            value: 0,
            system: "Volumetric Deluxe",
            requires: {
              volumetricDeluxe: volumetricDeluxeDef,
              vessel: vesselDef,
              trays: traysDef,
            },
          },
          1: {
            label: "True",
            value: 1,
            system: "Volumetric Deluxe",
            requires: {
              volumetricDeluxe: volumetricDeluxeDef,
              vessel: vesselWHeatDef,
              trays: traysDef,
            },
          },
        },
      },
    },
  },
  14: {
    label: "Suppositories",
    units: "ml",
    error: "Suppositories volumes must be between 0.1ml - 10ml",
    value: 14,
    volumes: {
      0: {
        min: 0.09,
        max: 2.001,
        value: 0,
        heating: {
          0: {
            label: "False",
            value: 0,
            system: "Volumetric",
            requires: {
              volumetric: volumetricDef,
              vessel: vesselDef,
              trays: traysDef,
            },
          },
          1: {
            label: "True",
            value: 1,
            system: "Volumetric",
            requires: {
              volumetric: volumetricDef,
              vessel: vesselWHeatDef,
              trays: traysDef,
            },
          },
        },
      },
      1: {
        min: 2.001,
        max: 10.1,
        value: 1,
        heating: {
          0: {
            label: "False",
            value: 0,
            system: "Volumetric Deluxe",
            requires: {
              volumetricDeluxe: volumetricDeluxeDef,
              vessel: vesselDef,
              trays: traysDef,
            },
          },
          1: {
            label: "True",
            value: 1,
            system: "Volumetric Deluxe",
            requires: {
              volumetricDeluxe: volumetricDeluxeDef,
              vessel: vesselWHeatDef,
              trays: traysDef,
            },
          },
        },
      },
    },
  },
  15: {
    label: "Q cups",
    units: "ul",
    value: 15,
    error: "Q cups volumes must be between 0ul - 0.1ul",
    volumes: {
      0: {
        min: 0,
        max: 11,
        value: 0,
        heating: {
          0: {
            label: "False",
            value: 0,
            system: "N-Diaphragm",
          },
          1: {
            label: "True",
            value: 1,
            system: "N-Diaphragm",
          },
        },
      },
    },
  },
  16: {
    label: "Tube containers",
    units: "ml",
    error: "Tube volumes must be between 0.1ml - 100ml",
    value: 16,
    volumes: {
      0: {
        min: 0.09,
        max: 2.001,
        value: 0,
        heating: {
          0: {
            label: "False",
            value: 0,
            system: "Volumetric",
            requires: {
              volumetric: volumetricDef,
              vessel: vesselDef,
              trays: traysDef,
            },
          },
          1: {
            label: "True",
            value: 1,
            system: "Volumetric",
            requires: {
              volumetric: volumetricDef,
              vessel: vesselWHeatDef,
              trays: traysDef,
            },
          },
        },
      },
      1: {
        min: 2.001,
        max: 10,
        value: 1,
        heating: {
          0: {
            label: "False",
            value: 0,
            system: "Volumetric Deluxe",
            requires: {
              volumetricDeluxe: volumetricDeluxeDef,
              vessel: vesselDef,
              trays: traysDef,
            },
          },
          1: {
            label: "True",
            value: 1,
            system: "Volumetric Deluxe",
            requires: {
              volumetricDeluxe: volumetricDeluxeDef,
              vessel: vesselWHeatDef,
              trays: traysDef,
            },
          },
        },
      },
      2: {
        min: 10,
        max: 100.1,
        value: 2,
        noHeat: true,
        heating: {
          0: {
            label: "False",
            value: 0,
            system: "Peristaltic",
            requires: {
              peristaltic: peristalticDef,
              trays: traysDef,
            },
          },
        },
      },
    },
  },
};
const MapObj = { map };
export default MapObj;
