import React, { useEffect, useState } from "react";
import Autocomplete from "react-google-autocomplete";
import { useNavigate } from "react-router-dom";

//COOKIES
import { useCookies } from "react-cookie";

// HOOKS
import useEstimate from "../../../../../hooks/useEstimate";
import { useForm } from "react-hook-form";

//COMPONENTS
import Card from "../../../../../components/Card/cardWHeader";
import axios from "axios";

const SubmitOrder = ({ order }) => {
  let navigate = useNavigate();
  const [cookies] = useCookies(["user"]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const defaults = cookies.leadDetails;
  const { estimateState } = useEstimate();
  const methods = useForm();
  const { handleSubmit, setValue, register } = methods;

  useEffect(() => {
    async function setDefault() {
      Object.entries(defaults).forEach((entry) => {
        const [key, value] = entry;
        setValue(key, value);
      });
    }
    if (defaults && Object.values(defaults).length !== 0) setDefault();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  async function onSubmit(data) {
    setIsSubmitting(true);
    const address = {
      line_one: "15A, 12 Rue Pierre Castagnou",
      country_subdivision_code: "CA",
      postal_code: "l2l3j4",
      city: "townVille",
    };
    const body = {
      address,
      predicted_estimate: estimateState.predicted_estimate,
      customer_industry: "CANNABIS",
      personal_Statement: data.statement,
    };
    // console.log(body);
    try {
      const orders_response = await axios({
        method: "patch",
        url: `/sales/orders/${order.id}`,
        config: { headers: { "Content-Type": "application/json" } },
        headers: {
          "X-CSRFToken":
            "OROsBbAGbBdam2JE6BQ5oxPi2eIVn6syDJ8pOMJbj55okrxJytm8EjKzsOPhNfdk",
          Authorization: `Bearer ${cookies.amsterdam}`,
        },
        data: {
          address,
          predicted_estimate: estimateState.predicted_estimate,
          customer_industry: "CANNABIS",
        },
      });
      console.log(orders_response);
      navigate("/sales/orders");
      setIsSubmitting(false);
    } catch (error) {
      console.error("ERROR IS HERE", error.response);
    }
  }
  return (
    <Card
      heading={{
        title: "Submit Order",
        subTitle: "Fill in the client information to complete their order",
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="shadow sm:rounded-md sm:overflow-hidden ">
          <div
            className={`bg-white py-6 px-4 space-y-6 sm:p-6 ${
              isSubmitting && "opacity-40"
            }`}
          >
            <h3 className="text-2xl leading-6 font-medium text-gray-900">
              Order Details
            </h3>
            <div className="col-span-6 sm:col-span-3">
              <label
                htmlFor="first_name"
                className="text-lg leading-6 font-medium text-gray-900"
              >
                Order ID
              </label>
              <h3 className="py-1 text-gray-600">23456789</h3>
            </div>
            <div className="col-span-6 sm:col-span-3">
              <label
                htmlFor="first_name"
                className="text-lg leading-6 font-medium text-gray-900"
              >
                Lead ID
              </label>
              <h3 className="py-1 text-gray-600">23456789</h3>
            </div>
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Shipping Address
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                the address of which the product will be delivered
              </p>
            </div>

            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-1">
                <label
                  htmlFor="first_name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Full name
                </label>
                <input
                  type="text"
                  {...register("contact_name")}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <div className="col-span-6 sm:col-span-1">
                <label
                  htmlFor="email_address"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email address
                </label>
                <input
                  type="text"
                  {...register("email")}
                  autoComplete="email"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <div className="col-span-6 sm:col-span-1">
                <label
                  htmlFor="first_name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Phone Number
                </label>
                <input
                  type="number"
                  {...register("phone_number")}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              {/* <div className="col-span-3">
                <label
                  htmlFor="first_name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Shipping Address
                </label>
                <Autocomplete
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  apiKey={"AIzaSyCjVTY4ZhotiU1Ldf54tRItAcBS-s_tlNU"}
                  style={{ width: "90%" }}
                  onPlaceSelected={(place) => {
                    for (const entry of place.address_components) {
                      console.log(entry);
                      let type = entry.types[0];
                      switch (type) {
                        case "locality":
                          document.querySelector("#locality").value =
                            entry.long_name;
                          setValue("city", entry.long_name);
                          break;
                        case "administrative_area_level_1":
                          document.querySelector("#state").value =
                            entry.short_name;
                          setValue("state", entry.short_name);
                          break;
                        case "postal_code":
                          document.querySelector("#postal_code").value =
                            entry.long_name;
                          setValue("postal_code", entry.long_name);
                          break;
                        case "country":
                          document.querySelector("#country").value =
                            entry.long_name;
                          setValue("country", entry.long_name);
                          break;
                        default:
                          break;
                      }
                    }
                  }}
                  options={{
                    types: ["address"],
                  }}
                  placeholder="Enter A location..."
                />
              </div> */}
              {/* <div className="col-span-1">
                <label
                  htmlFor="street_address"
                  className="block text-sm font-medium text-gray-700"
                >
                  Apartment, unit, suite, or floor #
                </label>
                <input
                  type="text"
                  {...register("street_address")}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div> */}
              {/* <div className="col-span-1">
                <label className="block text-sm font-medium text-gray-700">
                  City
                </label>
                <input
                  type="text"
                  id="locality"
                  {...register("city")}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div> */}
              <div className="col-span-1">
                <label className="block text-sm font-medium text-gray-700">
                  State/Province
                </label>
                <input
                  type="text"
                  id="state"
                  {...register("state")}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              {/* <div className="col-span-1">
                <label className="block text-sm font-medium text-gray-700">
                  Postal code
                </label>
                <input
                  type="text"
                  id="postal_code"
                  {...register("postal_code")}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div> */}
              <div className="col-span-1">
                <label className="block text-sm font-medium text-gray-700">
                  Country/Region
                </label>
                <input
                  type="text"
                  id="country"
                  {...register("country")}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Personal Statement
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                The personalized message you would like to be send to the client
                with links to the estimates created
              </p>
            </div>
            <div className="col-span-1">
              <label className="block text-sm font-medium text-gray-700">
                Statement
              </label>
              <textarea
                id="statement"
                {...register("statement")}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
          </div>
          <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
            <button
              type="submit"
              id="completeSubmit"
              className="bg-blue-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              {isSubmitting && (
                <svg
                  className="animate-spin h-5 w-5 text-white self-center mr-2"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 40 40"
                  enableBackground="new 0 0 40 40"
                  xml="preserve"
                >
                  <path
                    opacity="0.2"
                    d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"
                  />
                  <path d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z" />
                </svg>
              )}
              Submit
            </button>
          </div>
        </div>
      </form>
    </Card>
  );
};

export default SubmitOrder;
