import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import axios from "axios";

// CONTEXT_API
import { EstimateContext } from "../../context/Estimate";

const useEstimateContext = () => {
  const [state, dispatch] = useContext(EstimateContext);
  let navigate = useNavigate();
  // SET_DEFAULT_VALUES
  async function updateFormPage(action, head) {
    switch (action) {
      case "increment":
        dispatch({
          type: "UPDATE_FORM_PAGE",
          payload: { hd: head, page: state.pageOrder + 1 },
        });
        break;
      case "decrement":
        dispatch({
          type: "UPDATE_FORM_PAGE",
          payload: { hd: head, page: state.pageOrder - 1 },
        });
        break;
      case "container":
        dispatch({
          type: "SET_PARAMETERS",
          payload: true,
        });
        break;
      case "product":
        dispatch({
          type: "SET_IS_PRODUCTS",
          payload: { isProduct: true, parameters: state.parameters },
        });
        break;
      case "review":
        let builds = state.buildSeries;
        let index = state.seriesIndex;
        builds[index] = state.currentBuild.filter((n) => n);
        let buildPricing = state.buildPricing;
        buildPricing.push(state.totalPrice);
        dispatch({
          type: "SET_IN_REVIEW",
          payload: {
            status: true,
            buildSeries: builds,
            buildPrice: buildPricing,
          },
        });
        break;
      case "unsetReview":
        dispatch({
          type: "UNSET_IN_REVIEW",
          payload: false,
        });
        break;
      case "addBuild":
        let BuildIndex = state.seriesIndex;
        dispatch({
          type: "ADD_TO_BUILD",
          payload: BuildIndex + 1,
        });
        break;
      case "submit":
        dispatch({
          type: "SET_SUBMIT",
          payload: true,
        });
        break;
      case "finalize":
        navigate("/sales/orders");
        break;
      default:
        return null;
    }
  }
  async function jumpToReview(data) {
    dispatch({
      type: "JUMP_TO_REVIEW",
      payload: {
        buildSeries: data.systems,
        totalPrice: data.estimate_total,
        products: data.products,
        params: data.parameters,
        structure: data.structure,
      },
    });
  }

  async function setCurrency(value) {
    dispatch({
      type: "SET_CURRENCY",
      payload: value,
    });
  }

  async function predictEstimate(value) {
    dispatch({
      type: "SET_PREDICTED_ESTIMATE",
      payload: value,
    });
  }
  async function updateBuildIndex(value) {
    dispatch({
      type: "SET_BUILD_INDEX",
      payload: value,
    });
  }
  async function updateTotalPrice(structure) {
    let totalPrice = 0;
    for (const index of Object.keys(structure)) {
      totalPrice += structure[index].price;
    }
    dispatch({
      type: "UPDATE_PRICE",
      payload: totalPrice,
    });
  }

  async function getProductPrice(code) {
    try {
      const product_response = await axios({
        method: "post",
        url: `/inventory/products/${code}`,
        config: { headers: { "Content-Type": "application/json" } },
        headers: {
          "X-CSRFToken":
            "OROsBbAGbBdam2JE6BQ5oxPi2eIVn6syDJ8pOMJbj55okrxJytm8EjKzsOPhNfdk",
        },
        data: {
          currency: state.currency,
        },
      });
      return product_response.data.price;
    } catch (error) {
      console.error("ERROR IS HERE", error.response);
    }
  }

  async function handleFormSubmission(
    data,
    structure,
    order,
    head,
    product,
    machine
  ) {
    let LineItemNumber = state.lineItem;
    let filteredData = Object.fromEntries(
      Object.entries(data).filter(
        ([_, entry]) =>
          entry !== null && entry !== "" && entry !== "0" && entry !== false
      )
    );
    // add logic to allow for removal of trays when not required
    let BuildComponent = {
      systemType: structure.systemType,
      category: structure.category,
      product,
      machine,
      components: [],
    };
    if (head === "trays" && Object.keys(filteredData).length === 0) {
      let formStructure = state.formStructure;
      formStructure[head].defaults[0] = data;
      let EstimateBuild = state.currentBuild;
      EstimateBuild[order] = BuildComponent;
      dispatch({ type: "SET_DEFAULT_VALUES", payload: formStructure });
      dispatch({ type: "SET_CURRENT_BUILD", payload: EstimateBuild });
    }
    if (Object.keys(filteredData).length !== 0) {
      for (const entry of structure.form) {
        let component = {};
        let changeKitName = null;
        if (!entry.isTable) {
          let data_point = filteredData[entry.name];
          if (entry.isChange) {
            for (const subEntry of Object.values(entry.options)) {
              data_point = filteredData[subEntry.value];
              if (data_point) {
                changeKitName = subEntry.value;
                break;
              }
            }
          }
          if (entry.isCheckbox && data_point && data_point.length !== 0) {
            for (const entry of data_point) {
              let checkComponent = {};
              const productDetails = entry.split("-");
              checkComponent.productId = productDetails[0];
              checkComponent.productName = productDetails[1];
              let cost = await getProductPrice(productDetails[0]);
              checkComponent.lineItem = LineItemNumber;
              checkComponent.qty = 1;
              checkComponent.price = cost;
              checkComponent.cost = cost;
              checkComponent.discount = 0;
              BuildComponent.components.push(checkComponent);
              LineItemNumber++;
            }
          } else {
            if (data_point) {
              let productDetails;
              if (entry.isChange && changeKitName) {
                productDetails = changeKitName.split("-");
              } else {
                productDetails = entry.isQuantitative
                  ? entry.name.split("-")
                  : data_point.split("-");
              }
              component.productId = productDetails[0];
              component.productName = productDetails[1];
              let cost = await getProductPrice(productDetails[0]);
              component.lineItem = LineItemNumber;
              component.qty = entry.isQuantitative ? data_point : 1;
              component.price =
                cost * parseFloat(entry.isQuantitative ? data_point : 1);
              component.cost = cost;
              component.discount = 0;
              BuildComponent.components.push(component);
              LineItemNumber++;
            }
          }
        } else {
          for (const tableEntry of entry.TableInputs) {
            let tComponent = {};
            let data_point = filteredData[tableEntry.name];
            if (tableEntry.isSubIndex) {
              let traysData = Object.fromEntries(
                Object.entries(filteredData).filter(
                  ([_, entry]) => typeof entry !== "boolean"
                )
              );
              for (const [propI, propV] of Object.entries(traysData)) {
                if (
                  propI !== "setup" &&
                  propI !== "product" &&
                  propI !== "500028-300S Additional Trays"
                ) {
                  let productDetails = propI.split("-");
                  if (parseInt(productDetails[0])) {
                    tComponent.productId = productDetails[0];
                    tComponent.productName = productDetails[1];
                    let cost = await getProductPrice(productDetails[0]);
                    tComponent.lineItem = LineItemNumber;
                    tComponent.qty = propV;
                    tComponent.price = cost * parseFloat(propV);
                    tComponent.cost = cost;
                    tComponent.discount = 0;
                    BuildComponent.components.push(tComponent);
                    tComponent = {};
                  }
                }
              }
            } else if (data_point && !entry.switch) {
              if (tableEntry.quantityFor) {
                const productDetails =
                  filteredData[tableEntry.quantityFor].split("-");
                let element = BuildComponent.components.find(
                  (entry) => entry.productName === productDetails[1]
                );
                element.qty = data_point;
              } else if (tableEntry.isOption) {
                const productDetails = data_point.split("-");
                let element = BuildComponent.components.find(
                  (entry) => entry.productName === tableEntry.optionFor
                );
                if (element) {
                  element.productName = productDetails[1];
                  element.productId = productDetails[0];
                }
              } else if (tableEntry.isQuantitative) {
                if (parseFloat(data_point) > 0) {
                  const productDetails = tableEntry.name.split("-");
                  tComponent.productId = productDetails[0];
                  tComponent.productName = productDetails[1];
                  let cost = await getProductPrice(productDetails[0]);
                  tComponent.lineItem = LineItemNumber;
                  tComponent.qty = data_point;
                  tComponent.price = cost * parseFloat(data_point);
                  tComponent.cost = cost;
                  tComponent.discount = 0;
                  if (tableEntry.dependant) {
                    tComponent.qty = filteredData[tableEntry.dependant];
                  }
                  BuildComponent.components.push(tComponent);
                  LineItemNumber++;
                }
              } else {
                const productDetails = data_point.split("-");
                tComponent.productId = productDetails[0];
                tComponent.productName = productDetails[1];
                let cost = await getProductPrice(productDetails[0]);
                tComponent.lineItem = LineItemNumber;
                tComponent.qty = 1;
                tComponent.price = cost;
                tComponent.cost = cost;
                tComponent.discount = 0;
                if (tableEntry.dependant) {
                  tComponent.qty = filteredData[tableEntry.dependant];
                }
                BuildComponent.components.push(tComponent);
                LineItemNumber++;
              }
            }
          }
        }
      }
      if (BuildComponent.components.length !== 0) {
        let EstimateBuild = state.currentBuild;
        EstimateBuild[order] = BuildComponent;
        if (head) {
          let formStructure = state.formStructure;
          formStructure[head].defaults[0] =
            head === "trays" ? data : filteredData;
          dispatch({ type: "SET_DEFAULT_VALUES", payload: formStructure });
        }
        dispatch({ type: "SET_CURRENT_BUILD", payload: EstimateBuild });
        dispatch({ type: "SET_LINE_ITEM", payload: LineItemNumber });
      }
    }
  }

  async function updateStructure(data) {
    dispatch({ type: "SET_DEFAULT_VALUES", payload: data });
  }

  async function fetchPrice(data, structure) {
    let workingPrice = 0;
    let filteredData =
      data &&
      Object.fromEntries(
        Object.entries(data).filter(
          ([_, entry]) => entry !== null && entry !== "" && entry !== "0"
        )
      );
    if (filteredData && Object.keys(filteredData).length !== 0) {
      for (const entry of structure.form) {
        let changeKitName = null;
        if (!entry.isTable) {
          let data_point = filteredData[entry.name];
          if (entry.isChange) {
            for (const subEntry of Object.values(entry.options)) {
              data_point = filteredData[subEntry.value];
              if (data_point) {
                changeKitName = subEntry.value;
                break;
              }
            }
          }
          if (entry.isCheckbox && data_point && data_point.length !== 0) {
            for (const entry of data_point) {
              const productDetails = entry.split("-");
              let cost = await getProductPrice(productDetails[0]);
              workingPrice += cost;
            }
          } else {
            if (data_point) {
              let productDetails;
              if (entry.isChange && changeKitName) {
                productDetails = changeKitName.split("-");
              } else {
                productDetails = entry.isQuantitative
                  ? entry.name.split("-")
                  : data_point.split("-");
              }
              let cost = await getProductPrice(productDetails[0]);
              if (entry.isQuantitative || changeKitName)
                cost = cost * parseFloat(data_point);
              workingPrice += cost;
            }
          }
        } else {
          for (const tableEntry of entry.TableInputs) {
            let tComponent = {};
            let data_point = filteredData[tableEntry.name];
            if (tableEntry.isSubIndex) {
              let traysData = Object.fromEntries(
                Object.entries(filteredData).filter(
                  ([_, entry]) => typeof entry !== "boolean"
                )
              );
              for (const [propI, propV] of Object.entries(traysData)) {
                if (
                  propI !== "setup" &&
                  propI !== "product" &&
                  propI !== "500028-300S Additional Trays"
                ) {
                  let productDetails = propI.split("-");
                  if (parseInt(productDetails[0])) {
                    let cost = await getProductPrice(productDetails[0]);
                    workingPrice += cost * parseFloat(propV);
                  }
                }
              }
            } else if (data_point) {
              if (tableEntry.quantityFor) {
                const productDetails =
                  filteredData[tableEntry.quantityFor].split("-");
                let cost = await getProductPrice(productDetails[0]);
                workingPrice -= cost;
                workingPrice += cost * parseFloat(data_point);
              } else if (tableEntry.isOption) {
                const productDetails = data_point.split("-");
                let searchIndex = tableEntry.optionFor;
                let element = Object.keys(filteredData).find(
                  (entry) => entry.split("-")[1] === searchIndex
                );
                if (data[element]) {
                  let cost = await getProductPrice(productDetails[0]);
                  workingPrice += cost * parseFloat(filteredData[element]);
                }
              } else {
                const productDetails = tableEntry.isQuantitative
                  ? tableEntry.name.split("-")
                  : data_point.split("-");
                let cost = await getProductPrice(productDetails[0]);
                workingPrice += tableEntry.isQuantitative
                  ? cost * parseFloat(data_point)
                  : cost;
                if (tableEntry.dependant) {
                  tComponent.qty = filteredData[tableEntry.dependant];
                }
              }
            }
          }
        }
      }
    }
    return workingPrice;
  }

  async function handleProductOverlay(inStatus, inDetails) {
    dispatch({
      type: "SET_PRODUCT_OVERLAY",
      payload: { status: inStatus, details: inDetails },
    });
  }

  async function handleSystemOverlay(inStatus, inDetails) {
    dispatch({
      type: "SET_SYSTEM_OVERLAY",
      payload: { status: inStatus, details: inDetails },
    });
  }

  async function reviewTotalPrice(value) {
    dispatch({
      type: "UPDATE_PRICE",
      payload: value,
    });
  }
  return {
    estimateState: state,
    updateFormPage,
    predictEstimate,
    handleFormSubmission,
    handleProductOverlay,
    handleSystemOverlay,
    updateStructure,
    updateTotalPrice,
    fetchPrice,
    updateBuildIndex,
    reviewTotalPrice,
    jumpToReview,
    setCurrency,
  };
};

export default useEstimateContext;
