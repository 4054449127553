import React from "react";
import { useFormContext } from "react-hook-form";

// HOOKS
import useEstimate from "../../../hooks/useEstimate";

const SimpleRadio = ({ props }) => {
  let isRequired = false;
  const { handleProductOverlay } = useEstimate();
  const {
    register,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext();

  if (props.validation) {
    if (props.validation.dependant) {
      for (const entry of props.validation.dependant) {
        isRequired = entry ? (getValues(entry) ? true : false) : false;
        if (isRequired) break;
      }
    }
    // if (!isRequired && props.validation.default) {
    //   for (const entry of props.validation.default) {
    //     const defObject = estimateState.defaultValues[entry.index];
    //     console.log(defObject);

    //     isRequired = entry
    //       ? defObject[entry.VariableName]
    //         ? true
    //         : false
    //       : false;
    //     if (isRequired) break;
    //   }
    // }
  }

  const RadioOptions = props.options.map((entry, index) => {
    return (
      <div key={index} className="flex items-start mt-3">
        <div className="h-5 flex items-center">
          <input
            key={index}
            type="radio"
            onClick={() =>
              getValues(props.name) === entry.value &&
              setValue(props.name, null)
            }
            {...register(props.name, {
              required: isRequired,
            })}
            value={entry.value}
            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
          />
        </div>
        <div className="ml-3 text-base">
          <label htmlFor={props.name} className="font-medium text-gray-700">
            {entry.label}
          </label>
          <p className="text-gray-500">{entry.subtitle}</p>
        </div>
      </div>
    );
  });

  return (
    <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
      <div className="col-span-2">
        <label
          htmlFor="project_name"
          className="block text-lg font-medium text-gray-900 sm:mt-px sm:pt-2"
        >
          {props.label}
        </label>
        <p className="text-gray-500 text-base">{props.subtitle}</p>
        <button
          onClick={() => handleProductOverlay(true, props)}
          type="button"
          className="animate-pulse text-blue-400 hover:text-indigo-900 text-base font-semibold"
        >
          View Details
        </button>
        {errors[props.name] && (
          <p className=" text-xs tracking-wide  pt-2 lg:pt-1` text-red-600">
            Selection is Required
          </p>
        )}
      </div>
      <div>{RadioOptions}</div>
    </div>
  );
};

export default SimpleRadio;
