import React from "react";

// HOOKS
import useEstimate from "../../../hooks/useEstimate";

const ModularTable = ({ props, register }) => {
  const { handleProductOverlay } = useEstimate();
  const TableHeaders = props.headers.map((option, index) => {
    return (
      <th
        key={index}
        className="p-3 text-left text-xs font-medium  uppercase text-gray-500  hidden lg:table-cell"
      >
        {option}
      </th>
    );
  });

  const TableEntries = props.options.map((option, index) => {
    const entries = option.map((entry, subindex) => {
      let dropDownOptions;
      if (entry.options) {
        dropDownOptions = entry.options.map((option, index) => {
          return (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          );
        });
      }
      return (
        <td
          key={subindex}
          className="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"
        >
          {entry.label && (
            <div className="flex flex-col justify-start">
              <span className="text-lg">{entry.label}</span>
              <button
                onClick={() => handleProductOverlay(true, option)}
                type="button"
                className="w-min animate-pulse text-blue-400 hover:text-indigo-900 text-base font-semibold"
              >
                View Details
              </button>
            </div>
          )}
          {entry.input && (
            <input
              {...register(entry.name)}
              type={entry.input}
              name={entry.name}
              value={entry.value}
              step="1"
              min={0}
              className={entry.inputStyle}
            />
          )}
          {entry.select && (
            <select
              {...register(entry.name)}
              className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm p-1 bg-white border border-gray-300 rounded-md"
            >
              <option value="">Select...</option>
              {dropDownOptions}
            </select>
          )}
        </td>
      );
    });
    return (
      <tr
        key={index}
        className={`${
          index % 2 === 0 ? "bg-white" : "bg-gray-50"
        } flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0 w-full`}
      >
        {entries}
      </tr>
    );
  });

  return (
    <div className={`px-4 my-2 mb-3 h-${props.height} `}>
      <div>
        <h3 className="text-lg leading-6 font-medium text-gray-900 pb-2">
          {props.title}
        </h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">
          {props.description}
        </p>
      </div>
      <div className="h-5/6 overflow-auto object-contain ">
        <table className="relative h-full border rounded-lg w-full">
          <thead className="sticky z-20 top-0 bg-gray-50 border border-gray-300 rounded-lg">
            {TableHeaders}
          </thead>
          <tbody>{TableEntries}</tbody>
        </table>
      </div>
    </div>
  );
};

export default ModularTable;
