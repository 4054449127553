import React, { useEffect, useState } from "react";
import axios from "axios";
import { Outlet } from "react-router-dom";

//COOKIES
import { useCookies } from "react-cookie";

// HOOKS
import useEstimate from "../../../../../hooks/useEstimate";

// COMPONENTS
import OrderDetails from ".././detailView/OrderDetails";
import SubmitOrder from "./submitOrder";

const UniqueOrderView = () => {
  const { estimateState } = useEstimate();
  const [cookies] = useCookies(["user"]);
  const [orderId, setOrderId] = useState(window.location.href.split("/").pop());
  const [orderDetails, setOrderDetails] = useState(null);
  useEffect(() => {
    async function getOrder() {
      try {
        const order_response = await axios({
          method: "get",
          url: `/sales/orders/${orderId}`,
          config: { headers: { "Content-Type": "application/json" } },
          headers: {
            "X-CSRFToken":
              "OROsBbAGbBdam2JE6BQ5oxPi2eIVn6syDJ8pOMJbj55okrxJytm8EjKzsOPhNfdk",
            Authorization: `Bearer ${cookies.amsterdam}`,
          },
        });
        setOrderDetails(order_response.data);
      } catch (error) {
        console.error("ERROR IS HERE", error.response);
      }
    }
    getOrder();
  }, []);

  let renderedComponent;
  if (!estimateState.isSubmit) {
    renderedComponent = <OrderDetails order={orderDetails} />;
  } else {
    renderedComponent = <SubmitOrder order={orderDetails} />;
  }
  return (
    <div>
      {renderedComponent} <Outlet />
    </div>
  );
};

export default UniqueOrderView;
