import React from "react";
import { FormProvider } from "react-hook-form";

// HOOKS
import useEstimateForm from "../../../hooks/useEstimate/useEstimateForm";
// HELPER
import InputDistributer from "../../../helper/FormInputDistributer";

const EstimateForm = () => {
  const {
    currentFormStructure,
    methods,
    submitForm,
    handleDecrement,
    isSubmitting,
    // formStructure,
  } = useEstimateForm();
  const { handleSubmit } = methods;

  if (currentFormStructure) {
    const [main, aside] = currentFormStructure.structure.form.reduce(
      ([main, aside], entry) =>
        !entry.isAside ? [[...main, entry], aside] : [main, [...aside, entry]],
      [[], []]
    );
    return (
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(submitForm)}>
          <div className="border-b border-gray-200 pb-4 mb-2 flex flex-row justify-between items-start mx-6">
            <div>
              <h3 className="text-xl leading-6 font-medium text-gray-900">
                {currentFormStructure.structure.title}{" "}
                {currentFormStructure.pertains[currentFormStructure.index] && (
                  <span className=" text-xl font-bold">
                    {"[ "}
                    {
                      currentFormStructure.pertains[currentFormStructure.index]
                        ?.product
                    }{" "}
                    |{" Machine #"}
                    {
                      currentFormStructure.pertains[currentFormStructure.index]
                        ?.machine
                    }
                    {" ]"}
                  </span>
                )}
              </h3>
              <p className="mt-1 max-w-2xl text-base text-gray-500">
                {currentFormStructure.structure.description}
              </p>
            </div>
            <div className="mt-3 flex sm:mt-0 sm:ml-4">
              <button
                type="button"
                onClick={() => handleDecrement(currentFormStructure.previous)}
                className="inline-flex items-center px-5 py-2 border border-gray-300 rounded-md shadow-sm text-base font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                {currentFormStructure.previous ? "Back" : "Production Details"}
              </button>
              <button
                type="submit"
                className={`inline-flex ml-3  items-center px-5 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${
                  isSubmitting && "disabled"
                }`}
              >
                {isSubmitting && (
                  <svg
                    className="animate-spin h-5 w-5 text-white self-center mr-2"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 40 40"
                    enableBackground="new 0 0 40 40"
                    xml="preserve"
                  >
                    <path
                      opacity="0.2"
                      d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"
                    />
                    <path d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z" />
                  </svg>
                )}
                {currentFormStructure.next ? "Next" : "Review"}
              </button>
            </div>
          </div>
          <div className="flex-col lg:flex-row relative z-0 flex overflow-hidden">
            <main
              className={`relative xl:flex xl:flex-col pr-4 py-4 w-full lg:${
                aside.length !== 0 ? "w-1/2" : "w-2/3"
              }`}
            >
              {main.map((entry, index) => {
                // if (!entry.validation) {
                //   return <InputDistributer key={index} props={entry} />;
                // } else {
                //   let defaultKeys = Object.assign(
                //     {},
                //     ...formStructure[entry.validation.head]?.defaults
                //   );
                //   if (entry.validation.isValue) {
                //     for (const val of entry.validation.isValue) {
                //       if (defaultKeys[entry.validation.key] === val)
                //         return <InputDistributer key={index} props={entry} />;
                //     }
                //   } else {
                //   }
                //   console.log(defaultKeys);
                // }
                return <InputDistributer key={index} props={entry} />;
              })}
            </main>
            {aside.length !== 0 && (
              <aside className="block  h-full md:flex md:flex-col px-4 py-4 flex-1 w-full lg:w-1/2 border-l border-gray-200">
                {aside.map((entry, index) => {
                  if (
                    !entry.contingency ||
                    currentFormStructure.pertains[
                      currentFormStructure.index
                    ]?.container.find(
                      (element) => element.split("-")[1] === entry.contingency
                    )
                  ) {
                    return <InputDistributer key={index} props={entry} />;
                  }
                })}
              </aside>
            )}
          </div>
        </form>
      </FormProvider>
    );
  } else {
    return <h1>LOADING</h1>;
  }
};

export default EstimateForm;
