import React from "react";
import Cards from "./cards";

const DashboardView = () => {
  return (
    <div className="h-full overflow-auo ">
      <Cards />
    </div>
  );
};

export default DashboardView;
