const machineDef = {
  auto_filling_machine: "500205-RoboCAP 300 Series",
};

const volumetricDef = {
  default: {
    volumetric_nozzles: "500079-Standard 2 Nozzle",
    volumetric_tip: "200188-18Ga Cannula",
  },
  addOn: { extension: { "500067-volumetric 2 nozzle ChangeOver": "1" } },
};

const pneumaticDef = {
  default: {
    pneumatic_Nozzle: "500138-Pneumatic 2 Nozzles",
    pneumatic_tip: "400037-1mm Dispense Tip",
  },
  addOn: { extension: { "500063-pneumatic 2 nozzle ChangeOver": "1" } },
};

const volumetricDeluxeDef = {
  default: {
    volumetric_deluxe_nozzles: "500237-Deluxe 2 Nozzle",
    volumetric_deluxe_tip: "200132-12Ga Cannula",
  },
  addOn: { extension: { "500067-volumetric 2 nozzle ChangeOver": "1" } },
};

const peristalticDef = {
  default: {
    avg_vis_nozzle: "500142-AF600 YZ2515 2 Pump",
  },
  addOn: {
    extension: {
      "200117-#24 Additional Silicone Tubing": "50",
      "200148-#24 SS Countersink": "2",
      "200143-#24 Food Grade Check Valve": "2",
    },
  },
};

const vesselDef = {
  default: {
    "1G mixer": "500123-1G Mixer",
    "500034-1 Gallon Pressure Vessel": "1",
    pou: "500023-12 PSI Input",
  },
  addOn: {},
};

const vesselWHeatDef = {
  default: {
    "1G line selection": "1",
    "1G mixer": "500123-1G Mixer",
    "1G-heating lines": "500043-1G 2 Line Heating",
    "500034-1 Gallon Pressure Vessel": "1",
    pou: "500023-12 PSI Input",
  },
  addOn: {},
};

const traysDef = {
  default: {},
  addOn: { expand: true },
};

const pnuemPeristalticDef = {
  default: { "pnuem-peri_nozzle": "500344-Cream Filling 2 pump/nozzle" },
  addOn: { extension: { "500203-cream Filling 2 nozzle ChangeOver": "1" } },
};

const objects = {
  machineDef,
  volumetricDef,
  volumetricDeluxeDef,
  peristalticDef,
  vesselDef,
  vesselWHeatDef,
  traysDef,
  pneumaticDef,
  pnuemPeristalticDef,
};
export default objects;
