import React from 'react';
import {useFormContext} from 'react-hook-form';

const SimpleText = ({props}) => {
  const {register} = useFormContext ();
  return (
    <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
      <div  className="col-span-2">
        <label className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
          {props.label}
        </label>
        <p className="text-gray-500 text-sm">{props.subtitle}</p>
      </div>
      <div>
        <input
          type="text"
          {...register (props.name)}
          className="block shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border border-gray-300 rounded-md py-1 px-2"
        />
      </div>
    </div>
  );
};

export default SimpleText;
