import React from "react";
import { Helmet } from "react-helmet";

// COMPONENTS
import Directory from "./directoryList";

const ClientsView = () => {
  return (
    <div className="flex-1 relative z-0 flex overflow-hidden">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Clients | ATG Staff</title>
      </Helmet>
      <main
        className="flex-1 relative z-0 overflow-y-auto focus:outline-none xl:order-last"
        tabIndex="0"
      >
        {/* <!-- Start main area--> */}
        <div className="absolute inset-0 py-6 px-4 sm:px-6 lg:px-8">
          <div className="h-full border-2 border-gray-200 border-dashed rounded-lg"></div>
        </div>
        {/* <!-- End main area --> */}
      </main>
      <aside className="hidden relative xl:order-first xl:flex xl:flex-col flex-shrink-0 w-96 border-r border-gray-200">
        {/* <!-- Start secondary column (hidden on smaller screens) --> */}
        <div className="px-6 pt-6 pb-4">
          <h2 className="text-lg font-medium text-gray-900">Current Clients</h2>
          <p className="mt-1 text-sm text-gray-600">
            Search directory of Num_clients Clients
          </p>
          <form className="mt-6 flex space-x-4" action="#">
            <div className="flex-1 min-w-0">
              <label htmlFor="search" className="sr-only">
                Search
              </label>
              <div className="relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  {/* <!-- Heroicon name: mail -->
                  <!-- Heroicon name: solid/search --> */}
                  <svg
                    className="h-5 w-5 text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <input
                  type="search"
                  name="search"
                  id="search"
                  className="focus:ring-pink-500 focus:border-pink-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                  placeholder="Search"
                />
              </div>
            </div>
            <button
              type="submit"
              className="inline-flex justify-center px-3.5 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
            >
              {/* <!-- Heroicon name: solid/filter --> */}
              <svg
                className="h-5 w-5 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
                  clipRule="evenodd"
                />
              </svg>
              <span className="sr-only">Search</span>
            </button>
          </form>
        </div>
        <Directory />
        {/* <div className="absolute inset-0 py-6 px-4 sm:px-6 lg:px-8">
          <div className="h-full border-2 border-gray-200 border-dashed rounded-lg"></div>
        </div> */}
        {/* <!-- End secondary column --> */}
      </aside>
    </div>
  );
};

export default ClientsView;
