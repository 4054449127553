import React, { Fragment, useState, useEffect } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";

// HOOKS
import useEstimate from "../../../hooks/useEstimate";

const currency = [
  { id: 1, name: "CAD" },
  { id: 2, name: "USD" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const EstimateHeader = () => {
  const { estimateState, setCurrency } = useEstimate();
  const [selected, setSelected] = useState(currency[0]);
  const [dialopen, setOpen] = useState(false);
  const [total, setTotal] = useState(estimateState.totalPrice);

  useEffect(() => {
    if (selected.name !== "CAD") {
      setTotal(estimateState.totalPrice * 0.8);
      setCurrency(selected.name);
    } else if (estimateState.currency) {
      setCurrency(null);
      setTotal(estimateState.totalPrice);
    } else {
      setTotal(estimateState.totalPrice);
    }
  }, [selected, estimateState.totalPrice]);

  return (
    <>
      <div
        aria-live="assertive"
        className={`fixed ${
          dialopen ? "z-10" : "z-0"
        } inset-0 flex items-start pt-40 pl-8 md:pt-20 md:px-8 pointer-events-none lg:px-12 lg:pt-2`}
      >
        <div className="w-full flex flex-col items-start space-y-4 sm:items-end">
          <Transition
            show={true}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="max-w-xs xl:max-w-sm w-full  bg-white bg-opacity-50 border border-gray-100 rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5">
              <div className="px-4 py-5 sm:py-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-400 truncate">
                  <span>Estimate For: </span>
                  <span>Testing Org</span>
                </dt>
                <dd className="my-2 text-3xl font-semibold text-green-600  flex flex-row items-center ">
                  <Listbox value={selected} onChange={setSelected}>
                    {({ open }) => (
                      <>
                        {open && !dialopen && setOpen(true)}
                        {!open && dialopen && setOpen(false)}
                        <div className="relative mr-2">
                          <Listbox.Button className="relative w-full text-blue-600 text-4xl font-bold bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                            <span className="block truncate">
                              {selected.name}
                            </span>
                            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                              <SelectorIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </span>
                          </Listbox.Button>

                          <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                              {currency.map((person) => (
                                <Listbox.Option
                                  key={person.id}
                                  className={({ active }) =>
                                    classNames(
                                      active
                                        ? "text-white bg-indigo-600"
                                        : "text-gray-900",
                                      "cursor-default select-none relative py-2 pl-8 pr-4"
                                    )
                                  }
                                  value={person}
                                >
                                  {({ selected, active }) => (
                                    <>
                                      <span
                                        className={classNames(
                                          selected
                                            ? "font-semibold"
                                            : "font-normal",
                                          "block truncate"
                                        )}
                                      >
                                        {person.name}
                                      </span>

                                      {selected ? (
                                        <span
                                          className={classNames(
                                            active
                                              ? "text-white"
                                              : "text-indigo-600",
                                            "absolute inset-y-0 left-0 flex items-center pl-1.5"
                                          )}
                                        >
                                          <CheckIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                          />
                                        </span>
                                      ) : null}
                                    </>
                                  )}
                                </Listbox.Option>
                              ))}
                            </Listbox.Options>
                          </Transition>
                        </div>
                      </>
                    )}
                  </Listbox>
                  {total.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </dd>
                <dt className="pt-1 text-sm font-medium text-gray-400 truncate">
                  <span>Production Start Date:</span>
                  <span> 26/05/2021</span>
                </dt>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
};

export default EstimateHeader;
