function nFormatter(num, digits) {
  let si = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  let rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
}
const currentDate = new Date();

const RevenueBreakdownOptions = {
  chart: {
    type: "bar",
    height: 10,
    stacked: true,
  },
  plotOptions: {
    bar: {
      horizontal: true,
    },
  },
  stroke: {
    width: 1,
    colors: ["#fff"],
  },
  title: {
    text: `${
      currentDate.getMonth() + 1
    }-${currentDate.getFullYear()} | ForeCasted Revenue Breakdown `,
  },
  xaxis: {
    categories: [`${currentDate.getMonth() + 1}-${currentDate.getFullYear()}`],
    labels: {
      formatter: function (val) {
        return "$" + nFormatter(val, 2);
      },
    },
  },
  yaxis: {
    title: {
      text: undefined,
    },
  },
  tooltip: {
    y: {
      formatter: function (val) {
        return "$" + nFormatter(val, 2);
      },
    },
  },
  fill: {
    opacity: 1,
  },
  legend: {
    position: "top",
    horizontalAlign: "left",
    offsetX: 40,
  },
  dataLabels: {
    enabled: false,
  },
  colors: [
    "#2E93fA",
    "#66DA26",
    "#546E7A",
    "#E91E63",
    "#FF9800",
    "#FD6A6A",
    "#43BCCD",
  ],
};

const MonthlyRevenueForecast = {
  chart: {
    type: "bar",
    height: 430,
  },
  plotOptions: {
    bar: {
      horizontal: true,
      dataLabels: {
        position: "top",
      },
    },
  },
  legend: {
    position: "top",
    horizontalAlign: "left",
    offsetX: 40,
  },
  title: {
    text: "Forecasted VS Actual Revenue",
  },
  dataLabels: {
    enabled: true,
    offsetX: -6,
    formatter: function (val) {
      return "$" + nFormatter(val, 2);
    },
    style: {
      fontSize: "12px",
      colors: ["#fff"],
    },
  },
  stroke: {
    show: true,
    width: 1,
    colors: ["#fff"],
  },
  tooltip: {
    shared: true,
    intersect: false,
  },
  xaxis: {
    categories: [
      "2-21",
      "3-21",
      "4-21",
      "5-21",
      "6-21",
      "7-21",
      //  `${currentDate.getMonth () + 2}-${currentDate.getFullYear () - 2000}`,
    ],
  },
};

var HeatMapOptions = {
  chart: {
    height: 350,
    type: "heatmap",
  },
  dataLabels: {
    enabled: false,
  },
  colors: ["#008FFB"],
  xaxis: {
    type: "category",
    categories: [
      "1-21",
      "2-21",
      "3-21",
      "4-21",
      "5-21",
      "6-21",
      "7-21",
      "8-21",
      "9-21",
      "10-21",
      "11-21",
      "12-21",
    ],
  },
  plotOptions: {
    heatmap: {
      shadeIntensity: 1,
      radius: 0,
      distributed: true,
      // colorScale: {
      //   ranges: [
      //     {
      //       from: -30,
      //       to: 5,
      //       name: 'low',
      //       color: '#00A100',
      //     },
      //     {
      //       from: 6,
      //       to: 20,
      //       name: 'medium',
      //       color: '#128FD9',
      //     },
      //     {
      //       from: 21,
      //       to: 45,
      //       name: 'high',
      //       color: '#FFB200',
      //     },
      //     {
      //       from: 46,
      //       to: 55,
      //       name: 'extreme',
      //       color: '#FF0000',
      //     },
      //   ],
      // },
    },
  },
};

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  RevenueBreakdownOptions,
  MonthlyRevenueForecast,
  HeatMapOptions,
};
