import React, { useEffect } from "react";
import axios from "axios";

// HOOKS
import useEstimate from "../../../hooks/useEstimate";

//COOKIES
import { useCookies } from "react-cookie";

// COMPONENTS
import SimpleCard from "../../../components/Card/simpleCard";
import ProductOverlay from "../../../components/Overlay/productOverlay";
import SystemOverlay from "../../../components/Overlay/systemOverlay";
import EstimateHeader from "./header";
import EstimateForm from "./form";
import Review from "./review";
import Parameter from "./parameter";
import Products from "./products";

const EstimateView = () => {
  const { estimateState, jumpToReview } = useEstimate();
  const [cookies] = useCookies(["user"]);
  useEffect(() => {
    async function getEstimateDetails() {
      const EstimateID = window.location.href.split("=")[1];
      if (EstimateID) {
        try {
          const response = await axios({
            method: "get",
            url: `/sales/estimate/${EstimateID}`,
            config: { headers: { "Content-Type": "application/json" } },
            headers: {
              "X-CSRFToken":
                "OROsBbAGbBdam2JE6BQ5oxPi2eIVn6syDJ8pOMJbj55okrxJytm8EjKzsOPhNfdk",
              Authorization: `Bearer ${cookies.amsterdam}`,
            },
          });
          if (response.data) {
            jumpToReview(response.data.data_json);
          }
        } catch (error) {
          console.error("ERROR IS HERE", error.response);
        }
      }
    }
    getEstimateDetails();
  }, []);
  let renderedComponent;
  if (estimateState.isProduct) renderedComponent = <Products />;
  else if (estimateState.isParameter) renderedComponent = <Parameter />;
  else if (!estimateState.isReview) renderedComponent = <EstimateForm />;
  else renderedComponent = <Review />;

  return (
    <React.Fragment>
      <EstimateHeader />
      <div className="relative mt-32 sm:mt-16">
        <SimpleCard>
          <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5 mt-2 flex-1">
            {renderedComponent}
          </div>
        </SimpleCard>
      </div>
      <ProductOverlay />
      <SystemOverlay />
    </React.Fragment>
  );
};

export default EstimateView;
