import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// COOKIES
import { CookiesProvider } from "react-cookie";

// CONTEXT API
import { UserContextProvider } from "./context/User";
import { EstimateContextProvider } from "./context/Estimate";

import axios from "axios";

if (window.location.hostname === "localhost") {
  axios.defaults.baseURL = "http://localhost:8000/";
} else {
  axios.defaults.baseURL = "https://connectapi.castleclock.com/";
}

ReactDOM.render(
  <React.StrictMode>
    <CookiesProvider>
      <UserContextProvider>
        <EstimateContextProvider>
          <App />
        </EstimateContextProvider>
      </UserContextProvider>
    </CookiesProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
