import React, { useState, useEffect, Fragment } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import moment from "moment";
import Chart from "react-apexcharts";
import { Switch } from "@headlessui/react";
import {
  ClipboardListIcon,
  ClipboardCheckIcon,
  ArrowsExpandIcon,
} from "@heroicons/react/outline";
//COOKIES
import { useCookies } from "react-cookie";

//COMPONENT
import SimpleCard from "../../components/Card/simpleCard";

// DATA
import ChartOptions from "./chartOptions";

const ClientsView = () => {
  const [cookies] = useCookies(["user"]);
  const [submittedNpsTable, setSubmittedNpsTable] = useState([]);
  const [unsubmittedNpsTable, setUnsubmittedNpsTable] = useState([]);
  const [currentTable, setCurrentTable] = useState([]);
  const [expandIndex, setExpandIndex] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [npsScore, setNpsScore] = useState(0);
  const [clipId, setClipId] = useState(null);
  const { NPSGaugeOptions } = ChartOptions;

  const TableHeaders = [
    "Company",
    "Contact Name",
    "Contact Email",
    "Appointment Date",
    isSubmitted ? "View Details" : "NPS Link",
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  useEffect(() => {
    async function getNPSTable() {
      const NPS = await axios({
        method: "get",
        url: "/nps/all",
        config: { headers: { "Content-Type": "application/json" } },
        headers: {
          "X-CSRFToken":
            "OROsBbAGbBdam2JE6BQ5oxPi2eIVn6syDJ8pOMJbj55okrxJytm8EjKzsOPhNfdk",
        },
      });
      if (NPS.data) {
        const [submitted, unSubmitted] = NPS.data.reduce(
          ([submitted, unSubmitted], entry) =>
            entry.submitted
              ? [[...submitted, entry], unSubmitted]
              : [submitted, [...unSubmitted, entry]],
          [[], []]
        );
        setSubmittedNpsTable(submitted);
        setUnsubmittedNpsTable(unSubmitted);
        setCurrentTable(unSubmitted);
      }
    }

    async function getNPSScore() {
      try {
        const NPS = await axios({
          method: "get",
          url: "/nps/calculateNps/",
          config: { headers: { "Content-Type": "application/json" } },
          headers: {
            "X-CSRFToken":
              "OROsBbAGbBdam2JE6BQ5oxPi2eIVn6syDJ8pOMJbj55okrxJytm8EjKzsOPhNfdk",
            Authorization: `Bearer ${cookies.amsterdam}`,
          },
        });
        if (NPS.data) {
          setNpsScore(NPS.data.score);
        }
      } catch {
        setNpsScore(0);
      }
    }
    getNPSScore();
    getNPSTable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function toClipBoard(id) {
    setClipId(id);
    navigator.clipboard.writeText(`https://www.atgpharma.com/nps?uuid=${id}`);
    setTimeout(() => {
      setClipId(null);
    }, 1500);
  }
  useEffect(() => {
    setExpandIndex(null);
    isSubmitted
      ? setCurrentTable(submittedNpsTable)
      : setCurrentTable(unsubmittedNpsTable);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitted]);

  return (
    <SimpleCard>
      <Helmet>
        <meta charSet="utf-8" />
        <title>NPS | ATG Staff</title>
      </Helmet>
      <div className="px-4 py-5">
        <dl className="grid grid-cols-1 gap-5 xl:grid-cols-3">
          <div className="col-span-1" />
          <div className="bg-white  shadow rounded-lg col-span-1 px-4 py-5 sm:py-4 sm:px-6">
            <h2 className="text-center text-xl font-medium">
              Net Promoter Score
            </h2>
            <Chart
              options={NPSGaugeOptions(npsScore)}
              series={[(Math.abs(npsScore) * 10).toFixed(2)]}
              type="radialBar"
              width="100%"
              height="350"
            />
          </div>
        </dl>
      </div>
      <div className="px-4 py-5">
        <h2 className="pb-6 text-xl font-lg font-semibold">NPS Surveys</h2>
        <Switch.Group as="div" className="flex items-center my-4 ">
          <Switch.Label as="span" className="mr-3">
            <span className="text-base font-medium text-gray-500">
              UnSubmitted Surveys
            </span>
          </Switch.Label>
          <Switch
            checked={isSubmitted}
            onChange={setIsSubmitted}
            className={classNames(
              isSubmitted ? "bg-indigo-600" : "bg-gray-200",
              "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            )}
          >
            <span className="sr-only">Use setting</span>
            <span
              aria-hidden="true"
              className={classNames(
                isSubmitted ? "translate-x-5" : "translate-x-0",
                "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
              )}
            />
          </Switch>
          <Switch.Label as="span" className="ml-3">
            <span className="text-base font-medium text-gray-500">
              Submitted Surveys
            </span>
          </Switch.Label>
        </Switch.Group>
        <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full relative divide-y divide-gray-200">
                  <thead className="sticky top-0 bg-gray-50">
                    <tr>
                      {TableHeaders.map((header) => (
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500  uppercase tracking-wider"
                        >
                          {header}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {currentTable.length !== 0 &&
                      currentTable.map((NPS, index) => (
                        <Fragment>
                          <tr key={NPS.calendly_uuid}>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                              {NPS.company_name}
                            </td>

                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                              {NPS.contact_name}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
                              {NPS.contact_email}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
                              {moment(NPS.appointment_date).format(
                                "dddd, MMM DD HH:mm"
                              )}
                            </td>
                            <td className="whitespace-nowrap px-6 py-4 text-left text-sm font-medium flex flex-row items-center">
                              {isSubmitted ? (
                                <button
                                  className="mx-2 text-gray-300 hover:text-gray-500 focus:outline-none"
                                  onClick={() => {
                                    expandIndex === index
                                      ? setExpandIndex(null)
                                      : setExpandIndex(index);
                                  }}
                                >
                                  <ArrowsExpandIcon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                  />
                                </button>
                              ) : (
                                <button
                                  onClick={() => {
                                    toClipBoard(NPS.calendly_uuid);
                                  }}
                                  className="mx-2 text-gray-300 hover:text-gray-500 focus:outline-none"
                                >
                                  {clipId === NPS.calendly_uuid ? (
                                    <div class="relative flex flex-col items-center group">
                                      <ClipboardCheckIcon
                                        className="h-6 w-6 text-green-500 transform -rotate-12	"
                                        aria-hidden="true"
                                      />
                                      <div class="absolute bottom-0 flex flex-col items-center mb-6">
                                        <span class="relative z-10 p-2 text-xs leading-none text-gray-500 whitespace-no-wrap rounded-lg bg-gray-100 shadow-lg">
                                          Copied to Clipboard!
                                        </span>
                                        <div class="w-3 h-3 -mt-2 transform rotate-45 bg-gray-100"></div>
                                      </div>
                                    </div>
                                  ) : (
                                    <ClipboardListIcon
                                      className="h-6 w-6"
                                      aria-hidden="true"
                                    />
                                  )}
                                </button>
                              )}
                            </td>
                          </tr>
                          {expandIndex === index && (
                            <tr>
                              <td className="px-6 py-4 whitespace-nowrap flex justify-start items-start">
                                <div className="flex flex-col justify-start items-start">
                                  <h3 className="text-xl font-medium leading-4 tracking-wide py-1">
                                    Score:{" "}
                                    <span
                                      className={`${
                                        NPS.score > 7
                                          ? "text-green-500"
                                          : "text-red-500"
                                      }`}
                                    >
                                      {NPS.score}
                                    </span>
                                  </h3>
                                  <h3 className="text-base text-gray-500 py-1">
                                    Tenure: {NPS.client_tenure}
                                  </h3>
                                </div>
                              </td>
                              <td className="px-6 py-4">
                                <p>
                                  <span className="mr-2 text-lg tracking-wide font-semibold text-gray-9000">
                                    Praise:
                                  </span>
                                  <span className="text-sm tracking-wide text-gray-600">
                                    {NPS.praise_answer}
                                  </span>
                                </p>
                              </td>
                              <td className="px-6 py-4 flex items-start">
                                <p>
                                  <span className="mr-2 text-lg tracking-wide font-semibold text-gray-9000">
                                    To Improve:
                                  </span>
                                  <span className="text-sm tracking-wide text-gray-600">
                                    {NPS.improvement_answer}
                                  </span>
                                </p>
                              </td>
                            </tr>
                          )}
                        </Fragment>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SimpleCard>
  );
};

export default ClientsView;
