import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

// HOOKS
import useConnectAuth from "../../hooks/useConnectAuth";

const LoginPage = () => {
  const { handleSignIn } = useConnectAuth();
  const { register, handleSubmit, setValue } = useForm();
  const [signAttempt, setSignAttempt] = useState(false);

  useEffect(() => {
    let credentials = JSON.parse(localStorage.getItem("login"));
    if (credentials) {
      setValue("email", credentials.email);
      setValue("password", credentials.password);
      setValue("remember_me", credentials.remember_me);
    }
  }, []);

  const onLogin = async (data) => {
    const { remember_me, email, password } = data;
    remember_me && localStorage.setItem("login", JSON.stringify(data));

    setSignAttempt(true);
    try {
      await handleSignIn(email, password);
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <img
            className="mx-auto h-12 w-auto"
            src="https://atg-online-resources.s3.amazonaws.com/logo.png"
            alt="ATG Pharma"
          />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Welcome Back to Connect
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            New here? Please contact your manager to receive your credentials
          </p>
        </div>
        <form className="mt-8 space-y-6" onSubmit={handleSubmit(onLogin)}>
          <input type="hidden" name="remember" value="true" />
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <input
                {...register("email", { required: true })}
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Email address"
              />
            </div>
            <div>
              <label htmlFor="password" className="sr-only">
                Password
              </label>
              <input
                {...register("password", { required: true })}
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Password"
              />
            </div>
          </div>
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <input
                id="remember_me"
                name="remember_me"
                type="checkbox"
                {...register("remember_me")}
                className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
              />
              <label
                htmlFor="remember_me"
                className="ml-2 block text-sm text-gray-900"
              >
                Remember me
              </label>
            </div>
          </div>
          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                <svg
                  className={`${
                    signAttempt && "animate-spin"
                  } h-5 w-5 text-blue-400 group-hover:text-indigo-400`}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </span>
              Sign In
            </button>
          </div>
        </form>
        <div>
          <p className="mt-2 text-center text-sm text-gray-600">
            Powered by CastleClock
          </p>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
