import React, { useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";

const TextWTag = ({ props }) => {
  const { register, watch, unregister } = useFormContext();
  const [registrationState, setRegistrationState] = useState("sample");
  const [currentLabel, setCurrentLabel] = useState(null);
  let watchedField = watch(props.tag);
  useEffect(() => {
    if (watch(props.tag)) {
      unregister(registrationState);
      const { value, label } = props.options[watch(props.tag)];
      setRegistrationState(value);
      setCurrentLabel(label);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedField]);

  return (
    <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
      <div className="col-span-2">
        <label className="block text-base font-medium text-gray-900 ">
          {props.label}
        </label>
        <p className="text-gray-500 text-sm">{props.subtitle}</p>
        <button
          // onClick={getProductDetails}
          type="button"
          className="animate-pulse text-blue-400 hover:text-indigo-900 text-sm font-medium"
        >
          View Details
        </button>
      </div>
      <div className="flex flex-row w-full">
        <input
          type="number"
          className="shadow-sm h-1/2 sm:text-sm p-1 pr-0 border border-gray-300 rounded-l-md w-2/5 "
          id={props.name}
          {...register(registrationState)}
          placeholder={`0 ${props.unit}`}
        />
        <span className="flex w-3/5 h-1/2 items-center px-3  pl-1 rounded-r-md border border-l-0 border-gray-400 bg-gray-50 text-gray-500 sm:text-xs">
          {currentLabel} nozzle(s)
        </span>
      </div>
    </div>
  );
};

export default TextWTag;
