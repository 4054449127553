import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { CheckCircleIcon } from "@heroicons/react/outline";
import axios from "axios";

//COOKIES
import { useCookies } from "react-cookie";

const tabs = [
  { name: "General", href: "#", current: true },
  { name: "Password", href: "#", current: false },
  { name: "Team Members", href: "#", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const SettingsView = () => {
  const [cookies, setCookie, removeCookie] = useCookies(["user"]);
  const location = window.location.href.split("?")[1];

  useEffect(() => {
    async function authenticateQBO() {
      if (location) {
        try {
          const response = await axios({
            method: "get",
            url: `/qboApi/authCodeHandler?${location}`,
            config: { headers: { "Content-Type": "application/json" } },
            headers: {
              "X-CSRFToken":
                "OROsBbAGbBdam2JE6BQ5oxPi2eIVn6syDJ8pOMJbj55okrxJytm8EjKzsOPhNfdk",
              Authorization: `Bearer ${cookies.amsterdam}`,
            },
          });
          console.log(response);
        } catch (error) {
          console.error("ERROR IS HERE", error.response);
        }
      }
    }
    authenticateQBO();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  async function testFunction() {
    try {
      const response = await axios({
        method: "get",
        url: "/qboApi/connect/",
        config: { headers: { "Content-Type": "application/json" } },
        headers: {
          "X-CSRFToken":
            "OROsBbAGbBdam2JE6BQ5oxPi2eIVn6syDJ8pOMJbj55okrxJytm8EjKzsOPhNfdk",
          Authorization: `Bearer ${cookies.amsterdam}`,
        },
      });
      window.location = response.data.redirect_url;
      removeCookie("QBA", { path: "/" });
      setCookie("QBA", "true", {
        path: "/",
        secure: true,
        sameSite: true,
      });
    } catch (error) {
      console.error("ERROR IS HERE", error.response);
    }
  }
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Settings | ATG Staff</title>
      </Helmet>
      <div className="flex-1 flex flex-col">
        <main className="flex-1 overflow-y-auto focus:outline-none">
          <div className="relative max-w-4xl mx-auto md:px-8 xl:px-0">
            <div className="pt-10 pb-16">
              <div className="px-4 sm:px-6 md:px-0">
                <h1 className="text-3xl font-extrabold text-gray-900">
                  Settings
                </h1>
              </div>
              <div className="px-4 sm:px-6 md:px-0">
                <div className="py-6">
                  {/* Tabs */}
                  <div className="lg:hidden">
                    <label htmlFor="selected-tab" className="sr-only">
                      Select a tab
                    </label>
                    <select
                      id="selected-tab"
                      name="selected-tab"
                      className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                      defaultValue={tabs.find((tab) => tab.current).name}
                    >
                      {tabs.map((tab) => (
                        <option key={tab.name}>{tab.name}</option>
                      ))}
                    </select>
                  </div>
                  <div className="hidden lg:block">
                    <div className="border-b border-gray-200">
                      <nav className="-mb-px flex space-x-8">
                        {tabs.map((tab) => (
                          <a
                            key={tab.name}
                            href={tab.href}
                            className={classNames(
                              tab.current
                                ? "border-blue-500 text-blue-600"
                                : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                              "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                            )}
                          >
                            {tab.name}
                          </a>
                        ))}
                      </nav>
                    </div>
                  </div>

                  {/* Description list with inline editing */}
                  <div className="mt-10 divide-y divide-gray-200">
                    <div className="space-y-1">
                      <h3 className="text-lg leading-6 font-medium text-gray-900">
                        Profile
                      </h3>
                      <p className="max-w-2xl text-sm text-gray-500">
                        This information will be displayed publicly so be
                        careful what you share.
                      </p>
                    </div>
                    <div className="mt-6">
                      <dl className="divide-y divide-gray-200">
                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                          <dt className="text-sm font-medium text-gray-500">
                            Name
                          </dt>
                          <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            <span className="flex-grow">
                              {cookies.user.first_name} {cookies.user.last_name}
                            </span>
                            <span className="ml-4 flex-shrink-0">
                              <button
                                type="button"
                                className="bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                              >
                                Update
                              </button>
                            </span>
                          </dd>
                        </div>
                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5">
                          <dt className="text-sm font-medium text-gray-500">
                            Photo
                          </dt>
                          <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            <span className="flex-grow">
                              <img
                                className="h-8 w-8 rounded-full"
                                src={
                                  cookies.user.profile_image
                                    ? cookies.user.profile_image.document
                                    : "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                }
                                alt=""
                              />
                            </span>
                            <span className="ml-4 flex-shrink-0 flex items-start space-x-4">
                              <button
                                type="button"
                                className="bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                              >
                                Update
                              </button>
                              <span
                                className="text-gray-300"
                                aria-hidden="true"
                              >
                                |
                              </span>
                              <button
                                type="button"
                                className="bg-white rounded-md font-medium text-red-600 hover:text-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                              >
                                Remove
                              </button>
                            </span>
                          </dd>
                        </div>
                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5">
                          <dt className="text-sm font-medium text-gray-500">
                            Email
                          </dt>
                          <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            <span className="flex-grow">
                              {cookies.user.email}
                            </span>
                            <span className="ml-4 flex-shrink-0">
                              <button
                                type="button"
                                className="bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                              >
                                Update
                              </button>
                            </span>
                          </dd>
                        </div>
                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-b sm:border-gray-200">
                          <dt className="text-sm font-medium text-gray-500">
                            Job title
                          </dt>
                          <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            <span className="flex-grow">-</span>
                            <span className="ml-4 flex-shrink-0">
                              <button
                                type="button"
                                className="bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                              >
                                Update
                              </button>
                            </span>
                          </dd>
                        </div>
                      </dl>
                    </div>
                  </div>

                  <div className="mt-10 divide-y divide-gray-200">
                    <div className="space-y-1">
                      <h3 className="text-lg leading-6 font-medium text-gray-900">
                        Account
                      </h3>
                      <p className="max-w-2xl text-sm text-gray-500">
                        Manage internal account.
                      </p>
                    </div>
                    <div className="mt-6">
                      <dl className="divide-y divide-gray-200">
                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-40 items-center">
                          <dt className="text-sm font-medium text-gray-500">
                            QuickBooks Connection
                          </dt>
                          <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            <span className="flex-grow">
                              {cookies.user.valid_qbo_refresh ? (
                                cookies.QBA === "true" ? (
                                  <CheckCircleIcon
                                    className="h-9 w-9 text-green-600"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  <button
                                    type="button"
                                    id="connectQBO"
                                    className="inline-flex justify-center w-full rounded-md border-2 border-transparent shadow-sm px-4 py-2 hover:text-gray-900  hover:border-gray-900  bg-QBGreen hover:bg-transparent text-base font-medium  text-white focus:outline-none sm:text-sm"
                                    onClick={testFunction}
                                  >
                                    Connect to QuickBooks
                                  </button>
                                )
                              ) : (
                                <p className="text-red-600 leading-5 tracking-2 ">
                                  Contact Admin
                                </p>
                              )}
                            </span>
                          </dd>
                        </div>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default SettingsView;
